import React, { FunctionComponent } from 'react'
import { StyleSheet, Text, View, TextInput } from 'react-native';
import MyCheckbox from '../../MyCheckbox';
import MyText from '../../MyText';
import AnnexForm from '../AnnexForm';
import { AntDesign } from '@expo/vector-icons'; 
import stylesheet from '../../Style'
import { TouchableOpacity } from 'react-native-gesture-handler';
import AnnexMultiForm from '../AnnexMultiForm';

const AnnexVHazardous = (props) => {
    const css = stylesheet();
    
    const [collapsed, setCollapsed] = React.useState(true);

    const [paint, setPaint] = React.useState(props?.value?.paint?.active??false);
    const [printing, setPrinting] = React.useState(props?.value?.printing?.active??false);
    const [photo, setPhoto] = React.useState(props?.value?.photo?.active??false);
    const [med, setMed] = React.useState(props?.value?.med?.active??false);
    const [leadBat, setLeadBat] = React.useState(props?.value?.leadBat?.active??false);
    const [otherBat, setOtherBat] = React.useState(props?.value?.otherBat?.active??false);
    const [dangerous, setDangerous] = React.useState(props?.value?.dangerous?.active??false);
    const [oilyRags, setOilyRags] = React.useState(props?.value?.oilyRags?.active??false);
    const [other, setOther] = React.useState(props?.value?.other?.active??false);

    const [paintData, setPaintData] = React.useState(props?.value?.paint?.data??{});
    const [printingData, setPrintingData] = React.useState(props?.value?.printing?.data??{});
    const [photoData, setPhotoData] = React.useState(props?.value?.photo?.data??{});
    const [medData, setMedData] = React.useState(props?.value?.med?.data??{});
    const [leadBatData, setLeadBatData] = React.useState(props?.value?.leadBat?.data??{});
    const [otherBatData, setOtherBatData] = React.useState(props?.value?.other?.data??{});
    const [dangerousData, setDangerousData] = React.useState(props?.value?.dangerous?.data??{});
    const [oilyRagsData, setOilyRagsData] = React.useState(props?.value?.oilyRags?.data??{});
    const [otherData, setOtherData] = React.useState(props?.value?.other?.data??{});

    const [hasChecked, setHasChecked] = React.useState(false);

    React.useEffect(() => {
        setCollapsed(!props.checked);
    }, [props.checked]);
    
    React.useEffect(() => {
        onChange();
    }, [paint, printing, photo, med, leadBat, otherBat, dangerous, oilyRags, other,
        paintData, printingData, photoData, medData, leadBatData, otherBatData, dangerousData, oilyRagsData, otherData]);

    const onChange = () => {
        props.onChange({
            paint: {
                active: paint,
                data: paintData
            },
            printing: {
                active: printing,
                data: printingData
            },
            photo: {
                active: photo,
                data: photoData
            },
            med: {
                active: med,
                data: medData
            },
            leadBat: {
                active: leadBat,
                data: leadBatData
            },
            otherBat: {
                active: otherBat,
                data: otherBatData
            },
            dangerous: {
                active: dangerous,
                data: dangerousData
            },
            oilyRags: {
                active: oilyRags,
                data: oilyRagsData
            },
            other: {
                active: other,
                data: otherData
            },
        });
    }

    const Block = (check, setCheck, data, setData, label, ew) => {
        return (<>
            <View style={styles.row}>
                <MyCheckbox onClick={() => {setCheck(prev => !prev);}} checked={check} label={label} isInfo={props.isInfo}/>
            </View>
            {
                check && 
                <View style={[styles.row]}>
                    <AnnexForm ew={ew} wasteMetrics={props.wasteMetrics} onChange={setData} value={data} isInfo={props.isInfo}/>
                </View>
            }
        </>);
    }
    const MultiBlock = (check, setCheck, data, setData, label, ews) => {
        return (<>
            <View style={styles.row}>
                <MyCheckbox onClick={() => {setCheck(prev => !prev);}} checked={check} label={label} isInfo={props.isInfo}/>
            </View>
            {
                check && 
                <View style={[styles.row]}>
                    <AnnexMultiForm ews={ews} wasteMetrics={props.wasteMetrics} onChange={setData} value={data} isInfo={props.isInfo}/>
                </View>
            }
        </>);
    }

    return (
        <View style={[styles.annexContainer, {opacity: props.disabled?0.5:1}]}>
            <TouchableOpacity activeOpacity={0.7} onPress={() => {props.disabled?false:setCollapsed(prev => !prev)}}>
                <MyText style={[css.m, styles.annexTitle, hasChecked?css.bold:css.normal]}>ANNEX V - Operational Hazardous</MyText>
                <View style={{position: 'absolute', right: 10, top: 14}}>
                    <AntDesign name="down" size={12} color="#461b6f" style={{transform: [{rotate: collapsed?'0deg':'180deg'}]}}/>
                </View>
            </TouchableOpacity>
            <View style={styles.formContainer}>
                <View style={{display: collapsed?'none':'flex'}}>
                    {MultiBlock(paint, setPaint, paintData, setPaintData, 'Paint & varnish residues', [
                        {
                            name: 'Waste paint and varnish containing organic solvents or other dangerous substances',
                            code: '08 01 11'
                        },
                        {
                            name: 'Sludges from paint or varnish containing organic solvents or other dangerous substances',
                            code: '08 01 13'
                        },
                        {
                            name: 'Wastes from paint or varnish removal containing organic solvents or other dangerous substances',
                            code: '08 01 17'
                        },
                    ])}
                    {Block(printing, setPrinting, printingData, setPrintingData, 'Waste printing toner', '08 03 17')}
                    {MultiBlock(photo, setPhoto, photoData, setPhotoData, 'Photo waste', [
                        {
                            name: 'Water-based developer and activator solutions',
                            code: '09 01 01'
                        },
                        {
                            name: 'Bleach solutions and bleach fixer solutions',
                            code: '09 01 05'
                        },
                    ])}
                    {Block(med, setMed, medData, setMedData, 'Expired Medicine', '16 03 05')}
                    {Block(leadBat, setLeadBat, leadBatData, setLeadBatData, 'Lead batteries', '16 06 01')}
                    {Block(otherBat, setOtherBat, otherBatData, setOtherBatData, 'Other batteries & Accumulators', '20 01 33')}
                    {Block(dangerous, setDangerous, dangerousData, setDangerousData, 'Packaging contaminated by dangerous substances', '15 01 10')}
                    {Block(oilyRags, setOilyRags, oilyRagsData, setOilyRagsData, 'Oily Rags', '15 02 02')}
                    {MultiBlock(other, setOther, otherData, setOtherData, 'Other substances', [
                        {
                            name: 'Inorganic wastes containing dangerous substances',
                            code: '16 03 03',
                        },
                        {
                            name: 'Organic wastes containing dangerous substances',
                            code: '16 03 05',
                        },
                        {
                            name: 'Discarded inorganic chemicals consisting of or containing dangerous substances',
                            code: '16 05 07',
                        },
                        {
                            name: 'Discarded organic chemicals consisting of or containing dangerous substances',
                            code: '16 05 08',
                        },
                        {
                            name: 'Wastes whose collection and disposal is subject to special requirements in order to prevent infection',
                            code: '18 01 03',
                        },
                        {
                            name: 'Bottom ash, slag and boiler dust from co-incineration containing dangerous substances',
                            code: '10 01 14',
                        },
                        {
                            name: 'Other insulation materials consisting of or containing dangerous substances',
                            code: '17 06 03',
                        },
                        {
                            name: 'Soil and stones containing dangerous substances',
                            code: '17 05 03',
                        },
                        {
                            name: 'Wood containing dangerous substances',
                            code: '20 01 37',
                        },
                        {
                            name: 'Fluorescent tubes and other mercury-containing waste',
                            code: '20 01 21',
                        }
                    ])}
                </View>
            </View>
        </View>
    )
}
const styles = StyleSheet.create({
    annexContainer: {
        marginBottom: 0
    },
    annexTitle: {
        backgroundColor: '#e9e3f0',
        color: '#57307e',
        textAlign: 'center',
        width: '100%',
        padding: 10
    },
    formContainer: {
        padding: 20,
        paddingTop: 15,
        paddingVertical: 0,
        flex: 1
    },
    row: {
        marginBottom: 15
    }
})
export default AnnexVHazardous