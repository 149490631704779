import React, { FunctionComponent } from 'react'
import { StyleSheet, Text, View, TextInput, TouchableOpacity, Platform } from 'react-native';
import Collapsible from 'react-native-collapsible';
import MyCheckbox from '../MyCheckbox';
import MyDatePicker from '../MyDatePicker';
import MyDateTimePicker from '../MyDatetimePicker';
import MyPicker from '../MyPicker';
import MyText from '../MyText';
import MyTextarea from '../MyTextarea';
import MyTextInput from '../MyTextInput';
import stylesheet from '../Style'

const RequestedCollectionMeansAssetForm = (props) => {
    const css = stylesheet();
    
    const [asset, setAsset] = React.useState(props.value?.asset??'');
    const [purpose, setPurpose] = React.useState(props.value?.purpose??'');
    const [duration, setDuration] = React.useState(props.value?.duration??'');
    const [durationType, setDurationType] = React.useState(props.value?.durationType??'Hours');
    const [from, setFrom] = React.useState(props.value?.from??null);
    const [to, setTo] = React.useState(props.value?.to??null);
    const [active, setActive] = React.useState(props.value?.active??true);
    
    const toIsoString = (date) => {
        if(!date)
            return null;
        if(!date.getTimezoneOffset){
            date = new Date(date);
        }
        var tzo = -date.getTimezoneOffset(),
            dif = tzo >= 0 ? '+' : '-',
            pad = function(num) {
                return (num < 10 ? '0' : '') + num;
            };
      
        return date.getFullYear() +
            '-' + pad(date.getMonth() + 1) +
            '-' + pad(date.getDate()) +
            'T' + pad(date.getHours()) +
            ':' + pad(date.getMinutes()) +
            ':' + pad(date.getSeconds()) +
            dif + pad(Math.floor(Math.abs(tzo) / 60)) +
            ':' + pad(Math.abs(tzo) % 60);
      }

    React.useEffect(() => {
        props.onChange({
            asset,
            purpose,
            duration,
            durationType,
            from: toIsoString(from),
            to: toIsoString(to),
            active
        });
    }, [asset, purpose, duration, durationType, from, to, active]);

    const Delete = () => {
        setActive(false);
    }

    return (
        <>
        {active &&
            <View style={[css.formContainer, styles.container, {paddingTop: 24}]}>
                <TouchableOpacity onPress={() => Delete()} style={[{padding: 5, position: 'absolute', right: 4, top: Platform.OS==='android'?-8:-4}]}>
                    <MyText style={[css.ml, {color: '#461b6f'}]}>x</MyText>
                </TouchableOpacity>
                <View style={[{zIndex: 10}, css.formRowFull]}>
                    <MyText style={css.formRowLabelNarrow}>
                        Asset
                    </MyText>
                    <MyPicker
                        style={css.formRowInput}
                        items={['Garbage Truck', 'Hook Lift Container', 'Grapple Container', 'Refrigerator Truck', 'Box Truck', 'Self Propelled Barge', 'Towed Barge']}
                        value={props?.value?.asset}
                        onValueChange={(item, itemIndex) =>
                            setAsset(item.value)
                        }
                        isInfo={props.isInfo}
                        >
                    </MyPicker>
                </View>
                <View style={[{zIndex: 9}, css.formRowFull]}>
                    <MyText style={css.formRowLabelNarrow}>
                        Purpose
                    </MyText>
                    <MyPicker
                        style={css.formRowInput}
                        items={['Collection', 'Delivery', 'Expiration', 'Installation', 'Replacement']}
                        value={props?.value?.purpose}
                        onValueChange={(item, itemIndex) =>
                            setPurpose(item.value)
                        }
                        isInfo={props.isInfo}
                        >
                    </MyPicker>
                </View>
                <View style={[{zIndex: 8}, css.formRowFull]}>
                    <MyText style={css.formRowLabelNarrow}>
                        Duration
                    </MyText>
                    <View style={[{ flex: 1 }, css.doubleInput]}>
                        <MyTextInput
                            inputMode='decimal'
                            keyboardType='phone-pad'
                            style={[{ marginRight: 2 }, css.formRowInput]}
                            onChangeText={(e) => setDuration(e)}
                            value={duration}
                            type={durationType}
                            isInfo={props.isInfo}
                        />
                        <MyPicker
                            style={css.formRowInput}
                            items={['Hours', 'Days']}
                            value={props.isInfo ? '' : durationType}
                            onValueChange={(item, itemIndex) =>
                                setDurationType(item.value)
                            }
                            isInfo={props.isInfo}
                        >
                        </MyPicker>
                    </View>
                </View>
                <View style={[{zIndex: 8}, css.formRowFull]}>
                    <MyText style={css.formRowLabelNarrow}>
                        From
                    </MyText>
                    <View style={[{flex: 1}, css.doubleInput]}>
                        <MyDateTimePicker
                            onChange={setFrom}
                            small={true}
                            value={props?.value?.from?new Date(props.value.from):null}
                            isInfo={props.isInfo}
                            arrival={props.arrival}
                            departure={props.departure}
                            meanTo={props.value.to}
                            validation='meanFrom'
                            optional={true}
                        />
                    </View>
                </View>
                <View style={[{zIndex: 7}, css.formRowFull]}>
                    <MyText style={css.formRowLabelNarrow}>
                        To
                    </MyText>
                    <View style={[{flex: 1}, css.doubleInput]}>
                        <MyDateTimePicker
                            onChange={setTo}
                            small={true}
                            value={props?.value?.to?new Date(props.value.to):null}
                            isInfo={props.isInfo}
                            arrival={props.arrival}
                            departure={props.departure}
                            meanFrom={props.value.from}
                            validation='meanTo'
                            optional={true}
                        />
                    </View>
                </View>
            </View>
        }
        </>
    )
}
const styles = StyleSheet.create({
    container: {
        paddingVertical: 10,
        paddingHorizontal: 14,
        // paddingBottom: 0,
        borderColor: '#532b75',
        borderWidth: 0.5,
        borderRadius: 20,
        marginBottom: 15,
        overflow: 'hidden',
    }
})
export default RequestedCollectionMeansAssetForm