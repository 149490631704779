import React, { FunctionComponent } from 'react'
import { StyleSheet, Text, View, TextInput, ActivityIndicator, Image } from 'react-native';
import DropDownPicker from 'react-native-dropdown-picker';
import { SelectList } from 'react-native-dropdown-select-list'
import { Platform, TouchableOpacity } from 'react-native';
import { AntDesign, MaterialIcons } from '@expo/vector-icons'; 
import MyText from './MyText';
import MyTextInput from './MyTextInput';
import { Dropdown } from 'react-native-element-dropdown';
import stylesheet from './Style'
import axios from 'axios';
import * as db from './database';

const MyShipPicker = (props) => {
    const css = stylesheet();
    
    const inputRef = React.useRef();

    const [isFocus, setIsFocus] = React.useState(false);

    const dropdownRef = React.useRef(null);
    const viewRef = React.useRef(null);

    const [open, setOpen] = React.useState(false);
    const [items, setItems] = React.useState([]);
    const [defaultValue, setDefaultValue] = React.useState({index: -1, value: props.isInfo?'':'Please select...'});
    const [value, setValue] = React.useState(null);
    const [found, setFound] = React.useState(false);
    const [pageY, setPageY] = React.useState(0);
    const [borderRadiusBottom, setBorderRadiusBottom] = React.useState(5);
    const [borderRadiusTop, setBorderRadiusTop] = React.useState(0);

    const [loading, setLoading] = React.useState(false);
    const [search, setSearch] = React.useState('');

    React.useEffect(() => {
        const delayInputTimeoutId = setTimeout(() => {
            const findShip = async() => {
                setItems([]);
                if(search.length > 2){
                    setLoading(true);
                    let temp = await db.findShip(search, !!props.military, !!props.woAis);
                    setItems(temp);
                    setTimeout(() => {
                        setLoading(false);
                    },50)
                }
            }
            findShip().catch(console.error);
        }, 500);
        return () => clearTimeout(delayInputTimeoutId);
    }, [search, 500]);
    
    const renderItem = item => {
        return (
          <View style={styles.item}>
            <View style={{borderBottomWidth: 0.7, borderColor: '#ebe6f2'}}>
                <MyText style={[css.s, {
                    fontFamily: props.getTheApp?'Futura Heavy':'Comfortaa-Regular',
                    color: '#474747',
                    // lineHeight: 30,
                    paddingVertical: 7,
                    opacity: item.value === value?1:0.5
                    // paddingRight: 2
                }]}>{item.label}</MyText>
            </View>
          </View>
        );
    };

    /*
    IMPORTANT changes made to the library:
    1. Replaced in styles.ts in input: {} the margin: 6 to marginVertical: 6
    2. Removed "left: left" from styleVertical: ViewStyle (around line 562) and added it to <View style={Stylesheet.flatten... around line 605)
    */

    return (
        <View style={{flex: 1, width: '100%'}} ref={viewRef} collapsable={false}>
            {
                <Dropdown
                    style={[css.s, {
                        fontFamily: props.getTheApp?'Futura Heavy':'Comfortaa-Regular',
                        borderWidth: props.isInfo?0:1,
                        paddingBottom: 1,
                        paddingRight: props.isInfo?0:3,
                        paddingLeft: props.isInfo?0:6,
                        borderColor: props.hasError?'red':'#bdbdbd',
                        backgroundColor: (props.editable===false)&&(props.isInfo===false||!props.isInfo)?'#e6e6e6':'white',
                        color: '#474747',
                        borderRadius: 8,
                        minHeight: props.getTheApp?35:33,
                        maxHeight: props.getTheApp?35:33,
                        overflow: 'hidden'
                    }, props.style??null]}
                    containerStyle={[css.s, {
                        width: props.width??undefined,
                        borderTopLeftRadius: borderRadiusTop,
                        borderTopRightRadius: borderRadiusTop,
                        borderBottomLeftRadius: borderRadiusBottom,
                        borderBottomRightRadius: borderRadiusBottom,
                        // overflow: 'hidden',
                        // borderColor: props.hasError?'red':'#bdbdbd',
                        borderWidth: 0,
                        fontFamily: props.getTheApp?'Futura Heavy':'Comfortaa-Regular',
                        shadowColor: '#000',
                        shadowOffset: { width: 0, height: 6 },
                        shadowOpacity: 0.5,
                        shadowRadius: 7,
                        elevation: 15,
                        paddingHorizontal: 7,
                        // marginLeft: -7,
                    }]}
                    placeholderStyle={[css.s, {
                        fontFamily: props.getTheApp?'Futura Heavy':'Comfortaa-Regular',
                        color: '#b9bab7',
                        paddingTop: Platform.OS==='android'?0:3,
                        // lineHeight: props.isInfo?33:30,
                        // paddingRight: 2
                    }]}
                    selectedTextStyle={[props.isInfo?css.sm:css.s, {
                            fontFamily: props.getTheApp?'Futura Heavy':'Comfortaa-Regular',
                            color: props.isInfo?'#461b6f':'#474747',
                            paddingTop: Platform.OS==='android'?0:3,
                        }, 
                        props.getTheApp?{
                            fontSize: 0.001
                        }: null
                    ]}
                    inputSearchStyle={[css.s, {
                        fontFamily: props.getTheApp?'Futura Heavy':'Comfortaa-Regular',
                        color: '#474747',
                        height: 33,
                        borderRadius: 8,
                        overflow: 'hidden'
                    }]}
                    onChange={(item) => {
                        setValue(item.value);
                        setIsFocus(false);
                        props.onValueChange({value: item.value, label: item.label});
                    }}
                    //Edited styles.ts "item" to remove padding (probably not necessary anymore due to custom renderItem)
                    renderItem={renderItem}
                    activeColor='white'
                    data={items}
                    mode='default'
                    //Edited styles.ts "input" to remove margin and only set marginVertical to 6
                    search={props.showSearch}
                    disable={props.editable===false || props.isInfo}
                    maxHeight={200}
                    // renderRightIcon={props.isInfo?(()=>{}):()=>(
                    //     <>
                    //         <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    //             {value && <Text onPress={() => {setValue(null)}} style={{marginRight:6}}>
                    //                 <MyText>x</MyText>
                    //             </Text>}
                    //             <Text onPress={() => {console.log("clickdown")}} style={{marginRight:10}}>
                    //                 <Image style={{width: 24, height: 24}} src={{uri: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAQAAAD9CzEMAAAAgUlEQVRYw+2PQQqAMAwE51G2/sxrf6z+oB4UpGoVaoIgO9csMwSEEEKIzxnoHjcdQ6s+kRkJt5vASCa16jOZ6SYRmLZVag/UE7v+5Q/Xidf6YyIWl2ihryfM9GVipgegZ7bTnxPm+mPCQV8mXPTnhLm+TLjo94Sbfk246oUQQvyVBdt/dxNTapL+AAAAAElFTkSuQmCC'}}/>
                    //             </Text>
                    //         </View>
                    //     </>
                    // )}
                    autoScroll={false}
                    labelField="label"
                    valueField="value"
                    placeholder={props.fancyPlaceholder?'':(props.editable===false || props.isInfo ? '':(props.placeholder??'Please select...'))}
                    searchPlaceholder="Search..."
                    value={value}
                    onFocus={() => setIsFocus(true)}
                    onBlur={() => setIsFocus(false)}
                    renderInputSearch={onSearch =>  (
                        <View>
                            <MyTextInput autofocus style={{marginTop: 3}} onChangeTextImmediate={setSearch} placeholder='Search...' />
                            {
                                loading?
                                <ActivityIndicator size="small" color="#461b6f" style={{padding: 10}} />
                                :
                                items.length == 0?
                                <MyText style={styles(props, isFocus).emptyResult}>
                                    {search?.length > 2 ? 'No results':'Please search...'}
                                </MyText>
                                :<></>
                            }
                        </View>
                    )}
                    // renderLeftIcon={() => (
                    //     <AntDesign
                    //     style={styles.icon}
                    //     color={isFocus ? 'blue' : 'black'}
                    //     name="Safety"
                    //     size={20}
                    //     />
                    // )}
                    />
                }
                {
                    props.fancyPlaceholder &&
                    <MyText style={[styles(props, isFocus).placeholder, (isFocus||value)?css.xxs:css.s]}>
                        {props.placeholder}
                    </MyText>
                }
                {
                    props.getTheApp &&
                    <MyText style={[styles(props, isFocus).getTheApp, css.sm]}>
                        {'GET THE APP'}
                    </MyText>
                }
        </View>
    )
}
const styles = (props, isFocus) => StyleSheet.create({
    placeholder: {
        position: 'absolute',
        left: 0,
        top: Platform.OS==='android'?((isFocus||props.value)?-9:1):((isFocus||props.value)?-6:4), //for the border maybe(?)
        paddingLeft: (props.icon && !props.isInfo)?5:null,
        padding: props.isInfo?0:7,
        color: '#474747',
        opacity: 0.5,
        pointerEvents: 'none'
    },
    getTheApp: {
        position: 'absolute',
        left: 0,
        top: Platform.OS==='android'?1:3,
        // paddingLeft: 5,
        padding: 7,
        paddingLeft: 14,
        color: 'white',
        opacity: 1,
        pointerEvents: 'none',
        fontFamily: 'Futura Heavy',
        textAlign: 'center'
    },
    emptyResult: {
        textAlign: 'center',
        padding: 10
    }
})
// const areEqual = (prevProps, currentProps) => {
//     if(!currentProps.items) // If we don't have any items, don't render yet 
//         return true;

//     if(prevProps.items?.length != currentProps.items?.length){
//         return false;
//     }
//     return true;
// }
// export default React.memo(MyPicker, areEqual);
export default MyShipPicker