import React from 'react';
import {
  View,
  StyleSheet,
  TouchableOpacity,
  Platform,
  useWindowDimensions
} from 'react-native';

import MyText from './MyText';
import stylesheet from './Style'
import axios from 'axios';

import MyRequests from './assets/icons/MyRequests.svg';
import NewRequest from './assets/icons/NewRequest.svg';
import Dashboard from './assets/icons/Dashboard.svg';
import Documents from './assets/icons/Documents.svg';
import MySVG from './assets/MySVG';

import AsyncStorage from '@react-native-async-storage/async-storage';
import { FontAwesome5, MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons';

import * as db from './database';
import { useModal } from './hooks/modal';

const SideMenu = (props) => {
    const css = stylesheet();
    const modal = useModal();

    const [count, setCount] = React.useState(0);
    
    const {height, width} = useWindowDimensions();

    React.useEffect(() => {
        const fetchData = async () => {
          console.log("Fetching notifications");
          // const deviceState = await OneSignal.getDeviceState();
          let notifications = JSON.parse(await AsyncStorage.getItem('notifications')??'{}');
          // if(props.user && deviceState && deviceState.userId){
          if(props.user){
              axios.get('/api/getNotificationsCount.php', {
                params: {
                    uuid: await AsyncStorage.getItem('uuid')
                }
              })
              .then(async (res) => {
                if(res.data){
                  let seen = 0;
                  res.data.forEach(dbId => {
                    let hasSeen = false;
                    if(notifications[dbId]){
                      hasSeen = true;
                      seen++;
                    }
                  })
                  let temp = res.data.length-seen;
                  setCount(temp);
                }
              })
          }
        }
      
        fetchData().catch(console.error);
    },[])
    
    const Logout = () => {
        axios.get('/api/user/logout.php')
        .then(async() => {
            await AsyncStorage.removeItem('token');
            await AsyncStorage.removeItem('user');
            props.setUser(null);
            props.navigation?.push('Landing Page');
            db.logout();
        })
    }

    const onPress = (name) => {
      if(Platform.OS==='web'){
        document.documentElement.scrollTop = 0;
      }
      if(name==='Logout'){
        Logout();
      }
      else if(name==='My Notifications' && Platform.OS === 'web'){
        modal.confirm.show('Warning', 'This functionality is available only in the $android & $ios mobile apps.');
      }
      else{
        props.navigation?.push(name);
      }
    }
    const Touch = (name, svg, icon) => {
        return (
            <TouchableOpacity style={[styles.touchable]} onPress={() => onPress(name)}>
                <View style={{width: 8, height: 35, marginRight: 12, backgroundColor: props.route.name===name?'#461b6f':'white'}}></View>
                {
                    svg?
                    <MySVG source={svg} style={styles.icon} />
                    :
                    icon?icon:null
                }
                <View style={styles.labelContainer}>
                    <MyText style={[css.sm, styles.label, props.route.name===name?css.bold:null]}>
                        {name}
                    </MyText>
                </View>
            </TouchableOpacity>
        )
    }

    const MyNotifications = () => {
        return <View style={styles.notificationsContainer}>
            <MaterialCommunityIcons name="bell" size={20} color="#461b6f" />
            { count != 0 &&
            <MyText style={[css.xxs, styles.notifications]}>
                {count}
            </MyText>
            }
        </View>
    }

    return (
    <View style={{flex: 1, paddingVertical: 20, marginBottom: 0, backgroundColor: '#f6f4f9'}}>
        <View style={styles.content}>
            {
                props.user && !props.user.isVGroup?
                <>
                    {Touch('My Requests', MyRequests, null)}
                    {Touch('New Request', NewRequest, null)}
                    {Touch('Dashboard', Dashboard, null)}
                    {Touch('Documents', Documents, null)}
                    {Touch('My Notifications', null, <MyNotifications/>)}
                    {Touch('Logout', null, <MaterialIcons name="logout" size={13} color="white" style={[styles.icon, styles.logout]}/> )}
                </>
                :<></>
            }
            {
                props.user && props.user.isVGroup?
                <>
                    {Touch('Manage Requests', MyRequests, null)}
                    {Touch('New Request', NewRequest, null)}
                    {Touch('Manage Users', null, <FontAwesome5 name="users-cog" size={16} color="#461b6f" style={styles.vectorIcon} />)}
                    {Touch('Dashboard', Dashboard, null)}
                    {Touch('Documents', Documents, null)}
                    {Touch('My Notifications', null, <MyNotifications/>)}
                    {Touch('Logout', null, <MaterialIcons name="logout" size={13} color="white" style={[styles.icon, styles.logout]}/> )}
                </>
                :<></>
            }
        </View>
    </View>
    )
}

const styles = StyleSheet.create({
    background: {
        display: 'flex',
        backgroundColor: '#F6F4F9',
        padding: 10,
        flexDirection: 'row',
        alignItems: 'center',
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        borderTopWidth: 4,
        borderColor: '#e9e3f0',
        // boxShadow: '0px 0px 15px #e9e3f0',
        justifyContent: 'space-around',
        paddingBottom: Platform.OS === 'ios' ? 20 : 10
      },
      touchable: {
        alignItems: 'center',
        position: 'relative',
        flexDirection: 'row',
      },
      icon: {
        width: 20,
        height: 20,
        resizeMode: 'contain',
        marginTop: -3,
        marginRight: 8,
      },
      vectorIcon: {
        width: 20,
        height: 20,
        resizeMode: 'contain',
        paddingTop: 0,
        marginRight: 8,
      },
      label: {
        color: '#461b6f',
        marginVertical: 10
      },
      content: {
        backgroundColor: 'white',
        paddingVertical: 10,
        marginHorizontal: 10,
        position: Platform.OS==='web'?'fixed':null,
        left: 0,
        right: 0
      },
      notificationsContainer: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        marginRight: 8
    },
    notifications: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        left: -4,
        top: -2,
        borderRadius: 8,
        overflow: 'hidden',
        width: 16,
        lineHeight: 15,
        height: 16,
        color: 'white',
        borderWidth: 1,
        borderColor: 'white',
        backgroundColor: '#461b6f',
        fontWeight: 'bold',
        textAlign: 'center'
    },
    logout: {
        backgroundColor: '#461b6f',
        borderRadius: 10,
        paddingLeft: 5,
        paddingTop: 3,
        overflow: 'hidden'
    },
});

export default SideMenu
