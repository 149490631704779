import React from 'react';
import {
  SafeAreaView,
  View,
  TouchableOpacity,
  Platform
} from 'react-native';

import stylesheet from './Style'
import MyText from './MyText';

const CustomBottomSheetNewRequest = (props) => {
    const css = stylesheet();

  return (
    <SafeAreaView style={{ flex: 1, zIndex: 2 }}>
        <View style={{flex: 1, marginHorizontal: 30, marginBottom: 20, flexDirection: 'row', alignItems: 'center'}}>
            <View style={{flexShrink: 1, flexGrow: 1}}>
                <MyText style={css.s}>
                    Disclaimer for new request
                </MyText>
            </View>
            <View style={{marginLeft: 20}}>
                <TouchableOpacity onPress={() => props.bottomSheet.current.dismiss()}>
                    <View style={[css.smallButton, {backgroundColor: '#461b6f', paddingHorizontal: 20}]}>
                        <MyText style={[css.bold, css.smallButtonText, {color: 'white'}]}>
                            Continue
                        </MyText>
                    </View>
                </TouchableOpacity>
            </View>
        </View>
    </SafeAreaView>
  );
};

export default CustomBottomSheetNewRequest
