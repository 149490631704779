import React, { FunctionComponent } from 'react'
import { StyleSheet, Text, View, TextInput } from 'react-native';
import MyCheckbox from '../../MyCheckbox';
import MyText from '../../MyText';
import AnnexForm from '../AnnexForm';
import { AntDesign } from '@expo/vector-icons'; 
import stylesheet from '../../Style'
import { TouchableOpacity } from 'react-native-gesture-handler';

const AnnexIINonHazardous = (props) => {
    const css = stylesheet();
    
    const [collapsed, setCollapsed] = React.useState(true);

    const [categoryX, setCategoryX] = React.useState(props?.value?.categoryX?.active??false);
    const [categoryY, setCategoryY] = React.useState(props?.value?.categoryY?.active??false);
    const [categoryZ, setCategoryZ] = React.useState(props?.value?.categoryZ?.active??false);
    const [other, setOther] = React.useState(props?.value?.other?.active??false);

    const [categoryXData, setCategoryXData] = React.useState(props?.value?.categoryX?.data??{});
    const [categoryYData, setCategoryYData] = React.useState(props?.value?.categoryY?.data??{});
    const [categoryZData, setCategoryZData] = React.useState(props?.value?.categoryZ?.data??{});
    const [otherData, setOtherData] = React.useState(props?.value?.other?.data??{});

    const [hasChecked, setHasChecked] = React.useState(false);

    React.useEffect(() => {
        setCollapsed(!props.checked);
    }, [props.checked]);
    
    React.useEffect(() => {
        onChange();
    }, [categoryX, categoryY, categoryZ, other,
        categoryXData, categoryYData, categoryZData, otherData]);

    const onChange = () => {
        props.onChange({
            categoryX: {
                active: categoryX,
                data: categoryXData
            },
            categoryY: {
                active: categoryY,
                data: categoryYData
            },
            categoryZ: {
                active: categoryZ,
                data: categoryZData
            },
            other: {
                active: other,
                data: otherData
            },
        });
    }

    return (
        <View style={[styles.annexContainer, {opacity: props.disabled?0.5:1}]}>
            <TouchableOpacity activeOpacity={0.7} onPress={() => {props.disabled?false:setCollapsed(prev => !prev)}}>
                <MyText style={[css.m, styles.annexTitle, hasChecked?css.bold:css.normal]}>ANNEX II - NLS in bulk</MyText>
                <View style={{position: 'absolute', right: 10, top: 14}}>
                    <AntDesign name="down" size={12} color="#461b6f" style={{transform: [{rotate: collapsed?'0deg':'180deg'}]}}/>
                </View>
            </TouchableOpacity>
            <View style={styles.formContainer}>
                <View style={{display: collapsed?'none':'flex'}}>
                    <View style={styles.row}>
                        <MyCheckbox onClick={() => {setCategoryX(prev => !prev);}} checked={categoryX} label='Category X Substance' isInfo={props.isInfo}/>
                    </View>
                    {
                        categoryX && 
                        <View style={[styles.row]}>
                            <AnnexForm ew={'801801'} wasteMetrics={props.wasteMetrics} onChange={setCategoryXData} value={categoryXData} isInfo={props.isInfo}/>
                        </View>
                    }
                    <View style={styles.row}>
                        <MyCheckbox onClick={() => {setCategoryY(prev => !prev);}} checked={categoryY} label='Category Y Substance' isInfo={props.isInfo}/>
                    </View>
                    {
                        categoryY &&
                        <View style={[styles.row]}>
                            <AnnexForm ew={'801802'} wasteMetrics={props.wasteMetrics} onChange={setCategoryYData} value={categoryYData} isInfo={props.isInfo}/>
                        </View>
                    }
                    <View style={styles.row}>
                        <MyCheckbox onClick={() => {setCategoryZ(prev => !prev);}} checked={categoryZ} label='Category Z Substance' isInfo={props.isInfo}/>
                    </View>
                    {
                        categoryZ &&
                        <View style={[styles.row]}>
                            <AnnexForm ew={'801803'} wasteMetrics={props.wasteMetrics} onChange={setCategoryZData} value={categoryZData} isInfo={props.isInfo}/>
                        </View>
                    }
                    <View style={styles.row}>
                        <MyCheckbox onClick={() => {setOther(prev => !prev);}} checked={other} label='Other (please specify)' isInfo={props.isInfo}/>
                    </View>
                    {
                        other &&
                        <View style={[styles.row]}>
                            <AnnexForm ew={'801804'} wasteMetrics={props.wasteMetrics} onChange={setOtherData} value={otherData} isInfo={props.isInfo}/>
                        </View>
                    }
                </View>
            </View>
        </View>
    )
}
const styles = StyleSheet.create({
    annexContainer: {
        marginBottom: 0
    },
    annexTitle: {
        backgroundColor: '#e9e3f0',
        color: '#57307e',
        textAlign: 'center',
        width: '100%',
        padding: 10
    },
    formContainer: {
        padding: 20,
        paddingTop: 15,
        paddingVertical: 0,
        flex: 1
    },
    row: {
        marginBottom: 15
    }
})
export default AnnexIINonHazardous