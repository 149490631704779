import React from 'react';
import {
    StyleSheet,
    View,
    TouchableOpacity,
    ScrollView,
    TouchableWithoutFeedback,
    ActivityIndicator
  } from 'react-native'
import stylesheet from './Style'
import MyText from './MyText';
import MyDatePicker from './MyDatePicker';
import MyTextInput from './MyTextInput';
import MyPicker from './MyPicker';
import { MaterialIcons, Entypo, Feather } from '@expo/vector-icons'; 
import * as SecureStore from 'expo-secure-store';
import axios from 'axios';
import MyCheckbox from './MyCheckbox';

import * as db from './database';
import AsyncStorage from '@react-native-async-storage/async-storage';

export default function RegisterShipUser(props) {
    const defaultGrossTonnageType = 'Ton';
    const defaultGrtType = 'Ton';
    const defaultDwtType = 'Ton';

    const css = stylesheet();
    
    const [countries, setCountries] = React.useState(null);
    const [shipTypes, setShipTypes] = React.useState(null);
    const [wasteMetrics, setWasteMetrics] = React.useState(null);

    const [countryFlagCords, setCountryFlagCords] = React.useState(0);

    const [military, setMilitary] = React.useState(false);
    const [woAis, setWoAis] = React.useState(false);
    const [newShip, setNewShip] = React.useState(false);
    const [shipEditable, setShipEditable] = React.useState(true);

    const [ships, setShips] = React.useState(null);

    const [grossTonnageType, setGrossTonnageType] = React.useState(defaultGrossTonnageType);
    const [grtType, setGrtType] = React.useState(defaultGrtType);
    const [dwtType, setDwtType] = React.useState(defaultDwtType);

    const [imo, setImo] = React.useState({
        value: '',
        hasError: false,
        valid: false
    });
    const [shipName, setShipName] = React.useState({
        value: '',
        hasError: false
    });
    const [shipType, setShipType] = React.useState({
        value: '',
        hasError: false
    });
    const [countryFlag, setCountryFlag] = React.useState({
        value: '',
        hasError: false
    });
    const [grossTonnage, setGrossTonnage] = React.useState({
        value: '',
        hasError: false
    });
    const [registryOffice, setRegistryOffice] = React.useState({
        value: '',
        hasError: false
    });
    const [scnt, setScnt] = React.useState({
        value: '',
        hasError: false
    });
    const [environmentalFee, setEnvironmentalFee] = React.useState(false);
    const [grt, setGrt] = React.useState({
        value: '',
        hasError: false
    });
    const [dwt, setDwt] = React.useState({
        value: '',
        hasError: false
    });

    //Person in charge
    const [contactPersonFName, setContactPersonFName] = React.useState({
        value: '',
        hasError: false
    });
    const [contactPersonLName, setContactPersonLName] = React.useState({
        value: '',
        hasError: false
    });
    const [contactPersonProfession, setContactPersonProfession] = React.useState({
        value: '',
        hasError: false
    });
    const [contactPersonEmail, setContactPersonEmail] = React.useState({
        value: '',
        hasError: false,
        valid: false
    });
    const [contactPersonPhone, setContactPersonPhone] = React.useState({
        value: '',
        hasError: false,
        valid: false
    });

    const GetShipByIMO = async(parameter) => {
        setShipEditable(true);
        try{
            ships.forEach(ship => {
                if(ship.imo == (parameter?parameter:imo.value)){
                    setNewShip(false);
                    SetShip(ship);
                }
            })
        }catch(e){}
      }
      const GetShipByName = async(parameter) => {
          setShipEditable(true);
          try{
            ships.forEach(ship => {
                if(ship.shipName.toLowerCase() == (parameter?parameter.toLowerCase():shipName.value.toLowerCase())){
                    setNewShip(false);
                    SetShip(ship);
                }
            })
        }catch(e){}
      }
      const SetShip = (data) => {
        setShipEditable(data?false:true);
        setImo({
            hasError: false,
            valid: true,
            value: (data?.imo??"").toString()
        })
        setShipName({
            hasError: false,
            value: (data?.shipName??"").toString()
        })
        setCountryFlag({
            hasError: false,
            value: (data?.countryFlag??"").toString()
        })
        setGrossTonnage({
            hasError: false,
            value: (data?.grossTonnage??"").toString()
        })
        setGrossTonnageType((data?.grossTonnageType??defaultGrossTonnageType).toString())
        setDwt({
            hasError: false,
            value: (data?.dwt??"").toString()
        })
        setDwtType((data?.dwtType??defaultDwtType).toString())
        setGrt({
            hasError: false,
            value: (data?.grt??"").toString()
        })
        setGrtType((data?.grtType??defaultGrtType).toString())
        if(!(data?.grt)){
            setGrt({
                hasError: false,
                value: (data?.grossTonnage??"").toString()
            })
            setGrtType((data?.grossTonnageType??defaultGrossTonnageType).toString())
        }
        setShipType({
            hasError: false,
            value: (data?.shipType??"").toString()
        })
        setRegistryOffice({
            hasError: false,
            value: (data?.registryOffice??"").toString()
        })
        setScnt({
            hasError: false,
            value: (data?.scnt??"").toString()
        })
        setEnvironmentalFee(!!(data?.environmentalFee));
        if(data){
            setMilitary(!!(data?.military));
            setWoAis(!!(data?.woAis));
        }
        setContactPersonFName({
            hasError: false,
            value: (data?.contactPersonFName??"").toString()
        })
        setContactPersonLName({
            hasError: false,
            value: (data?.contactPersonLName??"").toString()
        })
        setContactPersonProfession({
            hasError: false,
            value: (data?.contactPersonProfession??"").toString()
        })
        setContactPersonEmail({
            hasError: false,
            valid: true,
            value: (data?.contactPersonEmail??"").toString()
        })
        setContactPersonPhone({
            hasError: false,
            valid: true,
            value: (data?.contactPersonPhone??"").toString()
        })
      }

    React.useEffect(() => {
        // calculate hasError and send to parent
        console.log(imo);
        setImo(val => ({...val, hasError: (!val.value || !val.valid)}));
        setShipName(val => ({...val, hasError: !val.value}));
        setShipType(val => ({...val, hasError: !val.value}));
        setGrossTonnage(val => ({...val, hasError: !val.value}));
        setGrt(val => ({...val, hasError: !val.value}));
        setDwt(val => ({...val, hasError: !val.value}));
        setCountryFlag(val => ({...val, hasError: !val.value}));
        setRegistryOffice(val => ({...val, hasError: !val.value}));

        setContactPersonFName(val => ({...val, hasError: !val.value}));
        setContactPersonLName(val => ({...val, hasError: !val.value}));
        setContactPersonEmail(val => ({...val, hasError: (!val.value || !val.valid)}));
        setContactPersonPhone(val => ({...val, hasError: (!val.value || !val.valid)}));
    },[imo.value, shipName.value, shipType.value, grossTonnage.value, grt.value, dwt.value, countryFlag.value, registryOffice.value, scnt.value,
        contactPersonFName.value, contactPersonLName.value, contactPersonProfession.value, contactPersonEmail.value, contactPersonPhone.value,
            imo.valid, contactPersonEmail.valid, contactPersonPhone.valid])

    React.useEffect(() => {
        const data = {
            imo,
            shipName,
            shipType,
            grossTonnage,
            grossTonnageType,
            grt,
            grtType,
            dwt,
            dwtType,
            countryFlag,
            registryOffice,
            scnt,
            military,
            woAis,
            newShip,
            environmentalFee,
            contactPersonFName,
            contactPersonLName,
            contactPersonProfession,
            contactPersonEmail,
            contactPersonPhone
        }
        props.setRegisterShipUser(data);
    },[imo.hasError, shipName.hasError, shipType.hasError, grossTonnage.hasError, grt.hasError, dwt.hasError, countryFlag.hasError, registryOffice.hasError, scnt.hasError,
        contactPersonFName.hasError, contactPersonLName.hasError, contactPersonProfession.hasError, contactPersonEmail.hasError, contactPersonPhone.hasError,
        imo.value, shipName.value, shipType.value, grossTonnage.value, grossTonnageType, grt.value, grtType, dwt.value, dwtType, countryFlag.value, registryOffice.value, scnt.value,
        contactPersonFName.value, contactPersonLName.value, contactPersonProfession.value, contactPersonEmail.value, contactPersonPhone.value,
        military, woAis, newShip])

            
    // React.useEffect(() => {
    //     setImo(val => ({...val, hasError: (!val.value || !val.valid)}));
    //     setContactPersonEmail(val => ({...val, hasError: (!val.value || !val.valid)}));
    //     setContactPersonPhone(val => ({...val, hasError: (!val.value || !val.valid)}));
    // },[imo.valid, contactPersonEmail.valid, contactPersonPhone.valid])

    React.useEffect(() => {
        const filters = async () => {
            try{
                await db.syncFilters();
                setCountries(await db.getAll('countries'));
                setShips(await db.getAll('datalastic', 'shipName'));
                setShipTypes(await db.getAll('ship_types'));
                setWasteMetrics(await db.getAll('waste_metrics'));
            }
            catch(e){}
        }
        filters().catch(console.error);
    },[]);

    React.useEffect(() => {
        if(props.initialShip){
            const data = props.initialShip;
            setImo(prev => ({...prev, value: data.imo.toString()}));
            setShipName(prev => ({...prev, value: data.shipName}));
            setShipType(prev => ({...prev, value: data.shipType}));
            setGrossTonnage(prev => ({...prev, value: data.grossTonnage}));
            setGrossTonnageType(data.grossTonnageType);
            setGrt(prev => ({...prev, value: data.grt}));
            setGrtType(data.grtType);
            setDwt(prev => ({...prev, value: data.dwt}));
            setDwtType(data.dwtType);
            setCountryFlag(prev => ({...prev, value: data.countryFlag}));
            setRegistryOffice(prev => ({...prev, value: data.registryOffice}));
            setScnt(prev => ({...prev, value: data.scnt??''}));
            setEnvironmentalFee(prev => ({...prev, value: !!data.environmentalFee}));
            setContactPersonFName(prev => ({...prev, value: data.contactPersonFName}));
            setContactPersonLName(prev => ({...prev, value: data.contactPersonLName}));
            setContactPersonProfession(prev => ({...prev, value: data.contactPersonProfession}));
            setContactPersonEmail(prev => ({...prev, value: data.contactPersonEmail}));
            setContactPersonPhone(prev => ({...prev, value: data.contactPersonPhone}));
            // setCompanyRegNum(prev => ({...prev, value: data.companyRegNumber.toString()}));
            // setCompanyName(prev => ({...prev, value: data.companyName}));
            // setAddress(prev => ({...prev, value: data.address}));
            // setArea(prev => ({...prev, value: data.area}));
            // setPostalCode(prev => ({...prev, value: data.postalCode.toString()}));
            // setCountryFlag(prev => ({...prev, value: data.country}));
            // setVatNumber(prev => ({...prev, value: data.vat}));
            // setVatType(prev => ({...prev, value: data.vatType}));
            // setTaxOffice(prev => ({...prev, value: data.taxOffice}));
            // setVatExceptionReason(prev => ({...prev, value: data.vatExceptionReason}));
            // setEmail(prev => ({...prev, value: data.email}));
            // setPhone(prev => ({...prev, value: data.phone}));
            // setWebsite(prev => ({...prev, value: data.website}));
            // setContactPersonFName(prev => ({...prev, value: data.contactFirstName}));
            // setContactPersonLName(prev => ({...prev, value: data.contactLastName}));
            // setContactPersonProfession(prev => ({...prev, value: data.contactPosition}));
            // setContactPersonEmail(prev => ({...prev, value: data.contactEmail}));
            // setContactPersonPhone(prev => ({...prev, value: data.contactPhone}));
        }
    },[props.initialShip])

    const MilitaryCheckbox = () => {
        if(!military){
            SetShip();
            setImo(prev => ({
                ...prev,
                value: '0000000',
                hasError: false,
                valid: true
            }))
            setShipName(prev => ({
                ...prev,
                value: ''
            }))
        }
        setMilitary(prev => !prev);
    }
    const WoAisCheckbox = () => {
        if(!woAis){
            SetShip();
            setImo(prev => ({
                ...prev,
                value: '0000000',
                hasError: false,
                valid: true
            }))
            setShipName(prev => ({
                ...prev,
                value: ''
            }))
        }
        setWoAis(prev => !prev);
    }
    const NewShipCheckbox = () => {
        if(!newShip){
            if(military || woAis) {
                SetShip();
                setImo(prev => ({
                    ...prev,
                    value: '0000000',
                    hasError: false,
                    valid: true
                }))
            }
            else {
                setImo(prev => ({
                    ...prev,
                    value: ''
                }))
            }
            setShipName(prev => ({
                ...prev,
                value: ''
            }))
            if(!military && !woAis) {
                SetShip();
            }
        }
        setNewShip(prev => !prev);
    }

    return (
      <>
    <View style={[{zIndex: 10}, css.form]} >
            <MyText style={[css.formTitle, css.bold]}>
                Vessel Information
            </MyText>
            <View
            style={{
                borderBottomColor: '#f3f1f8',
                borderBottomWidth: 2,
            }}
            />
        <View style={css.formContainer}>
            {
                (props.isInfo)?
                <></>
                :
                <>
                    <View style={[css.formRow, {flexBasis: '100%', marginTop: 20, marginBottom: 15, justifyContent: 'space-between', gap: 60}]}>
                        <MyText style={[css.m, styles.contactPersonTitle]}>
                            { newShip ? 'New Vessel' : 'Select existing Vessel' }
                        </MyText>
                        <View style={{gap: 10, flexFlow: 'row wrap', flex: 1, justifyContent: 'space-between'}}>
                            <View>
                                <MyCheckbox onClick={() => MilitaryCheckbox()} checked={military} label='Military?'/>
                            </View>
                            <View>
                                <MyCheckbox onClick={() => WoAisCheckbox()} checked={woAis} label='w/o AIS?' nowrap={true}/>
                            </View>
                            <View>
                                <MyCheckbox onClick={() => NewShipCheckbox()} checked={newShip} label='New?'/>
                            </View>
                        </View>
                    </View>
                    {
                        newShip?
                        <></>
                        :
                        <>
                            <View style={css.formRow}>
                                <MyText style={css.formRowLabel}>
                                IMO*
                                </MyText>
                                <MyPicker
                                    style={css.formRowInput}
                                    items={ships?.map(x => parseInt(x.imo)).sort((a, b) => (a - b)).map(x => x.toString())}
                                    onValueChange={(item, itemIndex) => {
                                            ships.forEach(x => {
                                                if(x.imo == item.value){
                                                    setShipName(prev => ({
                                                        ...prev,
                                                        value: x.shipName.toString(),
                                                        hasError: false
                                                    }))
                                                }
                                            })
                                            setImo(prev => ({
                                                ...prev,
                                                value: item.value,
                                                hasError: false,
                                                valid: true
                                            }))
                                            GetShipByIMO(item.value);
                                        }
                                    }
                                    value={imo.value}
                                    showSearch={true}
                                    placeholder={'IMO'}
                                />
                            </View>
                            <View style={css.formRow}>
                                <MyText style={css.formRowLabel}>
                                    Vessel Name*
                                </MyText>
                                <MyPicker
                                    style={css.formRowInput}
                                    items={ships?.map(x => x.shipName.toString())}
                                    onValueChange={(item, itemIndex) => {
                                            ships.forEach(x => {
                                                if(x.shipName == item.value){
                                                    setImo(prev => ({
                                                        ...prev,
                                                        value: x.imo.toString(),
                                                        hasError: false,
                                                        valid: true
                                                    }))
                                                }
                                            })
                                            setShipName(prev => ({
                                                ...prev,
                                                value: item.value,
                                                hasError: false
                                            }))
                                            GetShipByName(item.value);
                                        }
                                    }
                                    value={shipName.value}
                                    showSearch={true}
                                    placeholder={'Vessel Name'}
                                />
                            </View>
                        </>
                    }
                </>   
            }
            {
                (newShip || props.isInfo)?
                <>
                    <View style={css.formRow}>
                        <MyText style={css.formRowLabel}>
                        IMO*
                        </MyText>
                        <MyTextInput
                            inputMode='decimal'
                            keyboardType='phone-pad'
                            style={css.formRowInput}
                            onChangeText={(e) => {
                                    setImo(prev => ({
                                        ...prev,
                                        value: e
                                    }))
                                    GetShipByIMO(e);
                                }
                            }
                            onChangeValidation={(e) => {
                                setImo(prev => ({
                                        ...prev,
                                        valid: e
                                    }))
                                }
                            }
                            value={imo.value}
                            hasError={imo.hasError}
                            validation='imo'
                            editable={props.id==undefined && !woAis && !military}
                            isInfo={props.isInfo}
                        />
                    </View>
                    <View style={css.formRow}>
                        <MyText style={css.formRowLabel}>
                        Vessel Name*
                        </MyText>
                        <MyTextInput
                            style={css.formRowInput}
                            onChangeText={(e) => {
                                    setShipName(prev => ({
                                        ...prev,
                                        value: e
                                    }))
                                    GetShipByName(e);
                                }
                            }
                            value={shipName.value}
                            hasError={shipName.hasError}
                            editable={props.id==undefined}
                            isInfo={props.isInfo}
                        />
                    </View>
                </>
                :
                <></>
            }
            <View style={css.formRow}>
                <MyText style={css.formRowLabel}>
                    Vessel Type*
                </MyText>
                <MyPicker
                    style={css.formRowInput}
                    items={shipTypes}
                    onValueChange={(item, itemIndex) => setShipType(prev => ({
                        ...prev,
                        value: item.value
                    }))}
                    value={shipType.value}
                    hasError={shipType.hasError && props.submit}
                    editable={shipEditable}
                    isInfo={props.isInfo}
                />
            </View>
            <View style={[{zIndex: 9}, css.formRow]}>
                <MyText style={css.formRowLabel}>
                Gross Tonnage*
                </MyText>
                <View style={[{flex: 1}, css.doubleInput]}>
                <MyTextInput
                    inputMode='decimal'
                    keyboardType='phone-pad'
                    style={[{flexGrow: 1, flexShrink: 1, marginRight: 5}, css.formRowInput]}
                    onChangeText={(e) => setGrossTonnage(prev => ({
                        ...prev,
                        value: e
                    }))}
                    value={grossTonnage.value}
                    hasError={grossTonnage.hasError && props.submit}
                    editable={shipEditable}
                    isInfo={props.isInfo}
                />
                <View style={css.secondInput}>
                    <MyPicker
                        style={css.formRowInput}
                        width={65}
                        items={wasteMetrics}
                        onValueChange={(item, itemIndex) => setGrossTonnageType(item.value)}
                        value={grossTonnageType}
                        editable={shipEditable}
                        isInfo={props.isInfo}
                    >
                    </MyPicker>
                </View>
                </View>
            </View>
            <View style={[{zIndex: 8}, css.formRow]}>
                <MyText style={css.formRowLabel}>
                GRT*
                </MyText>
                <View style={[{flex: 1}, css.doubleInput]}>
                <MyTextInput
                    inputMode='decimal'
                    keyboardType='phone-pad'
                    style={[{flexGrow: 1, flexShrink: 1, marginRight: 5}, css.formRowInput]}
                    onChangeText={(e) => setGrt(prev => ({
                        ...prev,
                        value: e
                    }))}
                    value={grt.value}
                    hasError={grt.hasError && props.submit}
                    editable={shipEditable}
                    isInfo={props.isInfo}
                />
                <View style={css.secondInput}>
                    <MyPicker
                        style={css.formRowInput}
                        width={65}
                        items={wasteMetrics}
                        onValueChange={(item, itemIndex) => setGrtType(item.value)}
                        value={grtType}
                        editable={shipEditable}
                        isInfo={props.isInfo}
                    >
                    </MyPicker>
                </View>
                </View>
            </View>
            <View style={[{zIndex: 7}, css.formRow]}>
                <MyText style={css.formRowLabel}>
                DWT*
                </MyText>
                <View style={[{flex: 1}, css.doubleInput]}>
                <MyTextInput
                    inputMode='decimal'
                    keyboardType='phone-pad'
                    style={[{flexGrow: 1, flexShrink: 1, marginRight: 5}, css.formRowInput]}
                    onChangeText={(e) => setDwt(prev => ({
                        ...prev,
                        value: e
                    }))}
                    value={dwt.value}
                    hasError={dwt.hasError && props.submit}
                    editable={shipEditable}
                    isInfo={props.isInfo}
                />
                <View style={css.secondInput}>
                    <MyPicker
                        style={css.formRowInput}
                        width={65}
                        items={wasteMetrics}
                        onValueChange={(item, itemIndex) => setDwtType(item.value)}
                        value={dwtType}
                        editable={shipEditable}
                        isInfo={props.isInfo}
                    >
                    </MyPicker>
                </View>
                </View>
            </View>
            <View style={[{zIndex: 6}, css.formRow]} onLayout={(event) => {setCountryFlagCords(event.nativeEvent.layout.y + 450)}}>
                <MyText style={css.formRowLabel}>
                Country Flag*
                </MyText>
                <MyPicker
                    style={css.formRowInput}
                    items={countries}
                    onValueChange={(item, itemIndex) => setCountryFlag(prev => ({
                        ...prev,
                        value: item.value
                    }))}
                    showSearch={true}
                    value={countryFlag.value}
                    hasError={countryFlag.hasError && props.submit}
                    // willShow={() => {
                    //         props.scrollRef.current.scrollTo({
                    //             x: 0,
                    //             y: countryFlagCords,
                    //             animated: false,
                    //         })
                    //     }
                    // }
                    editable={shipEditable}
                    isInfo={props.isInfo}
                >
                </MyPicker>
            </View>
            <View style={css.formRow}>
                <MyText style={css.formRowLabel}>
                Registry Office*
                </MyText>
                <MyTextInput
                style={css.formRowInput}
                onChangeText={(e) => setRegistryOffice(prev => ({
                    ...prev,
                    value: e
                }))}
                value={registryOffice.value}
                hasError={registryOffice.hasError && props.submit}
                editable={shipEditable}
                isInfo={props.isInfo}
                />
            </View>
            <View style={css.formRow}>
                <MyText style={css.formRowLabel}>
                    SCNT (Suez Canal Net Tonnage)
                </MyText>
                <MyTextInput
                    inputMode='decimal'
                    keyboardType='phone-pad'
                    style={css.formRowInput}
                    onChangeText={(e) => setScnt(prev => ({
                        ...prev,
                        value: e
                    }))}
                    value={scnt.value}
                    hasError={scnt.hasError && props.submit}
                    isInfo={props.isInfo}
                />
            </View>
            <View style={css.formRow}>
                <MyText style={css.formRowLabel}>
                    
                </MyText>
                <MyCheckbox onClick={() => setEnvironmentalFee(prev => !prev)} checked={environmentalFee} label='Not subject to environmental fee'/>
            </View>
            <View style={[css.formRow, {flexBasis: '100%', marginTop: 20, marginBottom: 15}]}>
                <MyText style={[css.m, styles.contactPersonTitle]}>
                Person in charge
                </MyText>
            </View>
            <View style={[{zIndex: 10}, css.formRow]}>
                <MyText style={css.formRowLabel}>
                    First Name*
                </MyText>
                <MyTextInput
                    style={css.formRowInput}
                    onChangeText={(e) => setContactPersonFName(prev => ({
                        ...prev,
                        value: e
                    }))}
                    value={contactPersonFName.value}
                    hasError={contactPersonFName.hasError && props.submit}
                    isInfo={props.isInfo}
                />
            </View>
            <View style={[{zIndex: 9}, css.formRow]}>
                <MyText style={css.formRowLabel}>
                    Last Name*
                </MyText>
                <MyTextInput
                    style={css.formRowInput}
                    onChangeText={(e) => setContactPersonLName(prev => ({
                        ...prev,
                        value: e
                    }))}
                    value={contactPersonLName.value}
                    hasError={contactPersonLName.hasError && props.submit}
                    isInfo={props.isInfo}
                />
            </View>
            <View style={[{zIndex: 9}, css.formRow]}>
                <MyText style={css.formRowLabel}>
                    Position
                </MyText>
                <MyTextInput
                    style={css.formRowInput}
                    onChangeText={(e) => setContactPersonProfession(prev => ({
                        ...prev,
                        value: e
                    }))}
                    value={contactPersonProfession.value}
                    hasError={contactPersonProfession.hasError && props.submit}
                    isInfo={props.isInfo}
                />
            </View>
            <View style={[{zIndex: 9}, css.formRow]}>
                <MyText style={css.formRowLabel}>
                    Email*
                </MyText>
                <MyTextInput
                    style={css.formRowInput}
                    onChangeText={(e) => setContactPersonEmail(prev => ({
                        ...prev,
                        value: e
                    }))}
                    onChangeValidation={(e) => {
                        setContactPersonEmail(prev => ({
                                ...prev,
                                valid: e
                            }))
                        }
                    }
                    value={contactPersonEmail.value}
                    hasError={contactPersonEmail.hasError && props.submit}
                    inputMode='email'
                    keyboardType='email-address'
                    validation='email'
                    isInfo={props.isInfo}
                    autoCapitalize='none'
                    autoCorrect={false}
                />
            </View>
            <View style={[{zIndex: 9}, css.formRow]}>
                <MyText style={css.formRowLabel}>
                    Phone*
                </MyText>
                <MyTextInput
                    inputMode='decimal'
                    keyboardType='phone-pad'
                    style={css.formRowInput}
                    onChangeText={(e) => setContactPersonPhone(prev => ({
                        ...prev,
                        value: e
                    }))}
                    onChangeValidation={(e) => {
                        setContactPersonPhone(prev => ({
                                ...prev,
                                valid: e
                            }))
                        }
                    }
                    value={contactPersonPhone.value}
                    hasError={contactPersonPhone.hasError && props.submit}
                    validation='phone'
                    isInfo={props.isInfo}
                />
            </View>
        </View>
    </View>
    </>
);
}

const styles = StyleSheet.create({
    contactPersonTitle: {
        color: '#461b6f',
        flex: 1,
        // paddingHorizontal: 15,
    },
});
