import React from 'react';
import {
    StyleSheet,
    View,
    TouchableOpacity,
    Keyboard,
  } from 'react-native'
import { ScrollView } from 'react-native-gesture-handler';
import stylesheet from './Style'
import MyText from './MyText';
import MyTextInput from './MyTextInput';
import MyPicker from './MyPicker';
import axios from 'axios';
import MyTextarea from './MyTextarea';

import AsyncStorage from '@react-native-async-storage/async-storage';
import * as db from './database';

import { useModal } from './hooks/modal';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

const MyProfile = (props) => {
    const css = stylesheet();
    const modal = useModal();

    const scrollRef = React.useRef();

    const [firstName, setFirstName] = React.useState({
        value: '',
        hasError: false
    });
    const [lastName, setLastName] = React.useState({
        value: '',
        hasError: false
    });
    const [phone, setPhone] = React.useState({
        value: '',
        hasError: false,
        valid: false
    });
    const [profession, setProfession] = React.useState({
        value: '',
        hasError: false
    });
    const [suezNetTon, setSuezNetTon] = React.useState({
        value: '',
        hasError: false
    });

    const [comments, setComments] = React.useState('');

    const [submit, setSubmit] = React.useState(false);
    const [submitted, setSubmitted] = React.useState(false);

    const [gotData, setGotData] = React.useState(false);
    
    const FormatDatetime = (dt) => {
        if(!dt)
            return '';
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const padLeft = (num) => {
            if(num < 10)
                return '0'+num;
            return num;
        }
        const date = new Date(dt);
        return padLeft(date.getDate()) + '-' + months[date.getMonth()] + '-' + date.getFullYear() + ' ' + padLeft(date.getHours()) + ':' + padLeft(date.getMinutes());
    }

    React.useEffect(() => {
        console.log(props.user);
        if(props.user){
            setFirstName(prev => ({...prev, value: props.user.firstName??''}));
            setLastName(prev => ({...prev, value: props.user.lastName??''}));
            setPhone(prev => ({...prev, value: props.user.phone??''}));
            setProfession(prev => ({...prev, value: props.user.profession??''}));
            setComments(props.user.comments??'');
        }
    }, [props.user])
    
    React.useEffect(() => {
        setFirstName(val => ({...val, hasError: !val.value}));
        setLastName(val => ({...val, hasError: !val.value}));
        setPhone(val => ({...val, hasError: (!val.value || !val.valid)}));
    },[firstName.value, lastName.value, phone.value])

    const DeleteAccountPrompt = () => {
        modal.confirm.show("Warning", "Are you sure you want to Delete your account? This action is permanent.", async() => {
            axios.post('/api/user/delete_my.php')
            .then(async(res) => {
                modal.confirm.show("Success", "Your profile was deleted successfully!");
                await AsyncStorage.removeItem('token');
                await AsyncStorage.removeItem('user');
                props.setUser(null);
                props.navigation?.push('Landing Page');
                db.logout();
            }).catch((e) =>{
                modal.confirm.show("Warning", "Server error");
            })
        });
    }
    const Submit = () => {
        Keyboard.dismiss();
        setTimeout(() => {
            setSubmit(true); //This makes sure I call submit after all other inputs have been saved
        },100);
    }
    React.useEffect(() => {
        if(submit){
            let hasError = false;
            const mandatory = [firstName, lastName, phone];
            mandatory.forEach(field => {
                if(field.hasError){
                    hasError = true;
                }
            })
            if(hasError){
                scrollRef.current.scrollToPosition(0, 0, false);
            }
            if(hasError){
                modal.confirm.show("Warning", "Please fill in all mandatory fields in red.");
            }
            else{
                let data = {
                    firstName: firstName.value,
                    lastName: lastName.value,
                    phone: phone.value,
                    profession: profession.value,
                    comments: comments
                };
                axios.post('/api/user/update.php', data)
                .then((res) => {
                    console.log(res);
                    modal.confirm.show("Success", "Your profile was updated successfully!");
                    
                    let tempUser = props.user;
                    tempUser.firstName = data.firstName;
                    tempUser.lastName = data.lastName;
                    tempUser.phone = data.phone;
                    tempUser.profession = data.profession;
                    tempUser.comments = data.comments;
                    props.setUser(tempUser);
    
                    setTimeout(() => {
                        if(!props.user.isVGroup){
                            props.navigation.push('My Requests'); 
                        }
                        else{
                            props.navigation.push('Manage Requests'); 
                        }
                    },10)
                }).catch((e) =>{
                    modal.confirm.show("Warning", "Server error");
                })
            }
            setSubmitted(true);
        }
    },[submit])

  return (
    <KeyboardAwareScrollView ref={scrollRef} vertical nestedScrollEnabled={true} keyboardShouldPersistTaps={'handled'} contentContainerStyle={{flexShrink: 0, flexGrow: 1}} scrollEnabled={true}>
    {/* <TouchableWithoutFeedback> */}
        <View style={[css.background, css.formBackground]}>
            <View style={{width: '100%', flexDirection: 'row', alignItems: 'center', marginBottom: 12}}>
                <MyText style={[css.title, css.bold]}>
                    My Profile
                </MyText>
            </View>
            {/* General Information */}
            <View style={[{zIndex: 10}, css.form]}>
                <MyText style={[css.formTitle, css.bold]}>
                    General Information
                </MyText>
                <View
                style={{
                    borderBottomColor: '#f3f1f8',
                    borderBottomWidth: 2,
                }}
                />
                <View style={css.formContainer}>
                    <View style={css.formRow}>
                        <MyText style={css.formRowLabel}>
                        First Name
                        </MyText>
                        <MyTextInput
                            style={css.formRowInput}
                            onChangeText={(e) => {
                                    setFirstName(prev => ({
                                        ...prev,
                                        value: e
                                    }))
                                }
                            }
                            value={firstName.value}
                            hasError={firstName.hasError && submitted}
                            isInfo={props.isInfo}
                        />
                    </View>
                    <View style={css.formRow}>
                        <MyText style={css.formRowLabel}>
                            Last Name
                        </MyText>
                        <MyTextInput
                            style={css.formRowInput}
                            onChangeText={(e) => {
                                    setLastName(prev => ({
                                        ...prev,
                                        value: e
                                    }))
                                }
                            }
                            value={lastName.value}
                            hasError={lastName.hasError && submitted}
                            isInfo={props.isInfo}
                        />
                    </View>
                    <View style={css.formRow}>
                        <MyText style={css.formRowLabel}>
                            Email Address
                        </MyText>
                        <MyTextInput
                            style={css.formRowInput}
                            value={props.user?.email}
                            hasError={false}
                            inputMode='email'
                            keyboardType='email-address'
                            validation='email'
                            autoCapitalize='none'
                            autoCorrect={false}
                            editable={false}
                        />
                    </View>
                    <View style={css.formRow}>
                        <MyText style={css.formRowLabel}>
                            Phone Number
                        </MyText>
                        <MyTextInput
                            style={css.formRowInput}
                            onChangeText={(e) => {
                                    setPhone(prev => ({
                                        ...prev,
                                        value: e
                                    }))
                                }
                            }
                            onChangeValidation={(e) => {
                                    setPhone(prev => ({
                                        ...prev,
                                        valid: e
                                    }))
                                }
                            }
                            value={phone.value}
                            hasError={phone.hasError && submitted}
                            inputMode='decimal'
                            keyboardType='phone-pad'
                            validation='phone'
                            isInfo={props.isInfo}
                        />
                    </View>
                    <View style={css.formRow}>
                        <MyText style={css.formRowLabel}>
                            Position
                        </MyText>
                        <MyTextInput
                            style={css.formRowInput}
                            onChangeText={(e) => {
                                    setProfession(prev => ({
                                        ...prev,
                                        value: e
                                    }))
                                }
                            }
                            value={profession.value}
                            hasError={profession.hasError && submitted}
                            isInfo={props.isInfo}
                        />
                    </View>
                    <View style={css.formRow}>
                        <MyText style={css.formRowLabel}>
                            Suez Canal Net Tonnage
                        </MyText>
                        <MyTextInput
                            inputMode='decimal'
                            keyboardType='phone-pad'
                            style={css.formRowInput}
                            onChangeText={(e) => setSuezNetTon(prev => ({
                                ...prev,
                                value: e
                            }))}
                            value={suezNetTon.value}
                            hasError={suezNetTon.hasError && submit}
                            isInfo={props.isInfo}
                        />
                    </View>
                    <View style={[{zIndex: 10}, css.formRow]}>
                        <MyText style={css.formRowLabel}>
                            User Type
                        </MyText>
                        <MyPicker
                        style={css.formRowInput}
                        items={['Vessel', 'Agent', 'Industrial Company', 'Port', 'Marine', 'Shipyard']}
                        value={props.user?.type}
                        hasError={false}
                        editable={false}
                        >
                        </MyPicker>
                    </View>
                </View>
            </View>
            <View style={[{zIndex: 9}, css.form]}>
                <MyText style={[css.formTitle, css.bold]}>
                    Comments
                </MyText>
                <View
                style={{
                    borderBottomColor: '#f3f1f8',
                    borderBottomWidth: 2,
                }}
                />
                <View style={css.commentsContainer}>
                    <MyTextarea
                        style={[{flexGrow: 1, flexShrink: 0, marginRight: 5}, css.formRowInput]}
                        onChangeText={setComments}
                        value={comments}
                        placeholder="Please write any additional information regarding your Profile"
                        isInfo={props.isInfo}
                    />
                </View>
            </View>
            <View style={{flexDirection: 'row', justifyContent: 'center', marginBottom: 20}}>
                <TouchableOpacity onPress={() => DeleteAccountPrompt()}>
                    <MyText style={[css.button, css.bold, {backgroundColor: '#d7403a'}]}>
                        Delete Account
                    </MyText>
                </TouchableOpacity>
                <TouchableOpacity onPress={() => Submit()} style={{marginLeft: 20}}>
                    <MyText style={[css.button, css.bold]}>
                        Update
                    </MyText>
                </TouchableOpacity>
            </View>
        </View>
    </KeyboardAwareScrollView>
    // <ScrollView vertical nestedScrollEnabled={true} keyboardShouldPersistTaps={'handled'} contentContainerStyle={{flexShrink: 0, flexGrow: 1}} scrollEnabled={true}>
    //     <View style={[css.background]}>
    //         <View style={{marginTop: 15, alignItems: 'center'}}>
    //             <View>
    //                 <MyText style={[css.title, css.bold]}>
    //                     Register
    //                 </MyText>
    //                 <View style={{marginTop: 12}}>
    //                     <MyTextInput
    //                         style={[{flexGrow: 0, flexShrink: 0, flexBasis: 232}, css.formRowInput]}
    //                         onChangeText={setFirstName}
    //                         value={firstName}
    //                         placeholder='First Name'
    //                         fancyPlaceholder={true}
    //                     />
    //                 </View>
    //                 <View style={{marginTop: 12}}>
    //                     <MyTextInput
    //                         style={[{flexGrow: 0, flexShrink: 0, flexBasis: 232}, css.formRowInput]}
    //                         onChangeText={setLastName}
    //                         value={lastName}
    //                         placeholder='Last Name'
    //                         fancyPlaceholder={true}
    //                     />
    //                 </View>
    //                 <View style={{marginTop: 12}}>
    //                     <MyTextInput
    //                         style={[{flexGrow: 0, flexShrink: 0, flexBasis: 232}, css.formRowInput]}
    //                         onChangeText={setEmail}
    //                         value={email}
    //                         inputMode='email'
    //                         keyboardType='email-address'
    //                         autoCapitalize='none'
    //                         autoCorrect={false}
    //                         placeholder='Email'
    //                         fancyPlaceholder={true}
    //                         validation='email'
    //                     />
    //                 </View>
    //                 <View style={{marginTop: 12}}>
    //                     <MyTextInput
    //                         style={[{flexGrow: 0, flexShrink: 0, flexBasis: 232}, css.formRowInput]}
    //                         onChangeText={setPhone}
    //                         value={phone}
    //                         placeholder='Phone Number'
    //                         inputMode='decimal'
    //                         keyboardType='phone-pad'
    //                         fancyPlaceholder={true}
    //                         validation='phone'
    //                     />
    //                 </View>
    //                 <View style={{marginTop: 12}}>
    //                     <MyTextInput
    //                         style={[{flexGrow: 0, flexShrink: 0, flexBasis: 232}, css.formRowInput]}
    //                         onChangeText={setProfession}
    //                         value={profession}
    //                         placeholder='Profession'
    //                         fancyPlaceholder={true}
    //                     />
    //                 </View>
    //                 <View style={{marginTop: 12}}>
    //                     <MyTextInput
    //                         style={[{flexGrow: 0, flexShrink: 0, flexBasis: 232}, css.formRowInput]}
    //                         onChangeText={setPassword}
    //                         value={password}
    //                         secureTextEntry={true}
    //                         placeholder='Password'
    //                         fancyPlaceholder={true}
    //                     />
    //                 </View>
    //                 <View style={{marginTop: 12}}>
    //                     <MyTextInput
    //                         style={[{flexGrow: 0, flexShrink: 0, flexBasis: 232}, css.formRowInput]}
    //                         onChangeText={setVerifyPassword}
    //                         value={verifyPassword}
    //                         secureTextEntry={true}
    //                         placeholder='Verify Password'
    //                         fancyPlaceholder={true}
    //                     />
    //                 </View>
    //                 <View style={{marginTop: 12, flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
    //                     <TouchableOpacity onPress={() => Register()}>
    //                         <MyText style={[css.button, css.bold]}>
    //                             Register
    //                         </MyText>
    //                     </TouchableOpacity>
    //                 </View>
    //                 <View style={{marginTop: 15, alignItems: 'center'}}>
    //                     <TouchableOpacity onPress={() => props.navigation.push('Landing Page')} style={{flexGrow: 0, flexShrink: 0}}>
    //                         <MyText style={[css.bold, {fontSize: 10, color: '#461b6f', textDecorationLine: 'underline'}]}>
    //                             Already a user?
    //                         </MyText>
    //                     </TouchableOpacity>
    //                 </View>
    //             </View>
    //         </View>
    //     </View>
    // </ScrollView>
  );
}

const styles = StyleSheet.create({

});

export default MyProfile
