import { Entypo, EvilIcons, MaterialIcons } from '@expo/vector-icons'
import React, { FunctionComponent } from 'react'
import {
  Text,
  StyleSheet,
  View,
  TouchableOpacity,
  Platform
} from 'react-native'

import stylesheet from './Style'

import { TextInput as RNTextInput } from 'react-native';
import { TextInput as RNGHTextInput } from 'react-native-gesture-handler'
let TextInput;
if(Platform.OS==='web'){
  TextInput = RNTextInput; // On iPad Web the input was not clickable when using RNGHTextInput
}
else{
  TextInput = RNGHTextInput;
}

import MyText from './MyText'

const MyTextInput = (props) => {
  const css = stylesheet();
  
  const inputRef = React.useRef();

  const [value, setValue] = React.useState('');
  
  const [valid, isValid] = React.useState(true);
  const [emailError, setEmailError] = React.useState(false);
  const [phoneError, setPhoneError] = React.useState(false);
  const [imoError, setIMOError] = React.useState(false);
  const [passwordError, setPasswordError] = React.useState(false);
  const [verifyPasswordError, setVerifyPasswordError] = React.useState(false);
  const [websiteError, setWebsiteError] = React.useState(false);
  const [vatError, setVatError] = React.useState(false);
  const [quantityError, setQuantityError] = React.useState(false);
  const [maxError, setMaxError] = React.useState(false);
  const [visibility, setVisibility] = React.useState(!!props.secureTextEntry);

  const [isFocused, setIsFocused] = React.useState(false);

  React.useEffect(() => {
    let tempValid = true;
    
    setEmailError(false);
    setPhoneError(false);
    setIMOError(false);
    setPasswordError(false);
    setVerifyPasswordError(false);
    setWebsiteError(false);
    setVatError(false);
    setQuantityError(false);
    setMaxError(false);

    const val = value;
    if(props.max != undefined){
      setMaxError(false);
      if(parseFloat(val) > props.max){
        tempValid = false;
        setMaxError(true);
      }
    }
    if(tempValid){
      if(props.validation == 'email'){
        let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
        if (reg.test(val) === false && val != '') {
          setEmailError(true);
          tempValid = false;
        }
        else {
          setEmailError(false);
        }
      }
      else if(props.validation == 'phone'){
        setPhoneError(true);
        let reg = /^[\+]?[0-9]{10,15}$/;
        if (reg.test(val) === false && val != '') {
          setPhoneError(true);
          tempValid = false;
        }
        else {
          setPhoneError(false);
        }
      }
      else if(props.validation == 'imo'){
        setIMOError(true);
        let reg = /^[0-9]{7}$/; //is 7 digits
        if (reg.test(val) === false && val != '') {
          setIMOError(true);
          tempValid = false;
        }
        else {
          setIMOError(false);
        }
      }
      else if(props.validation == 'password'){
        setPasswordError(true);
        //https://stackoverflow.com/questions/19605150/regex-for-password-must-contain-at-least-eight-characters-at-least-one-number-a
        let reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/; //Minimum eight characters, at least one uppercase letter, one lowercase letter and one number
        if (reg.test(val) === false && val != '') {
          setPasswordError(true);
          tempValid = false;
        }
        else{
          setPasswordError(false);
        }
      }
      else if(props.validation == 'verifyPassword'){
        setVerifyPasswordError(true);
        if(value != props.password){
          setVerifyPasswordError(true);
          tempValid = false;
        }
        else{
          setVerifyPasswordError(false);
        }
      }
      else if(props.validation == 'website'){
        setWebsiteError(true);
        let reg = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.([-a-zA-Z0-9()@:%_\+.~#?&/=]{2,})/;
        if (reg.test(val) === false && val != '') {
          setWebsiteError(true);
          tempValid = false;
        }
        else {
          setWebsiteError(false);
        }
      }
      else if(props.validation == 'vat'){
        setVatError(true);
        let test = (val.length >=5 && val.length <= 15);
        if (!test && val != '') {
          setVatError(true);
          tempValid = false;
        }
        else {
          setVatError(false);
        }
      }
      else if(props.validation == 'wasteForDelivery'){
        setQuantityError(false);
        if(props.maxStorageCapacity != ''){
          if(parseFloat(val) + parseFloat(props.wasteRetainedOnBoard||0) > parseFloat(props.maxStorageCapacity||0)){
            tempValid = false;
            setQuantityError(true);
          }
        }
      }
      else if(props.validation == 'wasteRetainedOnBoard'){
        setQuantityError(false);
        if(props.maxStorageCapacity != ''){
          if(parseFloat(val) + parseFloat(props.wasteForDelivery||0) > parseFloat(props.maxStorageCapacity||0)){
            tempValid = false;
            setQuantityError(true);
          }
        }
      }
      else if(props.validation == 'estWasteUntilNextPort'){
        setQuantityError(false);
        if(props.maxStorageCapacity != ''){
          if(parseFloat(val) + parseFloat(props.wasteRetainedOnBoard||0) > parseFloat(props.maxStorageCapacity||0)){
            tempValid = false;
            setQuantityError(true);
          }
        }
      }
    }
    isValid(tempValid);
    if(props.onChangeValidation){
      props.onChangeValidation(tempValid);
    }
  },[value, 
      props.maxStorageCapacity, props.wasteRetainedOnBoard, props.wasteForDelivery
    ])
  
  React.useEffect(() => {
    if(props.value != value){
      setValue(props.value);
    }
  },[props.value]);

  const onChangeText = (e) => {
    let tempValid = valid;

    if(props.inputMode === 'decimal'){
      let reg = /^(\d+)[\.\,]?(\d+)?$/;
      if (reg.test(e) === false && e != '') {

      }
      else {
        setValue(e.replace(',', '.'));
      }
    }
    else if(props.inputMode === 'two_decimal'){
      let reg = /^(\d+)[\.\,]?(\d{0,2})?$/;
      if (reg.test(e) === false && e != '') {

      }
      else {
        setValue(e.replace(',', '.'));
      }
    }
    else if(props.inputMode === 'integer'){
      let reg = /^(\d+)?$/;
      if (reg.test(e) === false && e != '') {

      }
      else {
        setValue(e);
      }
    }
    else{
      setValue(e);
    }
  }

  const ToggleVisibility = () => {
    setVisibility(prev => !prev);
  }
  return (
    <View {...props} style={[{borderRadius: 8}, props.style, styles(props, isFocused, value).container]}>
      <View style={{borderTopLeftRadius: 7, borderBottomLeftRadius: 7, overflow: 'hidden'}}>
        {
          !props.isInfo && props.icon == 'date' && 
          <EvilIcons pointerEvents="none" style={styles(props, isFocused, value).icon} name="calendar" size={24} color="white" />
        }
        {
          !props.isInfo && props.icon == 'time' && 
          <Entypo pointerEvents="none" style={styles(props, isFocused, value).icon} name="back-in-time" size={18} color="white" />
        }
        {
          !props.isInfo && props.icon == 'search' && 
          <Entypo pointerEvents="none" style={styles(props, isFocused, value).icon} name="magnifying-glass" size={18} color="white" />
        }
      </View>
      {
        Platform.OS === 'web'?
        <TextInput {...props} 
          ref={(ref) => { inputRef.current = ref; if(props.autofocus) ref && ref.focus(); }}
          onPressOut={() => props.onPress?props.onPress():false}
          onChangeText={(e) => {onChangeText(e); props.onChangeTextImmediate?props.onChangeTextImmediate(e):false}}
          value={value?(value + (props.isInfo&&props.type?(' '+props.type):'')):''}
          // onEndEditing={(e) => console.log('test')/*props.onEndEditing??null*/}
          editable={props.isInfo?false:props.editable}
          secureTextEntry={visibility}
          onFocus={() => setIsFocused(true)}
          onBlur={() => {setIsFocused(false); props.onChangeText?props.onChangeText(value):false}}
          placeholder={props.fancyPlaceholder?'':props.placeholder}
          style={
            [
              props.isInfo?css.sm:css.s,
                {
                  paddingLeft: (props.icon && !props.isInfo)?5:null,
                  fontFamily: 'Comfortaa-Regular',
                  color: props.isInfo?'#461b6f':'#474747',
                  borderWidth: props.isInfo?0:1,
                  padding: props.isInfo?0:7,
                  height: 33,
                  borderColor: (props.hasError||!valid)?'red':'#bdbdbd',
                  borderRadius: 8,
                  overflow: 'hidden',
                  backgroundColor: (props.editable==false&&props.icon!="date"&&props.icon!="time"&&!props.isInfo)?'#e6e6e6':'white',
                  flex: 1,
                  borderBottomLeftRadius: (props.icon)?0:null,
                  borderTopLeftRadius: (props.icon)?0:null,
                  paddingTop: (Platform.OS==='ios'&&!props.isInfo)?9:null,
                },
                Platform.OS==='web'?{
                  outline: 0
                }:{},
                props.style
            ]
          }
        />
        :
        <TextInput {...props} 
          ref={(ref) => { inputRef.current = ref; if(props.autofocus) ref && ref.focus(); }}
          onPressOut={() => props.onPress?props.onPress():false}
          onChangeText={(e) => {onChangeText(e); props.onChangeTextImmediate?props.onChangeTextImmediate(e):false}}
          value={value?(value + (props.isInfo&&props.type?(' '+props.type):'')):''}
          // onEndEditing={(e) => console.log('test')/*props.onEndEditing??null*/}
          editable={props.isInfo?false:props.editable}
          secureTextEntry={visibility}
          onFocus={() => setIsFocused(true)}
          onBlur={() => {setIsFocused(false); props.onChangeText?props.onChangeText(value):false}}
          placeholder={props.fancyPlaceholder?'':props.placeholder}
          style={
            [
              props.isInfo?css.sm:css.s,
                {
                  paddingLeft: (props.icon && !props.isInfo)?5:null,
                  fontFamily: 'Comfortaa-Regular',
                  color: props.isInfo?'#461b6f':'#474747',
                  borderWidth: props.isInfo?0:1,
                  padding: props.isInfo?0:7,
                  height: 33,
                  borderColor: (props.hasError||!valid)?'red':'#bdbdbd',
                  borderRadius: 8,
                  overflow: 'hidden',
                  backgroundColor: (props.editable==false&&props.icon!="date"&&props.icon!="time"&&!props.isInfo)?'#e6e6e6':'white',
                  flex: 1,
                  borderBottomLeftRadius: (props.icon)?0:null,
                  borderTopLeftRadius: (props.icon)?0:null,
                  paddingTop: (Platform.OS==='ios'&&!props.isInfo)?9:null,
                },
                Platform.OS==='web'?{
                  outline: 0
                }:{},
                props.style
            ]
          }
        />
      }
      {
          props.secureTextEntry && 
          <TouchableOpacity onPress={ToggleVisibility} style={styles(props, isFocused, value).iconRightContainer} hitSlop={{top: 20, bottom: 20, left: 20, right: 20}}>
            <MaterialIcons pointerEvents="none" style={styles(props, isFocused, value).iconRight} name={!visibility?"visibility":"visibility-off"} size={18} color="black" />
          </TouchableOpacity>
      }
      {
        props.fancyPlaceholder &&
        <MyText onPress={() => inputRef?.current?.focus()} style={[styles(props, isFocused, value).placeholder, (isFocused||value)?css.xs:css.s]}>
          {props.placeholder}
        </MyText>
      }
      {
        props.isInfo?
        null
        :
        <>
          <MyText style={[css.xxs, styles(props, isFocused, value).error, {display: emailError?'flex':'none'}]}>
            Please enter a valid email address
          </MyText>
          <MyText style={[css.xxs, styles(props, isFocused, value).error, {display: phoneError?'flex':'none'}]}>
            Please enter a valid phone number
          </MyText>
          <MyText style={[css.xxs, styles(props, isFocused, value).error, {display: imoError?'flex':'none'}]}>
            Please enter a valid IMO number
          </MyText>
          <MyText style={[css.xxs, styles(props, isFocused, value).error, {display: verifyPasswordError?'flex':'none'}]}>
            Passwords do not match
          </MyText>
          <MyText style={[css.xxs, styles(props, isFocused, value).error, {display: websiteError?'flex':'none'}]}>
            Please enter a valid website
          </MyText>
          <MyText style={[css.xxs, styles(props, isFocused, value).error, {display: vatError?'flex':'none'}]}>
            Please enter a valid VAT
          </MyText>
          <MyText style={[css.xxs, styles(props, isFocused, value).error, {display: quantityError?'flex':'none'}]}>
            Invalid quantity
          </MyText>
          <MyText style={[css.xxs, styles(props, isFocused, value).error, {display: maxError?'flex':'none'}]}>
            Maximum allowed quantity is {props.max??''}
          </MyText>
        </>
      }
    </View>
  )
}
const styles = (props, isFocused, value) => StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    // justifyContent: 'center',
    // alignItems: 'center',
  },
  icon: {
    // position: 'absolute',
    // left: 0,
    // top: 0,
    backgroundColor: '#b9bab7',
    height: 33,
    lineHeight: 33,
    //Needed for Android, for iOS the parent View is needed
    // borderTopLeftRadius: 7,
    // borderBottomLeftRadius: 7,
    // borderRadius: 7,
    // paddingHorizontal: 2,
    width: 28,
    textAlign: 'center',
    zIndex: 1,
    pointerEvents: 'none',
    // overflow: 'hidden'
  },
  iconRightContainer: {
    position: 'absolute',
    right: 5,
    zIndex: 1
  },
  iconRight: {
    height: 33,
    lineHeight: 33,
    zIndex: 1,
  },
  placeholder: {
    position: 'absolute',
    left: 0,
    top: Platform.OS==='android'?((isFocused||value)?-9:1):((isFocused||value)?-6:4), //for the border maybe(?)
    paddingLeft: (props.icon && !props.isInfo)?5:null,
    padding: props.isInfo?0:7,
    color: '#474747',
    opacity: 0.5,
    pointerEvents: 'none'
  },
  error: {
    position: 'absolute',
    color: 'red',
    top: '100%',
    marginTop: Platform.OS==='android'?-2:2,
    textWrap: 'nowrap'
  }
})
export default MyTextInput