import React from 'react';
import {
    StyleSheet,
    View,
    TouchableOpacity,
    ActivityIndicator,
    Keyboard
  } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { ScrollView } from 'react-native-gesture-handler';
import stylesheet from './Style'
import MyText from './MyText';
import MyTextInput from './MyTextInput';
import MyPicker from './MyPicker';
import axios from 'axios';
import RegisterShipUser from './RegisterShipUser';
import RegisterAgent from './RegisterAgent';
import MyTextarea from './MyTextarea';

import NetInfo from "@react-native-community/netinfo";
import { useModal } from './hooks/modal';

import AsyncStorage from '@react-native-async-storage/async-storage';

const Register = (props) => {
    const css = stylesheet();
    const modal = useModal();
    
    const scrollRef = React.useRef();
    
    const [type, setType] = React.useState('PRF');
    const [password, setPassword] = React.useState({
        value: '',
        hasError: false
    });
    const [verifyPassword, setVerifyPassword] = React.useState({
        value: '',
        hasError: false,
        valid: false
    });
    
    const [status, setStatus] = React.useState('');
    const [registeredOn, setRegisteredOn] = React.useState(null);

    const [firstName, setFirstName] = React.useState({
        value: '',
        hasError: false
    });
    const [lastName, setLastName] = React.useState({
        value: '',
        hasError: false
    });
    const [email, setEmail] = React.useState({
        value: '',
        hasError: false,
        valid: false
    });
    const [emailExists, setEmailExists] = React.useState(false);
    const [phone, setPhone] = React.useState({
        value: '',
        hasError: false,
        valid: false
    });
    const [profession, setProfession] = React.useState({
        value: '',
        hasError: false
    });
    const [userType, setUserType] = React.useState({
        value: '',
        hasError: false
    });
    const [comments, setComments] = React.useState('');

    const [registerShipUser, setRegisterShipUser] = React.useState(null);
    const [initialShip, setInitialShip] = React.useState(null);

    const [registerAgent, setRegisterAgent] = React.useState(null);
    const [initialAgent, setInitialAgent] = React.useState(null);

    const [dataCords0, setDataCords0] = React.useState(0);
    const [dataCords1, setDataCords1] = React.useState(0);

    const [submit, setSubmit] = React.useState(false);
    const [submitBtn, setSubmitBtn] = React.useState(false);

    const [gotData, setGotData] = React.useState(false);
    
    const FormatDatetime = (dt) => {
        if(!dt)
            return '';
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const padLeft = (num) => {
            if(num < 10)
                return '0'+num;
            return num;
        }
        const date = new Date(dt);
        return padLeft(date.getDate()) + '-' + months[date.getMonth()] + '-' + date.getFullYear() + ' ' + padLeft(date.getHours()) + ':' + padLeft(date.getMinutes());
    }

    React.useEffect(() => {
        if(!gotData){
            if(!!!props.id){ //If we are in Register, load form immediatelly
                setGotData(true);
            }
            else{
                axios.get('/api/getUser.php', {
                    params: {
                        id: props.id
                    }
                })
                .then((res) => {               
                    console.log(res.data); 
                    setStatus(res.data.status);
                    setRegisteredOn(res.data.registeredOn??null);
                    setFirstName(prev => ({...prev, value: res.data.firstName??''}));
                    setLastName(prev => ({...prev, value: res.data.lastName??''}));
                    setEmail(prev => ({...prev, value: res.data.email??''}));
                    setPhone(prev => ({...prev, value: res.data.phone??''}));
                    setProfession(prev => ({...prev, value: res.data.profession??''}));
                    setUserType(prev => ({...prev, value: res.data.userType??''}));
                    setComments(res.data.comments??'');

                    if(res.data.userType === 'Vessel' && res.data.registeredShip){
                        setInitialShip(res.data.registeredShip);
                    }
                    else if(res.data.relatedCompany){
                        setInitialAgent(res.data.relatedCompany);
                    }

                    setGotData(true);
                })
            }
        }
    }, [])
    
    React.useEffect(() => {
        setFirstName(val => ({...val, hasError: !val.value}));
        setLastName(val => ({...val, hasError: !val.value}));
        setEmail(val => ({...val, hasError: (!val.value || !val.valid)}));
        setPhone(val => ({...val, hasError: (!val.value || !val.valid)}));
        setUserType(val => ({...val, hasError: !val.value}));
        setPassword(val => ({...val, hasError: (!val.value || !val.valid)}));
        setVerifyPassword(val => ({...val, hasError: (!val.value || (val.value != password.value))}));
    },[firstName.value, lastName.value, email.value, phone.value, userType.value, password.value, verifyPassword.value,
        email.valid, phone.valid, password.valid])

    React.useEffect(() => {
        console.log(props.bottomDisclaimer);
        const present = async() => {
            const accepted = JSON.parse(await AsyncStorage.getItem('disclaimer-consent')??'false');
            if(!accepted){
                props.bottomDisclaimer.current.present();
            }
        }
        if(props.bottomDisclaimer?.current){
            present();
        }
    },[props.bottomDisclaimer]);

    const Submit = () => {
        NetInfo.fetch().then(async(state) => {
            if(!state.isConnected){
                modal.confirm.show('Warning', 'You need to be online to register. Please check your internet connection.');
                return;
            }
            Keyboard.dismiss();
            setTimeout(() => {
                setSubmitBtn(true); //This makes sure I call submit after all other inputs have been saved
            },100);    
        })
    }
    React.useEffect(() => {
        if(submitBtn){
            setSubmitBtn(false);
            
            let hasError = false;
            let hasPasswordError = password.hasError;
            const mandatory = [firstName, lastName, email, phone, userType, password, verifyPassword];
            mandatory.forEach(field => {
                if(field.hasError){
                    hasError = true;
                }
            })
            if(hasError){
                scrollRef.current.scrollToPosition(0, 0, false);
            }
            if(userType.value === 'Vessel'){
                console.log(registerShipUser);
                for (const property in registerShipUser) {
                    if(registerShipUser[property].hasError){
                        hasError = true;
                        break;
                    }
                }
            }
            else{
                for (const property in registerAgent) {
                    if(registerAgent[property].hasError){
                        hasError = true;
                        break;
                    }
                }
            }
            if(hasError && !hasPasswordError){
                modal.confirm.show("Warning", "Please fill in all mandatory fields in red.");
            }
            else if(hasPasswordError){
                modal.confirm.show("Warning", "Password must contain at least 8 characters that include at least 1 lowercase character, 1 uppercase character, and 1 number.");
            }
            else if(password.value != verifyPassword.value){
                console.log(password);
                modal.confirm.show("Warning", "The passwords you entered do not match.");
            }
            else{
                let data = {
                    firstName: firstName.value,
                    lastName: lastName.value,
                    email: email.value,
                    phone: phone.value,
                    profession: profession.value,
                    userType: userType.value,
                    password: password.value,
                    comments
                };
                if(userType.value === 'Vessel'){
                    const shipUser = {
                        imo: registerShipUser.imo.value,
                        shipName: registerShipUser.shipName.value,
                        shipType: registerShipUser.shipType.value,
                        grossTonnage: registerShipUser.grossTonnage.value,
                        grossTonnageType: registerShipUser.grossTonnageType,
                        grt: registerShipUser.grt.value,
                        grtType: registerShipUser.grtType,
                        dwt: registerShipUser.dwt.value,
                        dwtType: registerShipUser.dwtType,
                        countryFlag: registerShipUser.countryFlag.value,
                        registryOffice: registerShipUser.registryOffice.value,
                        scnt: registerShipUser.scnt.value,
                        military: registerShipUser.military,
                        woAis: registerShipUser.woAis,
                        environmentalFee: registerShipUser.environmentalFee,
                        contactPersonFName: registerShipUser.contactPersonFName.value,
                        contactPersonLName: registerShipUser.contactPersonLName.value,
                        contactPersonProfession: registerShipUser.contactPersonProfession.value,
                        contactPersonEmail: registerShipUser.contactPersonEmail.value,
                        contactPersonPhone: registerShipUser.contactPersonPhone.value,
                    }
                    data = {
                        ...data,
                        ...shipUser
                    }
                }
                else{
                    const agent = {
                        companyRegNumber: registerAgent.companyRegNum.value,
                        companyName: registerAgent.companyName.value,
                        address: registerAgent.address.value,
                        area: registerAgent.area.value,
                        countryFlag: registerAgent.countryFlag.value,
                        vatNumber: registerAgent.vatNumber.value,
                        taxOffice: registerAgent.taxOffice.value,
                        vatType: registerAgent.vatType.value,
                        agentEmail: registerAgent.email.value,
                        postalCode: registerAgent.postalCode.value,
                        vatExceptionReason: registerAgent.vatExceptionReason.value,
                        website: registerAgent.website.value,
                        agentPhone: registerAgent.phone.value,
                        contactPersonFName: registerAgent.contactPersonFName.value,
                        contactPersonLName: registerAgent.contactPersonLName.value,
                        contactPersonEmail: registerAgent.contactPersonEmail.value,
                        contactPersonPhone: registerAgent.contactPersonPhone.value,
                        contactPersonProfession: registerAgent.contactPersonProfession.value,
                    }
                    data = {
                        ...data,
                        ...agent
                    }
                }
                axios.post('/api/user/register.php', data)
                .then((res) => {
                    console.log(res);
                    modal.confirm.show("Success", "Thank for registering for FOOTPRINT App. You will be notified shortly regarding your registration form progress.");
                    setEmailExists(false);
                    setTimeout(() => {
                        props.navigation.push('Landing Page');
                        if(Platform.OS==='web'){
                            document.documentElement.scrollTop = 0;
                        }
                    },10)
                }).catch((e) =>{
                    if(e.response.status == 403){
                        modal.confirm.show("Warning", "This email is already registered");
                        setEmailExists(true);
                        scrollRef.current.scrollToPosition(0, 0, false);
                    }
                    else{
                        modal.confirm.show("Warning", "Server error");
                    }
                })
            }
            setSubmit(true);
        }
    },[submitBtn])

    if(!gotData){
        return <>
            <View style={[css.background, css.formBackground]}>
                <View style={{width: '100%', flexDirection: 'row', alignItems: 'center'}}>
                    <MyText style={[css.title, css.bold]}>
                        {props.id?'User Info':'Registration'}
                    </MyText>
                </View>
                <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
                    <ActivityIndicator size="large" color="#461b6f" />
                </View>
            </View>
        </>;
    }
  return (
    <KeyboardAwareScrollView ref={scrollRef} vertical nestedScrollEnabled={true} keyboardShouldPersistTaps={'handled'} contentContainerStyle={{flexShrink: 0, flexGrow: 1}} scrollEnabled={true}>
    {/* <TouchableWithoutFeedback> */}
        <View style={[css.background, css.formBackground]}>
            <View style={{width: '100%', flexDirection: 'row', alignItems: 'center'}}>
                <MyText style={[css.title, css.bold]}>
                    {props.id?'User Info':'Registration'}
                </MyText>
            </View>
            {/* <MyText style={css.subtitle}>
                
            </MyText> */}
            <View style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', marginTop: props.id?5:0, marginBottom: props.id?5:12}}>
                {
                    props.id && (status == 'Approved' || status == 'Rejected')?
                    <View style={styles.filterButton}>
                        <View style={[css.smallButton, {cursor: 'default', backgroundColor: status=='Approved'?'#23a43f':'#e32112', borderColor: status=='Approved'?'#23a43f':'#e32112'}]}>
                            <MyText style={[css.bold, {color: 'white'}]}>
                                {status}
                            </MyText>
                        </View>
                    </View>
                    :null
                }
            </View>
            {/* General Information */}
            <View style={[{zIndex: 10}, css.form]} onLayout={(event) => {setDataCords0(event.nativeEvent.layout.y)}}>
                <MyText style={[css.formTitle, css.bold]}>
                    User Information
                </MyText>
                <View
                style={{
                    borderBottomColor: '#f3f1f8',
                    borderBottomWidth: 2,
                }}
                />
                <View style={css.formContainer}>
                    <View style={css.formRow}>
                        <MyText style={css.formRowLabel}>
                        First Name*
                        </MyText>
                        <MyTextInput
                            style={css.formRowInput}
                            onChangeText={(e) => {
                                    setFirstName(prev => ({
                                        ...prev,
                                        value: e
                                    }))
                                }
                            }
                            value={firstName.value}
                            hasError={firstName.hasError && submit}
                            isInfo={props.isInfo}
                        />
                    </View>
                    <View style={css.formRow}>
                        <MyText style={css.formRowLabel}>
                            Last Name*
                        </MyText>
                        <MyTextInput
                            style={css.formRowInput}
                            onChangeText={(e) => {
                                    setLastName(prev => ({
                                        ...prev,
                                        value: e
                                    }))
                                }
                            }
                            value={lastName.value}
                            hasError={lastName.hasError && submit}
                            isInfo={props.isInfo}
                        />
                    </View>
                    <View style={css.formRow}>
                        <MyText style={css.formRowLabel}>
                            Email Address*
                        </MyText>
                        <MyTextInput
                            style={css.formRowInput}
                            onChangeText={(e) => {
                                    setEmail(prev => ({
                                        ...prev,
                                        value: e
                                    }))
                                    axios.get('/api/user/exists.php', {
                                        params: {
                                            email: e
                                        }
                                    })
                                    .then((res) => {
                                        setEmail(prev => ({
                                            ...prev,
                                            hasError: (!!res.data || !prev.valid)
                                        }))
                                        if(res.data){
                                            modal.confirm.show("Warning", "This email is already registered.");
                                            setEmailExists(true);
                                        }
                                        else{
                                            setEmailExists(false);
                                        }
                                    })
                                }
                            }
                            onChangeValidation={(e) => {
                                console.log(e);
                                    setEmail(prev => ({
                                        ...prev,
                                        valid: e
                                    }))
                                }
                            }
                            value={email.value}
                            hasError={(email.hasError && submit) || emailExists}
                            inputMode='email'
                            keyboardType='email-address'
                            validation='email'
                            autoCapitalize='none'
                            autoCorrect={false}
                            isInfo={props.isInfo}
                        />
                    </View>
                    <View style={css.formRow}>
                        <MyText style={css.formRowLabel}>
                            Phone Number*
                        </MyText>
                        <MyTextInput
                            style={css.formRowInput}
                            onChangeText={(e) => {
                                    setPhone(prev => ({
                                        ...prev,
                                        value: e
                                    }))
                                }
                            }
                            onChangeValidation={(e) => {
                                console.log(e);
                                    setPhone(prev => ({
                                        ...prev,
                                        valid: e
                                    }))
                                }
                            }
                            value={phone.value}
                            hasError={phone.hasError && submit}
                            inputMode='decimal'
                            keyboardType='phone-pad'
                            validation='phone'
                            isInfo={props.isInfo}
                        />
                    </View>
                    {
                        !props.isInfo?
                        <>
                            <View style={css.formRow}>
                                <MyText style={css.formRowLabel}>
                                    Password*
                                </MyText>
                                <MyTextInput
                                    style={css.formRowInput}
                                    onChangeText={(e) => {
                                            setPassword(prev => ({
                                                ...prev,
                                                value: e
                                            }))
                                        }
                                    }
                                    onChangeValidation={(e) => {
                                        console.log(e);
                                            setPassword(prev => ({
                                                ...prev,
                                                valid: e
                                            }))
                                        }
                                    }
                                    value={password.value}
                                    hasError={password.hasError && submit}
                                    secureTextEntry={true}
                                    autoCapitalize='none'
                                    autoCorrect={false}
                                    isInfo={props.isInfo}
                                    validation='password'
                                />
                            </View>
                            <View style={css.formRow}>
                                <MyText style={css.formRowLabel}>
                                    Verify Password*
                                </MyText>
                                <MyTextInput
                                    style={css.formRowInput}
                                    onChangeText={(e) => {
                                            setVerifyPassword(prev => ({
                                                ...prev,
                                                value: e
                                            }))
                                        }
                                    }
                                    value={verifyPassword.value}
                                    hasError={verifyPassword.hasError && submit}
                                    secureTextEntry={true}
                                    autoCapitalize='none'
                                    autoCorrect={false}
                                    validation='verifyPassword'
                                    password={password.value}
                                    isInfo={props.isInfo}
                                />
                            </View>
                        </>
                        :null
                    }
                    <View style={css.formRow}>
                        <MyText style={css.formRowLabel}>
                            Position
                        </MyText>
                        <MyTextInput
                            style={css.formRowInput}
                            onChangeText={(e) => {
                                    setProfession(prev => ({
                                        ...prev,
                                        value: e
                                    }))
                                }
                            }
                            value={profession.value}
                            hasError={profession.hasError && submit}
                            isInfo={props.isInfo}
                        />
                    </View>
                    <View style={[{zIndex: 10}, css.formRow]}>
                        <MyText style={css.formRowLabel}>
                            User Type*
                        </MyText>
                        <MyPicker
                        style={css.formRowInput}
                        items={['Vessel', 'Agent', 'Industrial Company', 'Port', 'Marine', 'Shipyard']}
                        // items={shipTypes}
                        onValueChange={(item, itemIndex) => setUserType(prev => ({
                            ...prev,
                            value: item.value
                        }))}
                        value={userType.value}
                        hasError={userType.hasError && submit}
                        isInfo={props.isInfo}
                        >
                        </MyPicker>
                    </View>
                </View>
            </View>
            <View onLayout={(event) => {setDataCords1(event.nativeEvent.layout.y)}}>
                {
                    userType?.value === 'Vessel' &&
                    <RegisterShipUser scrollRef={scrollRef} initialShip={initialShip} submit={submit} setRegisterShipUser={setRegisterShipUser} isInfo={props.isInfo}/>
                }
            </View>
            <View onLayout={(event) => {setDataCords1(event.nativeEvent.layout.y)}}>
                {
                    userType?.value === 'Agent' &&
                    <RegisterAgent title='Agent Information' initialAgent={initialAgent} scrollRef={scrollRef} submit={submit} setRegisterAgent={setRegisterAgent} isInfo={props.isInfo}/>
                }
            </View>
            <View onLayout={(event) => {setDataCords1(event.nativeEvent.layout.y)}}>
                {
                    (userType?.value === 'Industrial Company' || userType?.value === 'Port' || userType?.value === 'Marine' || userType?.value === 'Shipyard') &&
                    <RegisterAgent title='Company Information' initialAgent={initialAgent} scrollRef={scrollRef} submit={submit} setRegisterAgent={setRegisterAgent} isInfo={props.isInfo}/>
                }
            </View>
            {/* Comments */}
            <View style={[{zIndex: 9}, css.form]}>
                <MyText style={[css.formTitle, css.bold]}>
                    Comments
                </MyText>
                <View
                style={{
                    borderBottomColor: '#f3f1f8',
                    borderBottomWidth: 2,
                }}
                />
                <View style={css.commentsContainer}>
                    <MyTextarea
                        style={[{flexGrow: 1, flexShrink: 0, marginRight: 5}, css.formRowInput]}
                        // onChangeTextImmediate={setComments}
                        // onChangeText={() => false}
                        onChangeText={setComments}
                        value={comments}
                        placeholder="Please write any additional information regarding your request for Registration"
                        isInfo={props.isInfo}
                    />
                </View>
            </View>
            {
                !props.id?
                <TouchableOpacity onPress={() => Submit()} style={{alignItems: 'center', marginBottom: 20}}>
                    <MyText style={[css.button, css.bold]}>
                        Register
                    </MyText>
                </TouchableOpacity>
                :
                <View style={[{zIndex: 8}]}>
                    <View style={[css.formRow, {paddingBottom: 25, paddingHorizontal: 0}]}>
                        <MyText style={[css.bold]}>
                            Registration Submitted on
                        </MyText>
                        <MyText style={{marginLeft: 20}}>
                            {FormatDatetime(registeredOn)}
                        </MyText>
                    </View>
                </View>
            }
        </View>
    </KeyboardAwareScrollView>
    // <ScrollView vertical nestedScrollEnabled={true} keyboardShouldPersistTaps={'handled'} contentContainerStyle={{flexShrink: 0, flexGrow: 1}} scrollEnabled={true}>
    //     <View style={[css.background]}>
    //         <View style={{marginTop: 15, alignItems: 'center'}}>
    //             <View>
    //                 <MyText style={[css.title, css.bold]}>
    //                     Register
    //                 </MyText>
    //                 <View style={{marginTop: 12}}>
    //                     <MyTextInput
    //                         style={[{flexGrow: 0, flexShrink: 0, flexBasis: 232}, css.formRowInput]}
    //                         onChangeText={setFirstName}
    //                         value={firstName}
    //                         placeholder='First Name'
    //                         fancyPlaceholder={true}
    //                     />
    //                 </View>
    //                 <View style={{marginTop: 12}}>
    //                     <MyTextInput
    //                         style={[{flexGrow: 0, flexShrink: 0, flexBasis: 232}, css.formRowInput]}
    //                         onChangeText={setLastName}
    //                         value={lastName}
    //                         placeholder='Last Name'
    //                         fancyPlaceholder={true}
    //                     />
    //                 </View>
    //                 <View style={{marginTop: 12}}>
    //                     <MyTextInput
    //                         style={[{flexGrow: 0, flexShrink: 0, flexBasis: 232}, css.formRowInput]}
    //                         onChangeText={setEmail}
    //                         value={email}
    //                         inputMode='email'
    //                         keyboardType='email-address'
    //                         autoCapitalize='none'
    //                         autoCorrect={false}
    //                         placeholder='Email'
    //                         fancyPlaceholder={true}
    //                         validation='email'
    //                     />
    //                 </View>
    //                 <View style={{marginTop: 12}}>
    //                     <MyTextInput
    //                         style={[{flexGrow: 0, flexShrink: 0, flexBasis: 232}, css.formRowInput]}
    //                         onChangeText={setPhone}
    //                         value={phone}
    //                         placeholder='Phone Number'
    //                         inputMode='decimal'
    //                         keyboardType='phone-pad'
    //                         fancyPlaceholder={true}
    //                         validation='phone'
    //                     />
    //                 </View>
    //                 <View style={{marginTop: 12}}>
    //                     <MyTextInput
    //                         style={[{flexGrow: 0, flexShrink: 0, flexBasis: 232}, css.formRowInput]}
    //                         onChangeText={setProfession}
    //                         value={profession}
    //                         placeholder='Profession'
    //                         fancyPlaceholder={true}
    //                     />
    //                 </View>
    //                 <View style={{marginTop: 12}}>
    //                     <MyTextInput
    //                         style={[{flexGrow: 0, flexShrink: 0, flexBasis: 232}, css.formRowInput]}
    //                         onChangeText={setPassword}
    //                         value={password}
    //                         secureTextEntry={true}
    //                         placeholder='Password'
    //                         fancyPlaceholder={true}
    //                     />
    //                 </View>
    //                 <View style={{marginTop: 12}}>
    //                     <MyTextInput
    //                         style={[{flexGrow: 0, flexShrink: 0, flexBasis: 232}, css.formRowInput]}
    //                         onChangeText={setVerifyPassword}
    //                         value={verifyPassword}
    //                         secureTextEntry={true}
    //                         placeholder='Verify Password'
    //                         fancyPlaceholder={true}
    //                     />
    //                 </View>
    //                 <View style={{marginTop: 12, flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
    //                     <TouchableOpacity onPress={() => Register()}>
    //                         <MyText style={[css.button, css.bold]}>
    //                             Register
    //                         </MyText>
    //                     </TouchableOpacity>
    //                 </View>
    //                 <View style={{marginTop: 15, alignItems: 'center'}}>
    //                     <TouchableOpacity onPress={() => props.navigation.push('Landing Page')} style={{flexGrow: 0, flexShrink: 0}}>
    //                         <MyText style={[css.bold, {fontSize: 10, color: '#461b6f', textDecorationLine: 'underline'}]}>
    //                             Already a user?
    //                         </MyText>
    //                     </TouchableOpacity>
    //                 </View>
    //             </View>
    //         </View>
    //     </View>
    // </ScrollView>
  );
}

const styles = StyleSheet.create({
    filterButton: {
        marginRight: 10,
        marginBottom: 10,
        flexBasis: 125,
        flexGrow: 0,
        flexShrink: 0
    },
});

export default Register
