import React, { FunctionComponent } from 'react'
import { StyleSheet, Text, View, TextInput, TouchableOpacity, Platform } from 'react-native';
import Collapsible from 'react-native-collapsible';
import MyCheckbox from '../MyCheckbox';
import MyDatePicker from '../MyDatePicker';
import MyDateTimePicker from '../MyDatetimePicker';
import MyPicker from '../MyPicker';
import MyText from '../MyText';
import MyTextarea from '../MyTextarea';
import MyTextInput from '../MyTextInput';
import stylesheet from '../Style'

const RequestedCollectionMeansSalesItemForm = (props) => {
    const css = stylesheet();
    
    const [salesItem, setSalesItem] = React.useState(props.value?.salesItem??'');
    const [quantity, setQuantity] = React.useState(props.value?.quantity??'');
    const [active, setActive] = React.useState(props.value?.active??true);
    
    const toIsoString = (date) => {
        if(!date)
            return null;
        if(!date.getTimezoneOffset){
            date = new Date(date);
        }
        var tzo = -date.getTimezoneOffset(),
            dif = tzo >= 0 ? '+' : '-',
            pad = function(num) {
                return (num < 10 ? '0' : '') + num;
            };
      
        return date.getFullYear() +
            '-' + pad(date.getMonth() + 1) +
            '-' + pad(date.getDate()) +
            'T' + pad(date.getHours()) +
            ':' + pad(date.getMinutes()) +
            ':' + pad(date.getSeconds()) +
            dif + pad(Math.floor(Math.abs(tzo) / 60)) +
            ':' + pad(Math.abs(tzo) % 60);
      }

    React.useEffect(() => {
        props.onChange({
            salesItem,
            quantity,
            active
        });
    }, [salesItem, quantity, active]);

    const Delete = () => {
        setActive(false);
    }

    return (
        <>
        {active &&
            <View style={[css.formContainer, styles.container, {paddingTop: 24}]}>
                <TouchableOpacity onPress={() => Delete()} style={[{padding: 5, position: 'absolute', right: 4, top: Platform.OS==='android'?-8:-4}]}>
                    <MyText style={[css.ml, {color: '#461b6f'}]}>x</MyText>
                </TouchableOpacity>
                <View style={[{zIndex: 10}, css.formRowFull]}>
                    <MyText style={css.formRowLabelNarrow}>
                        Sales Item
                    </MyText>
                    <MyPicker
                        style={css.formRowInput}
                        items={['Big Bag Large', 'Big Bag Small', 'Detergent']}
                        value={props?.value?.salesItem}
                        onValueChange={(item, itemIndex) =>
                            setSalesItem(item.value)
                        }
                        isInfo={props.isInfo}
                        >
                    </MyPicker>
                </View>
                <View style={[{zIndex: 9}, css.formRowFull]}>
                    <MyText style={css.formRowLabelNarrow}>
                        Quantity
                    </MyText>
                    <MyTextInput
                        inputMode='two_decimal'
                        keyboardType='phone-pad'
                        style={[css.formRowInput]}
                        onChangeText={(e) => setQuantity(e)}
                        value={quantity}
                        isInfo={props.isInfo}
                    />
                </View>
            </View>
        }
        </>
    )
}
const styles = StyleSheet.create({
    container: {
        paddingVertical: 10,
        paddingHorizontal: 14,
        // paddingBottom: 0,
        borderColor: '#532b75',
        borderWidth: 0.5,
        borderRadius: 20,
        marginBottom: 15,
        overflow: 'hidden',
    }
})
export default RequestedCollectionMeansSalesItemForm