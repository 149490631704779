import { registerRootComponent } from 'expo';

import App from './App';

import { AutocompleteDropdownContextProvider } from 'react-native-autocomplete-dropdown'
import { GestureHandlerRootView } from 'react-native-gesture-handler';

const Root = () => {
    return (
        <GestureHandlerRootView style={{ flex: 1 }}>
            <AutocompleteDropdownContextProvider>
                <App/ >
            </AutocompleteDropdownContextProvider>
        </GestureHandlerRootView>
    )
}
// registerRootComponent calls AppRegistry.registerComponent('main', () => App);
// It also ensures that whether you load the app in Expo Go or in a native build,
// the environment is set up appropriately
registerRootComponent(Root);
