import React, { FunctionComponent } from 'react'
import { StyleSheet, Text, View, TextInput, TouchableOpacity } from 'react-native';
import Collapsible from 'react-native-collapsible';
import MyCheckbox from '../MyCheckbox';
import MyPicker from '../MyPicker';
import MyText from '../MyText';
import MyTextarea from '../MyTextarea';
import MyTextInput from '../MyTextInput';
import stylesheet from '../Style'
import RequestedCollectionMeansForm from './RequestedCollectionMeansForm';
import { AntDesign } from '@expo/vector-icons';
import RequestedCollectionMeansAssetForm from './RequestedCollectionMeansAssetForm';
import RequestedCollectionMeansSalesItemForm from './RequestedCollectionMeansSalesItemForm';
import RequestedCollectionMeansServiceForm from './RequestedCollectionMeansServiceForm';

const RequestedCollectionMeans = (props) => {
    const css = stylesheet();

    const HasAssets = () => {
        let found = false;
        props.value.asset?.forEach(row => {
            if(row.active){
                if(row.asset){
                    found = true;
                }
            }
        })
        return found;
    }

    const HasSalesItems = () => {
        let found = false;
        props.value.salesItem?.forEach(row => {
            if(row.active){
                if(row.asset){
                    found = true;
                }
            }
        })
        return found;
    }

    const HasServices = () => {
        let found = false;
        props.value.services?.forEach(row => {
            if(row.active){
                if(row.service){
                    found = true;
                }
            }
        })
        return found;
    }
    
    const HasDefaultValue = () => {
        return HasAssets() || HasSalesItems() || HasServices();
        // if(props.value.length == 0){
        //     return false;
        // }
        // let found = false;
        // props.value.forEach(row => {
        //     if(row.active){
        //         if(row.collectionMean || row.purpose || row.from || row.to){
        //             found = true;
        //         }
        //     }
        // });
        // return found;
    }
    const [collapsed, setCollapsed] = React.useState(!HasDefaultValue());
    const [assetCollapsed, setAssetCollapsed] = React.useState(false);
    const [salesItemCollapsed, setSalesItemCollapsed] = React.useState(!HasSalesItems());
    const [serviceCollapsed, setServiceCollapsed] = React.useState(!HasServices());
    const [requestedCollectionMeans, setRequestedCollectionMeans] = React.useState(HasDefaultValue()?props.value:(props.isInfo?{}:{asset: [{}], salesItem: [{}], services: [{}]}));//props?.value??[{}]);
    
    React.useEffect(() => {
        onChange();
    }, [requestedCollectionMeans]);

    const onChange = () => {
        props.onChange(requestedCollectionMeans);
    }

    return (
        <View style={styles.reqContainer}>
            <TouchableOpacity activeOpacity={0.7} onPress={() => {setCollapsed(prev => !prev)}}>
                <MyText style={[styles.reqTitle, css.m]}>Additional Requests</MyText>
                <View style={{position: 'absolute', right: 10, top: 14}}>
                    <AntDesign name="down" size={12} color="#461b6f" style={{transform: [{rotate: collapsed?'0deg':'180deg'}]}}/>
                </View>
            </TouchableOpacity>
            { 
                !collapsed &&
                <View style={{display: collapsed?'none':'flex'}}>
                    <View style={[{zIndex: 9, flexDirection: 'row', alignItems: 'flex-start'}]}>
                        <View style={css.columnFormContainer}>
                            <TouchableOpacity activeOpacity={0.7} onPress={() => {setAssetCollapsed(prev => !prev)}}>
                                <MyText style={[styles.reqTitle, css.m]}>Assets</MyText>
                                <View style={{position: 'absolute', right: 10, top: 14}}>
                                    <AntDesign name="down" size={12} color="#461b6f" style={{transform: [{rotate: assetCollapsed?'0deg':'180deg'}]}}/>
                                </View>
                            </TouchableOpacity>
                            {
                                !assetCollapsed &&
                                <View style={css.columnFormContainer}>
                                    {
                                        requestedCollectionMeans.asset?.map((mean, i) => (
                                            <RequestedCollectionMeansAssetForm onChange={(e) => {
                                                const newAr = requestedCollectionMeans.asset.slice(); //copy array
                                                newAr[i] = e;
                                                let hasActive = false;
                                                newAr.forEach(re => {
                                                    if(re.active){
                                                        hasActive = true;
                                                    }
                                                })
                                                if(!hasActive){ //If we deleted everything, add a new one
                                                    newAr.push({});
                                                }
                                                setRequestedCollectionMeans({
                                                    ...requestedCollectionMeans,
                                                    asset: newAr
                                                });
                                            }} value={mean} arrival={props.arrival} departure={props.departure} collectionMeans={props.collectionMeans} purposes={props.purposes} key={i} id={i} isInfo={props.isInfo}/>
                                        ))
                                    }
                                    {
                                        !props.isInfo &&
                                        <TouchableOpacity onPress={() => setRequestedCollectionMeans(current => {
                                                return {
                                                    ...current,
                                                    asset: [...(current.asset ?? []), {}]
                                                }
                                            })} style={{alignItems: 'flex-start', marginBottom: 0}}>
                                            <MyText style={[{textDecorationLine: 'underline', color: '#461b6f'}, css.bold]}>
                                                + Add more Assets
                                            </MyText>
                                        </TouchableOpacity>
                                    }
                                </View>
                            }
                        </View>
                        <View style={css.columnFormContainer}>
                            <TouchableOpacity activeOpacity={0.7} onPress={() => {setSalesItemCollapsed(prev => !prev)}}>
                                <MyText style={[styles.reqTitle, css.m]}>Sales Items</MyText>
                                <View style={{position: 'absolute', right: 10, top: 14}}>
                                    <AntDesign name="down" size={12} color="#461b6f" style={{transform: [{rotate: salesItemCollapsed?'0deg':'180deg'}]}}/>
                                </View>
                            </TouchableOpacity>
                            {
                                !salesItemCollapsed &&
                                <View style={css.columnFormContainer}>
                                {
                                        requestedCollectionMeans.salesItem?.map((mean, i) => (
                                            <RequestedCollectionMeansSalesItemForm onChange={(e) => {
                                                const newAr = requestedCollectionMeans.salesItem.slice(); //copy array
                                                newAr[i] = e;
                                                let hasActive = false;
                                                newAr.forEach(re => {
                                                    if(re.active){
                                                        hasActive = true;
                                                    }
                                                })
                                                if(!hasActive){ //If we deleted everything, add a new one
                                                    newAr.push({});
                                                }
                                                setRequestedCollectionMeans({
                                                    ...requestedCollectionMeans,
                                                    salesItem: newAr
                                                });
                                            }} value={mean} arrival={props.arrival} departure={props.departure} collectionMeans={props.collectionMeans} purposes={props.purposes} key={i} id={i} isInfo={props.isInfo}/>
                                        ))
                                    }
                                    {
                                        !props.isInfo &&
                                        <TouchableOpacity onPress={() => setRequestedCollectionMeans(current => {
                                            return {
                                                ...current,
                                                salesItem: [...(current.salesItem ?? []), {}]
                                            }
                                            })} style={{alignItems: 'flex-start', marginBottom: 0}}>
                                            <MyText style={[{textDecorationLine: 'underline', color: '#461b6f'}, css.bold]}>
                                                + Add more Sales Items
                                            </MyText>
                                        </TouchableOpacity>
                                    }
                                </View>
                            }
                        </View>
                        <View style={css.columnFormContainer}>
                            <TouchableOpacity activeOpacity={0.7} onPress={() => {setServiceCollapsed(prev => !prev)}}>
                                <MyText style={[styles.reqTitle, css.m]}>Services</MyText>
                                <View style={{position: 'absolute', right: 10, top: 14}}>
                                    <AntDesign name="down" size={12} color="#461b6f" style={{transform: [{rotate: serviceCollapsed?'0deg':'180deg'}]}}/>
                                </View>
                            </TouchableOpacity>
                            {
                                !serviceCollapsed &&
                                <View style={css.columnFormContainer}>
                                    {
                                        requestedCollectionMeans.services?.map((mean, i) => (
                                            <RequestedCollectionMeansServiceForm onChange={(e) => {
                                                const newAr = requestedCollectionMeans.services.slice(); //copy array
                                                newAr[i] = e;
                                                let hasActive = false;
                                                newAr.forEach(re => {
                                                    if(re.active){
                                                        hasActive = true;
                                                    }
                                                })
                                                if(!hasActive){ //If we deleted everything, add a new one
                                                    newAr.push({});
                                                }
                                                setRequestedCollectionMeans({
                                                    ...requestedCollectionMeans,
                                                    services: newAr
                                                });
                                            }} value={mean} arrival={props.arrival} departure={props.departure} collectionMeans={props.collectionMeans} purposes={props.purposes} key={i} id={i} isInfo={props.isInfo}/>
                                        ))
                                    }
                                    {
                                        !props.isInfo &&
                                        <TouchableOpacity onPress={() => setRequestedCollectionMeans(current => {
                                            return {
                                                ...current,
                                                services: [...(current.services ?? []), {}]
                                            }
                                            })} style={{alignItems: 'flex-start', marginBottom: 0}}>
                                            <MyText style={[{textDecorationLine: 'underline', color: '#461b6f'}, css.bold]}>
                                                + Add more Services
                                            </MyText>
                                        </TouchableOpacity>
                                    }
                                </View>
                            }
                        </View>
                    </View>
                </View>
            }
        </View>
    )
}
const styles = StyleSheet.create({
    container: {
        paddingVertical: 10,
        paddingHorizontal: 14,
        paddingBottom: 0,
        borderColor: '#532b75',
        borderWidth: 0.5,
        borderRadius: 20,
        marginBottom: 15,
        overflow: 'hidden',
    },
    reqContainer: {
        marginBottom: 0,
        backgroundColor: 'white'
    },
    reqTitle: {
        backgroundColor: '#e9e3f0',
        color: '#57307e',
        textAlign: 'center',
        width: '100%',
        padding: 10
    },
})
export default RequestedCollectionMeans