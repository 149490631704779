import React, { useState } from 'react';
import {
    StyleSheet,
    View,
    TouchableOpacity,
    ScrollView,
    TouchableWithoutFeedback,
    Linking,
    Platform,
    useWindowDimensions,
    RefreshControl
  } from 'react-native'
import stylesheet from '../Style'
import MyText from '../MyText';
import MyDatePicker from '../MyDatePicker';
import MyTextInput from '../MyTextInput';
import { MaterialIcons, Entypo, Feather, AntDesign } from '@expo/vector-icons'; 

import axios from 'axios';
import MyPicker from '../MyPicker';

// const RNFS = require('react-native-fs');
import * as XLSX from 'xlsx'
// import RNFetchBlob from 'rn-fetch-blob';

import * as FileSystem from 'expo-file-system';

import * as db from '../database';

import MyReasonModal from '../MyReasonModal';

import AsyncStorage from '@react-native-async-storage/async-storage';
import { useModal } from '../hooks/modal';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import MyCheckbox from '../MyCheckbox';

let filteredData = [];
let selectedRows = [];

const tableHeader = ['ID', 'Validation', 'Ship Name', 'Datetime \nSubmitted', 'Datetime \nRequested Delivery', 'Port', 'Location', 'IMO', 'Status', 'Scheduled \nCollection', 'Datetime \nCollected', 'Military', 'w/o AIS', 'New', 'Actions'];
// const tableData = [
//     ['123456', 'Blue Star Delos',       '31-Jan-2023 11:00', '31-Jan-2023 11:00', 'Κ.Λ. Περαία', 'XXXXXXX', 'XXXXXXX', 'Submitted', '31-Jan-2023 11:00', ''],
//     ['213456', 'Blue Star Paros',       '31-Jan-2023 11:00', '31-Jan-2023 11:00', 'Κ.Λ. Περαία', 'XXXXXXX', 'XXXXXXX', 'Validated', '31-Jan-2023 11:00', ''],
//     ['312456', 'Blue Star Mykonos',     '31-Jan-2023 11:00', '31-Jan-2023 11:00', 'Κ.Λ. Περαία', 'XXXXXXX', 'XXXXXXX', 'Route Scheduled 7-Jan-2022 14:00', '31-Jan-2023 11:00', ''],
//     ['312456', 'Blue Star Mykonos',     '31-Jan-2023 11:00', '31-Jan-2023 11:00', 'Κ.Λ. Περαία', 'XXXXXXX', 'XXXXXXX', 'Driver Arrived', '31-Jan-2023 11:00', ''],
//     ['412356', 'Aida Blue',             '31-Jan-2023 11:00', '31-Jan-2023 11:00', 'Κ.Λ. Περαία', 'XXXXXXX', 'XXXXXXX', 'Waste Collected', '31-Jan-2023 11:00', ''],
//     ['512356', 'Aida Blue',             '31-Jan-2023 11:00', '31-Jan-2023 11:00', 'Κ.Λ. Περαία', 'XXXXXXX', 'XXXXXXX', 'Waste Segregated', '31-Jan-2023 11:00', ''],
//     ['612345', 'Aida Blue',             '31-Jan-2023 11:00', '31-Jan-2023 11:00', 'Κ.Λ. Περαία', 'XXXXXXX', 'XXXXXXX', 'Certificate Issued', '31-Jan-2023 11:00', ''],
//     ['612345', 'Aida Blue',             '31-Jan-2023 11:00', '31-Jan-2023 11:00', 'Κ.Λ. Περαία', 'XXXXXXX', 'XXXXXXX', 'Cancelled', '31-Jan-2023 11:00', ''],
// ];

const ManageRequests = (props) => {
    const css = stylesheet();
    const modal = useModal();
    
    const {height, width} = useWindowDimensions();
    const sizes = width>=768?
    [35, 45, 70, 100, 100, 135, 150, 90, 95, 110, 150, 135, 135, 80, 60, 60, 100]:
    [35, 35, 60, 90, 90, 125, 140, 80, 85, 100, 140, 125, 125, 70, 50, 50, 90];

    const pageRef = React.useRef();
    const tableRef = React.useRef();

    const [page, setPage] = useState('1');
    const [pageLength, setPageLength] = useState('5');

    const [modalOpen, setModalOpen] = useState(false);
    const [modalCallback, setModalCallback] = useState(null);

    const [from, setFrom] = useState(null);
    const [to, setTo] = useState(null);
    const [fromKey, setFromKey] = useState(1000);
    const [toKey, setToKey] = useState(2000);

    const [type, setType] = useState('all');
    const [searchText, setSearchText] = useState('');
    const [sort, setSort] = useState([{column: 2, ascending: true}, {column: 4, ascending: false}]); //# column does not count, 0 is the ID

    const [tableData, setTableData] = useState([]);
    const [data, setData] = useState(tableData);

    const [initialLoadDone, setInitialLoadDone] = useState(false);

    const [ships, setShips] = useState(null);
    const [imo, setImo] = useState(null);
    const [shipName, setShipName] = useState(null);
    
    const [countries, setCountries] = useState(null);
    const [portOfDeliveries, setPortOfDeliveries] = useState(null);
    const [locations, setLocations] = useState(null);
    const [agents, setAgents] = useState(null);
    const [shipTypes, setShipTypes] = useState(null);
    const [agentPerPort, setAgentPerPort] = useState(null);

    const [country, setCountry] = useState({value: ''});
    const [portOfDelivery, setPortOfDelivery] = useState({value: ''});
    const [location, setLocation] = useState({value: ''});
    const [agent, setAgent] = useState(null);

    const [refreshing, setRefreshing] = useState(true);

    const [selected, setSelected] = useState([]);

    const onRefresh = React.useCallback(() => {
        setRefreshing(true);
        GetFilters();
        GetTableData();
    }, []);

    // function to handle exporting
  const exportDataToExcel = async () => {
    console.log('export');
    let wb = XLSX.utils.book_new();

    // Format datetime columns
    let formattedTableData = tableData.map(row => {
        if(row[4])
            row[4] = new Date(row[4]);
        if(row[5])
            row[5] = new Date(row[5]);
        if(row[10])
            row[10] = new Date(row[10]);
        if(row[11])
            row[11] = new Date(row[11]);
        return row;
    })
    let ws = XLSX.utils.aoa_to_sheet([tableHeader, ...formattedTableData], { cellDates: true });
    ws['!cols'] = sizes.map(size => { return {wpx: size}});

    XLSX.utils.book_append_sheet(wb,ws,"Requests")
    const wbout = XLSX.write(wb, {type:'base64', bookType:"xlsx"});

    const filename = "Requests_"+FormatDatetime(new Date()).replace(":", "-").replace(" ", "_")+".xlsx";
    if(Platform.OS === 'android' || Platform.OS === 'ios'){
        const dir = FileSystem.documentDirectory+'Documents/'+props.user.id;
        const fileUri = dir+'/'+filename;
        const dirInfo = await FileSystem.getInfoAsync(dir);
        if (!dirInfo.exists) {
            console.log("Documents directory doesn't exist, creating...");
            await FileSystem.makeDirectoryAsync(dir, { intermediates: true });
        }
        await FileSystem.writeAsStringAsync(fileUri, wbout, { encoding: FileSystem.EncodingType.Base64 });
    }
    else if(Platform.OS === 'web'){
        await db.saveDocument(props.user.id, filename, wbout);        
        var a = document.createElement("a");
        a.href = "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," + wbout;
        a.download = filename;
        a.click();
    }
    const body = "Exporting completed successfully with filename: "+filename+".";
    modal.confirm.show("Success", body);
  }

    const GetFilters = async() => {
        try{
            await db.syncFilters();
            setShips(await db.getAll('datalastic', 'shipName'));
            setCountries(await db.getAll('countries'));
            setPortOfDeliveries(await db.getAll('ports'));
            setLocations(await db.getAll('locations'));
            setShipTypes(await db.getAll('ship_types'));

            const agents = await db.getAll('agents', 'value');
            setAgents(agents);

            let tmpAgentPerPort = {};
            const agent_ports = await db.getAll('agent_port');
            agent_ports.forEach(agent_port => {
                const agentName = agents.find(agent => agent.key == agent_port.agent);
                if (!agentName)
                    return;
                if (tmpAgentPerPort[agent_port.port]) {
                    tmpAgentPerPort[agent_port.port].push(agentName);
                }
                else {
                    tmpAgentPerPort[agent_port.port] = [agentName];
                }
            })
            for (const port in tmpAgentPerPort) {
                tmpAgentPerPort[port].sort((a, b) => (a.vallue > b.value) ? 1 : ((b.value > a.value) ? -1 : 0))
            }
            setAgentPerPort(tmpAgentPerPort);
        }
        catch(e){
            console.log(e);
        }
    }
    const processData = (requests) => {
        let result = [];
        requests.forEach(row => {
            if(imo){
                if(row.data.imo != imo)
                    return;
            }
            if(shipName){
                if(row.data.shipName != shipName)
                    return;
            }
            if(country?.value){
                if(row.data.countryFlag != country.value)
                    return;
            }
            if(portOfDelivery?.value){
                if(row.data.portOfDelivery != portOfDelivery.value)
                    return;
            }
            if(location?.value){
                if(row.data.location != location.value)
                    return;
            }
            if(agent){
                if(row.data.agent != agent)
                    return;
            }
            
            let scheduledCollection = '';
            if(row.route_update && row.route_update.length > 0){
                scheduledCollection = new Date(row.route_update[0].datetime);
            }

            let collectionDatetime = '';
            if(row.waste_collection?.collectedOn){
                collectionDatetime = new Date(row.waste_collection.collectedOn);
            }

            result.push([row.id, row.status, row.data.shipName||'NA', new Date(row.requested_at ?? row.data.requestedAt), new Date(row.data.requestedDelivery), row.data.portOfDelivery||'NA', row.data.location||'NA', row.data.imo||'NA', row.status, scheduledCollection, collectionDatetime, row.data.military?'YES':'NO', row.data.woAis?'YES':'NO', row.data.new?'YES':'NO', '']);
        })
        setTableData(result);
        setRefreshing(false);
        setTimeout(() => {
            //Just to make sure the table data has time to load
            setInitialLoadDone(true);
        },1000)
    }
    const GetTableData = async() => {
        setSelected([]);
        const requests = await db.getAllRequests();
        const pendingRequests = await db.getAll('pending_requests');
        processData(requests.concat(pendingRequests));
    }
    React.useEffect(() => {
        const unsubscribe = props.navigation.addListener('focus', () => {
            // if(pageRef){
            //     pageRef.current.scrollTo({
            //         x: 0,
            //         y: 0,
            //         animated: false,
            //     });
            // }
            // if(tableRef){
            //     tableRef.current.scrollTo({
            //         x: 0,
            //         y: 0,
            //         animated: false,
            //     });
            // }
            GetFilters();
            GetTableData();
        });
        const getPageLength = async() => {
            setPageLength(await AsyncStorage.getItem('pageLength')??'10');
        }
        getPageLength();
        return unsubscribe;
      }, []);

    React.useEffect(() => {
        FilterData();
    }, [type, searchText, tableData, from, to, imo, shipName]);

    React.useEffect(() => {
        if(initialLoadDone) {
            setRefreshing(true);
            GetTableData();
        }
    }, [country.value, portOfDelivery.value, location.value, agent]);

    React.useEffect(() => {
        SortData();
    }, [sort]);

    const FormatDatetime = (dt) => {
        if(!dt)
            return '';
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const padLeft = (num) => {
            if(num < 10)
                return '0'+num;
            return num;
        }
        const date = new Date(dt);
        return padLeft(date.getDate()) + '-' + months[date.getMonth()] + '-' + date.getFullYear() + ' ' + padLeft(date.getHours()) + ':' + padLeft(date.getMinutes());
    }

    const Searched = (text) => {
        setSearchText(text);
    }

    const changeType = (type) => {
        setType(type);
    }

    const ChangeSort = (sortColumn) => {
        if(sortColumn > 0){
            setSort(prev => (
                [{
                    column: sortColumn,
                    ascending: prev[0].column==sortColumn?(!prev[0].ascending):true
                }]
            ));
        }
    }

    const Approve = (id, firstName, lastName) => {
        const data = {
            id,
            status: 'Validated'
        }
        axios.post('/api/editRequestStatus.php', data)
        .then((res) => {
            modal.confirm.show("Success", "Request "+id+" is validated successfully.");
            setTimeout(() => {
                GetTableData();
            },10)
        })
    }
    const Reject = (id, firstName, lastName) => {
        setModalOpen(true);
        setModalCallback((reason) => (reason) => {
            const data = {
                id,
                status: 'Rejected',
                reason: reason
            }
            axios.post('/api/editRequestStatus.php', data)
            .then((res) => {
                modal.confirm.show("Success", "Request "+id+" is now rejected.");
                setTimeout(() => {
                    GetTableData();
                },10)
            })
        })
    }
    const BulkApprove = () => {
        modal.confirm.show("Warning", "Are you sure you want to Approve the following requests?\n" + selected.join(', '), async() => {
            for(let i = 0; i < selected.length; i++) {
                const id = selected[i];
                const data = {
                    id,
                    status: 'Validated'
                }
                await axios.post('/api/editRequestStatus.php', data);
            }
            modal.confirm.show("Success", "Requests "+selected.join(', ')+" are validated successfully.");
            setTimeout(() => {
                GetTableData();
            },10)
        });
    }
    const BulkReject = () => {
        modal.confirm.show("Warning", "Are you sure you want to Reject the following requests?\n" + selected.join(', '), () => {
            setModalOpen(true);
            setModalCallback((reason) => async(reason) => {
                for(let i = 0; i < selected.length; i++) {
                    const id = selected[i];
                    const data = {
                        id,
                        status: 'Rejected',
                        reason: reason
                    }
                    await axios.post('/api/editRequestStatus.php', data)
                }
                modal.confirm.show("Success", "Requests "+selected.join(', ')+" are now rejected.");
                setTimeout(() => {
                    GetTableData();
                },10)
            })
        });
    }
    const Cancel = (id) => {
        modal.confirm.show("Warning", "Are you sure you want to Cancel this request?\nPlease note that by cancelling the request, you will not be able to recover it.", () => {
            const data = {
                id,
                status: 'Cancelled'
            }
            axios.post('/api/editRequestStatus.php', data)
            .then((res) => {
                modal.confirm.show("Success", "Your request has been successfully cancelled.");
                GetTableData();
            })
        });
    }
    
    const Header = (headerProps) => {
        let text = headerProps?.text?.toString()??'';
        return <View style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <MyText style={[styles.headerText, css.bold, css.s]}>{text}</MyText>
            {
                sort[0].column+1 == headerProps.cellIndex &&
                <View style={{position: 'absolute', right: -10}}>
                    <MaterialIcons style={{top: 0}} name={"keyboard-arrow-up"} size={18} color={sort[0].ascending?'white':'#461b6f'} />
                    <MaterialIcons style={{marginTop: -10}} name={"keyboard-arrow-down"} size={18} color={!sort[0].ascending?'white':'#461b6f'} />
                </View>
            }
            {
                sort[0].column+1 != headerProps.cellIndex && headerProps.cellIndex != 0 && (
                <View style={{position: 'absolute', right: -10}}>
                    <MaterialIcons style={{top: 0}} name={"keyboard-arrow-up"} size={18} color="#d1d1d1" />
                    <MaterialIcons style={{marginTop: -10}} name={"keyboard-arrow-down"} size={18} color="#d1d1d1" />
                </View>
                )
            }
            </View>;
    }
    const Bullet = (bulletProps) => {
        const white = '#fff';
        const orange = '#efa270';
        const yellow = '#fed354';
        const blue = '#1aa0eb';
        const green = '#23a43f';
        const red = '#e32112';
        let color = '';
        let text = bulletProps?.text?.toString()??'';

        if(bulletProps.cellIndex == 16){
            return <View style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                <TouchableOpacity onPress={() => props.navigation.push('Request Info', { id: bulletProps.id })}>
                    <MaterialIcons name="visibility" size={18} color="#461b6f" style={{marginRight: 10}} />
                </TouchableOpacity>
                {
                    bulletProps.status != 'Cancelled' && bulletProps.status != 'Rejected' &&
                    <TouchableOpacity onPress={() => props.navigation.push('Edit Request', { id: bulletProps.id })}>
                        <Entypo name="edit" size={14} color="#461b6f" style={{marginRight: 8}}/>
                    </TouchableOpacity>
                }
                {
                    bulletProps.status != 'Cancelled' && bulletProps.status != 'Rejected' &&
                    <TouchableOpacity onPress={() => Cancel(bulletProps.id)}>
                        <Feather name="x" size={18} color="#b10002" style={{marginRight: 10}}/>
                    </TouchableOpacity>
                }
            </View>;
        }
        if(!text){
            return <></>;
        }
        if(text.startsWith('Submitted') || text.startsWith('Validated')){
            color = orange;
        }
        else if(text.startsWith('Route') || text.startsWith('Driver')){
            color = yellow;
        }
        else if(text.startsWith('Waste')){
            color = blue;
        }
        else if(text.startsWith('Certificate')){
            color = green;
        }
        else if(text.startsWith('Cancel') || text.startsWith('Reject')){
            color = red;
        }
        if(bulletProps.cellIndex == 5 || bulletProps.cellIndex == 6 || bulletProps.cellIndex == 11 || bulletProps.cellIndex == 12){
            // text = <><MyText style={{fontSize: 0.001, color: 'transparent'}}>{text}</MyText>{FormatDatetime(text)}</>;
            text = FormatDatetime(text);
        }
        if(bulletProps.cellIndex == 3){
            if(text.startsWith('Submitted')){
                return <View style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                        <TouchableOpacity onPress={() => Approve(bulletProps.id, bulletProps.firstName, bulletProps.lastName)}>
                            <MyText style={[styles.statusAction, {backgroundColor: green, height: 24, lineHeight: Platform.OS==='android'?20:26}]}>
                                ✓
                            </MyText>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => Reject(bulletProps.id, bulletProps.firstName, bulletProps.lastName)}>
                            <MyText style={[styles.statusAction, {backgroundColor: red, height: 24, lineHeight: Platform.OS==='android'?20:24}]}>
                                x
                            </MyText>
                        </TouchableOpacity>
                    </View>
            }
            else if(text.startsWith('Rejected')){
                color = red;
            }
            else if(text.startsWith('Not Synced')){
                color = white;
            }
            else{
                text = 'Approved';
                color = green;
            }
            return <View style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <View style={{width: 10, height: 10, borderRadius: 5, backgroundColor: color, marginRight: 5, marginTop: Platform.OS==='ios'?-1:1.5, overflow: 'hidden'}}></View>
                    <MyText style={css.s}>{text}</MyText>
                </View>
        }
        if(bulletProps.cellIndex == 0){
            console.log(bulletProps.status);
            if(bulletProps.status != 'Submitted') return '';
            return <MyCheckbox onClick={() => {
                if(selected.includes(bulletProps.id)) {
                    setSelected(prev => prev.filter(item => item !== bulletProps.id));
                }
                else{
                    setSelected(prev => [...prev, bulletProps.id]);
                }
            }} checked={selected.includes(bulletProps.id)} small></MyCheckbox>
        }
        if(bulletProps.cellIndex == 13 || bulletProps.cellIndex == 14 || bulletProps.cellIndex == 15){
            return <MyText style={{color: text=='YES'?green:undefined}}>{text}</MyText>;
        }
        else if(bulletProps.cellIndex == 10){
            return <View style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <View style={{width: 10, height: 10, borderRadius: 5, backgroundColor: color, marginRight: 5, marginTop: Platform.OS==='ios'?-1:1.5, overflow: 'hidden'}}></View>
                    <MyText style={css.s}>{text}</MyText>
                </View>
        }
        let textAlign = 'left';
        if(bulletProps.cellIndex == 1)
            textAlign = 'right';
        if(bulletProps.cellIndex == 2 && bulletProps.status == 'Not Synced')
            text = 'N/A';
        return <MyText style={[css.s, {textAlign: textAlign}]}>{text}</MyText>;
    }

    const renderHeader = (cellIndex, data, id, status) => {
        const totalTableWidth = sizes.reduce((partialSum, a) => partialSum + a, 0);
        let cellWidth = sizes[cellIndex];
        if(totalTableWidth < width-230-40){ //table fits without scrolling
            cellWidth = sizes[cellIndex]*((width-230-40)/totalTableWidth);
        }
        return (
            <TouchableOpacity onPress={() => ChangeSort(cellIndex-1)}>
                <View key={cellIndex} style={[css.s, styles.cell, styles.header, {width: cellWidth}]}>
                    <Header cellIndex={cellIndex} text={data}/>
                </View>
            </TouchableOpacity>
        );
    }
    const render = (rowIndex, cellIndex, data, id, status) => {
        const totalTableWidth = sizes.reduce((partialSum, a) => partialSum + a, 0);
        let cellWidth = sizes[cellIndex];
        if(totalTableWidth < width-230-40){ //table fits without scrolling
            cellWidth = sizes[cellIndex]*((width-230-40)/totalTableWidth);
        }
        return <View key={cellIndex} style={[rowIndex%2?styles.even:styles.odd, css.s, styles.cell, {width: cellWidth}]}><Bullet rowIndex={rowIndex} cellIndex={cellIndex} id={id} text={data} status={status}/></View>;
    }

    const FilterData = () => {
        filteredData = [];
        console.log(tableData);
        tableData.forEach((row, rowIndex) => {
            if(shipName){
                if(row[2] != shipName) return;
            }

            let addedRow = false;
            let fromDate = new Date();
            let toDate = new Date();
            if(from){
                fromDate = new Date(from);
                fromDate.setHours(0);
                fromDate.setMinutes(0);
            }
            if(to){
                toDate = new Date(to);
                toDate.setHours(23);
                toDate.setMinutes(59);
            }
            if(from && !to){
                if(new Date(row[4]).getTime() < fromDate.getTime())
                    return;
            }
            else if(!from && to){
                if(new Date(row[4]).getTime() > toDate.getTime())
                    return;
            }
            else if(from && to){
                if(new Date(row[4]).getTime() < fromDate.getTime() || new Date(row[4]).getTime() > toDate.getTime())
                    return;
            }
            
            // Datetime objects to ISO String in order for sorting to work
            if(row[3].toISOString)
                row[3] = row[3].toISOString();
            if(row[4].toISOString)
                row[4] = row[4].toISOString();
            if(row[9].toISOString)
                row[9] = row[9].toISOString();
            if(row[10].toISOString)
                row[10] = row[10].toISOString();

            row.forEach((cell, cellIndex) => {
                if(addedRow)
                    return;
                if((cell??'').toString().toLowerCase().includes(searchText.toLowerCase())){
                    let hasFilter = false;
                    if(type == 'all'){
                        hasFilter = true;
                    }
                    if(row[8].startsWith('Submitted') && type == 'new'){
                        hasFilter = true;
                    }
                    else if(row[8].startsWith('Validated') && type == 'new'){
                        hasFilter = true;
                    }
                    else if(row[8].startsWith('Route Scheduled') && type == 'scheduled'){
                        hasFilter = true;
                    }
                    else if(row[8].startsWith('Driver Arrived') && type == 'scheduled'){
                        hasFilter = true;
                    }
                    else if(row[8].startsWith('Waste Collected') && type == 'collected'){
                        hasFilter = true;
                    }
                    if(hasFilter){
                        filteredData.push(row);
                        addedRow = true;
                    }
                    return;
                }
            })
        })
        SortData();
    }

    const SortData = () => {
        setPage('1');
        const statusSorting = {
            'Submitted': 0,
            'Validated': 1,
            'Route Scheduled': 2,
            'Driver Arrived': 3,
            'Waste Collected': 4,
            'Waste Segregated': 5,
            'Certificate Issued': 6,
            'Cancelled': 7,
            'Rejected': 8,
        };
        filteredData.sort((a, b) => {
            for(let i = 0; i<sort.length; i++){
                let s = sort[i];
                if(s.column === 2 || s.column === 9){ //status sorting
                    if (statusSorting[a[s.column].toString()] < statusSorting[b[s.column].toString()]) return s.ascending ? -1 : 1;
                    if (statusSorting[a[s.column].toString()] > statusSorting[b[s.column].toString()]) return s.ascending ? 1 : -1;    
                }
                else if(s.column === 8){ //numeric sorting
                    if (parseInt(a[s.column]) < parseInt(b[s.column])) return s.ascending ? -1 : 1;
                    if (parseInt(a[s.column]) > parseInt(b[s.column])) return s.ascending ? 1 : -1;    
                }
                else{
                    if (a[s.column].toString() < b[s.column].toString()) return s.ascending ? -1 : 1;
                    if (a[s.column].toString() > b[s.column].toString()) return s.ascending ? 1 : -1;    
                }
            }
            return 0;
        });
        let numbered = [['', '#', ...tableHeader]];
        filteredData.forEach((row, idx) => {
            numbered.push([false, (idx+1).toString(), ...row]);//.unshift(idx.toString()));
        })
        setData(numbered);
    }

  return (
    <>
        <KeyboardAwareScrollView vertical ref={pageRef} refreshControl={Platform.OS==='web'?undefined:<RefreshControl refreshing={refreshing} onRefresh={onRefresh}/>} nestedScrollEnabled={true} keyboardShouldPersistTaps={'handled'} contentContainerStyle={{flexShrink: 0, flexGrow: 1, padding: 0}} scrollEnabled={true}>
            <View style={[css.background, {paddingRight: 0, paddingTop: 20}]}>
                <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                    <MyText style={[css.title, css.bold]}>
                        Manage Requests
                    </MyText>
                </View>
                {/* <MyText style={css.subtitle}>
                    Date
                </MyText> */}
                <View style={{marginTop: 15}}>
                    <View style={{display: 'flex', flexDirection: 'row'}}>
                        <View style={{marginRight: 7.5, flexBasis: 127.5, flexGrow: 0, flexShrink: 0}}>
                            <MyDatePicker
                                onChange={setFrom}
                                value={from}
                                placeholder={'From\nDelivery Date'}
                                key={fromKey}
                            />
                        </View>
                        <View style={{flex: 1, flexBasis: 127.5, flexGrow: 0, flexShrink: 0}}>
                            <MyDatePicker
                                onChange={setTo}
                                value={to}
                                placeholder={'To\nDelivery Date'}
                                key={toKey}
                            />
                        </View>
                        <View style={{flex: 1, flexBasis: 127.5, flexGrow: 0, flexShrink: 0, alignSelf: 'center', marginLeft: 10}}>
                            <TouchableOpacity onPress={() => {setFrom(new Date()); setTo(new Date()); setFromKey(prev => prev+1); setToKey(prev => prev+1);}}>
                                <MyText style={{color: '#461b6f', textDecorationLine: 'underline'}}>
                                    Today
                                </MyText>
                            </TouchableOpacity>
                        </View>
                    </View>
                </View>
                <View style={{marginTop: 10, display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                    <View style={css.fullFilterButton}>
                        <MyPicker
                            style={css.formRowInput}
                            items={ships?.map(x => parseInt(x.imo)).sort((a, b) => (a - b)).map(x => x.toString())}
                            onValueChange={(item, itemIndex) => {
                                    ships.forEach(x => {
                                        if(x.imo == item.value){
                                            setShipName(x.shipName.toString());
                                        }
                                    })
                                    setImo(item.value)
                                }
                            }
                            value={imo}
                            showSearch={true}
                            placeholder={'IMO'}
                            fancyPlaceholder={true}
                        />
                    </View>
                    <View style={css.fullFilterButton}>
                        <MyPicker
                            style={css.formRowInput}
                            items={ships?.map(x => x.shipName.toString())}
                            onValueChange={(item, itemIndex) => {
                                    ships.forEach(x => {
                                        if(x.shipName == item.value){
                                            setImo(x.imo.toString());
                                        }
                                    })
                                    setShipName(item.value)
                                }
                            }
                            value={shipName}
                            showSearch={true}
                            placeholder={'Ship Name'}
                            fancyPlaceholder={true}
                        />
                    </View>
                </View>
                <View style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                    <View style={css.fullFilterButton}>
                        <MyPicker
                            style={css.formRowInput}
                            items={countries}
                            onValueChange={(item, itemIndex) => {
                                setCountry(prev => ({
                                    ...prev,
                                    key: item.key,
                                    value: item.value
                                }))
                                setPortOfDelivery(prev => ({
                                    ...prev,
                                    key: null,
                                    value: null
                                }))
                                setLocation(prev => ({
                                    ...prev,
                                    value: null
                                }))
                                setAgent(null)
                            }}
                            showSearch={true}
                            value={country.value}
                            placeholder={'Country'}
                            fancyPlaceholder={true}
                        >
                        </MyPicker>
                    </View>
                    <View style={css.fullFilterButton}>
                        <MyPicker
                            style={css.formRowInput}
                            items={portOfDeliveries?.filter(port => port.country_id == country.key)}
                            onValueChange={(item, itemIndex) => {
                                setPortOfDelivery(prev => ({
                                    ...prev,
                                    key: item.key,
                                    value: item.value
                                }))
                                setLocation(prev => ({
                                    ...prev,
                                    value: null
                                }))
                            }
                        }
                        value={portOfDelivery.value}
                        placeholder={'Port of Delivery'}
                        fancyPlaceholder={true}
                        >
                        </MyPicker>
                    </View>
                    <View style={css.fullFilterButton}>
                        <MyPicker
                            style={css.formRowInput}
                            items={locations?.filter(loc => loc.port_id == portOfDelivery.key)}
                            onValueChange={(item, itemIndex) => setLocation(prev => ({
                                ...prev,
                                value: item.value
                            }))}
                            value={location.value}
                            placeholder={'Location'}
                            fancyPlaceholder={true}
                            >
                        </MyPicker>
                    </View>
                    <View style={css.fullFilterButton}>
                        <MyPicker
                            style={css.formRowInput}
                            value={agent}
                            showSearch={true}
                            items={portOfDelivery.key ? ((agentPerPort[portOfDelivery.key] && agentPerPort[portOfDelivery.key].length > 0) ? agentPerPort[portOfDelivery.key] : []) : []}
                            onValueChange={(item, itemIndex) =>
                                setAgent(item.value)
                            }
                            placeholder={'Agent'}
                            fancyPlaceholder={true}
                            >
                        </MyPicker>
                    </View>
                </View>
                <View>
                    <View style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                        <View style={css.filterButton}>
                            <TouchableOpacity onPress={() => changeType('all')}>
                                <View style={[css.smallButton, {backgroundColor: type=='all'?'#461b6f':'white'}]}>
                                    <MyText style={[css.bold, css.smallButtonText, {color: type=='all'?'white':'#461b6f'}]}>
                                        All Requests
                                    </MyText>
                                </View>
                            </TouchableOpacity>
                        </View>
                        <View style={css.filterButton}>
                            <TouchableOpacity onPress={() => changeType('new')}>
                                <View style={[css.smallButton, {backgroundColor: type=='new'?'#461b6f':'white'}]}>
                                    <MyText style={[css.bold, css.smallButtonText, {color: type=='new'?'white':'#461b6f'}]}>
                                        New
                                    </MyText>
                                </View>
                            </TouchableOpacity>
                        </View>
                        <View style={css.filterButton}>
                            <TouchableOpacity onPress={() => changeType('scheduled')}>
                                <View style={[css.smallButton, {backgroundColor: type=='scheduled'?'#461b6f':'white'}]}>
                                    <MyText style={[css.bold, css.smallButtonText, {color: type=='scheduled'?'white':'#461b6f'}]}>
                                        Scheduled
                                    </MyText>
                                </View>
                            </TouchableOpacity>
                        </View>
                        <View style={css.filterButton}>
                            <TouchableOpacity onPress={() => changeType('collected')}>
                                <View style={[css.smallButton, {backgroundColor: type=='collected'?'#461b6f':'white'}]}>
                                    <MyText style={[css.bold, css.smallButtonText, {color: type=='collected'?'white':'#461b6f'}]}>
                                        Collected
                                    </MyText>
                                </View>
                            </TouchableOpacity>
                        </View>
                        <View style={[css.filterButton, {justifyContent: 'center', alignItems: 'center'}]}>
                            <TouchableOpacity onPress={() => {setFrom(null); setTo(null); setImo(null); setShipName(null); setCountry({value: ''}); setPortOfDelivery({value: ''}); setLocation({value: ''}); setAgent(null); setType('all'); setFromKey(prev => prev+1); setToKey(prev => prev+1);}}>
                                <MyText style={{color: '#461b6f', textDecorationLine: 'underline'}}>
                                    Clear Filters
                                </MyText>
                            </TouchableOpacity>
                        </View>
                    </View>
                </View>
                {
                    width<576?
                    <View style={{display: 'flex', flexDirection: 'row', marginRight: 20, marginTop: 0, marginBottom: 10, justifyContent: 'center'}}>
                        <View style={{flexDirection: 'row', alignItems: 'center'}}>
                            <MyText style={{paddingRight: 10}}>
                                Show
                            </MyText>
                            <View style={{width: 50}}>
                                <MyPicker
                                    style={css.formRowInput}
                                    items={['5','10','25','50']}
                                    onValueChange={(item, itemIndex) => {
                                            setPageLength(item.value);
                                            AsyncStorage.setItem('pageLength', item.value);
                                            const maxPage = Math.ceil(data.length/parseInt(item.value));
                                            if(page > maxPage){
                                                setPage(maxPage.toString());
                                            }
                                        }
                                    }
                                    value={pageLength}
                                    placeholder={'Show'}
                                    // fancyPlaceholder={true}
                                />
                            </View>
                            <MyText style={{paddingLeft: 10}}>
                                Requests
                            </MyText>
                        </View>
                    </View>
                    :<></>
                }
                <View style={{display: 'flex', flexDirection: 'row', marginRight: 20, marginTop: 0, marginBottom: 10, justifyContent: 'flex-start'}}>
                    {/* <MyText style={[{lineHeight: 33, marginRight: 10}, css.bold]}>Search</MyText> */}
                    <MyTextInput icon='search' onChangeTextImmediate={Searched} onChangeText={() => false} value={searchText} placeholder='Search...' style={{flexGrow: 0, flexShrink: 0, flexBasis: 232, marginRight: 38}}/>
                    {
                        width>=576?
                        <View style={{flexDirection: 'row', alignItems: 'center'}}>
                            <MyText style={{paddingRight: 10}}>
                                Show
                            </MyText>
                            <View style={{width: 50}}>
                                <MyPicker
                                    style={css.formRowInput}
                                    items={['5','10','25','50']}
                                    onValueChange={(item, itemIndex) => {
                                            setPageLength(item.value);
                                            AsyncStorage.setItem('pageLength', item.value);
                                            const maxPage = Math.ceil(data.length/parseInt(item.value));
                                            if(page > maxPage){
                                                setPage(maxPage.toString());
                                            }
                                        }
                                    }
                                    value={pageLength}
                                    placeholder={'Show'}
                                    // fancyPlaceholder={true}
                                />
                            </View>
                            <MyText style={{paddingLeft: 10}}>
                                Requests
                            </MyText>
                        </View>
                        :<></>
                    }
                    <TouchableOpacity onPress={() => exportDataToExcel()} style={{marginLeft: 'auto'}}>
                        <View style={{flexDirection: 'column', alignItems: 'center'}}>
                            <Feather name="download" size={18} color="#461b6f" />
                            <MyText style={[css.xs, {color: '#461b6f'}]}>
                                Export
                            </MyText>
                        </View>
                    </TouchableOpacity>
                </View>
                <View style={{marginBottom: 50}}>
                    <View style={{display: 'flex', flexDirection: 'row'}}>
                        <View style={{flexShrink: 0, flexGrow: 0, borderTopLeftRadius: 8, borderBottomLeftRadius: 8, overflow: 'hidden'}}>
                            {
                                data.length>0?
                                <View key={0} style={{display: 'flex', flexDirection: 'row'}}>
                                    {
                                        data[0].map((cellData, cellIndex) => {
                                            if(cellIndex >= 4)
                                                return <></>;
                                            return renderHeader(cellIndex, cellData, data[0][2], data[0][10]);
                                        })
                                    }
                                </View>
                                :null
                            }
                            {
                                data.slice(1).map((rowData, rowIndex) => {
                                    if(rowIndex < (parseInt(page)-1)*parseInt(pageLength) || rowIndex >= ((parseInt(page)-1)*parseInt(pageLength) + parseInt(pageLength)))
                                        return <></>
                                    return (
                                        <View key={rowIndex} style={{display: 'flex', flexDirection: 'row'}}>
                                            {
                                                rowData.map((cellData, cellIndex) => {
                                                    if(cellIndex >= 4)
                                                        return <></>;
                                                    return render(rowIndex, cellIndex, cellData, rowData[2], rowData[10]);
                                                })
                                            }
                                        </View>
                                    )
                                })
                            }
                        </View>
                        <ScrollView horizontal ref={tableRef}>
                            <View style={{marginRight: 20, borderTopRightRadius: 8, borderBottomRightRadius: 8, overflow: 'hidden'}}>
                                {
                                    data.length>0?
                                    <View key={0} style={{display: 'flex', flexDirection: 'row'}}>
                                        {
                                            data[0].map((cellData, cellIndex) => {
                                                if(cellIndex < 4)
                                                    return <></>;
                                                return renderHeader(cellIndex, cellData, data[0][2], data[0][10]);
                                            })
                                        }
                                    </View>
                                    :null
                                }
                                {
                                    data.slice(1).map((rowData, rowIndex) => {
                                        if(rowIndex < (parseInt(page)-1)*parseInt(pageLength) || rowIndex >= ((parseInt(page)-1)*parseInt(pageLength) + parseInt(pageLength)))
                                            return <></>
                                        return (
                                            <View key={rowIndex} style={{display: 'flex', flexDirection: 'row'}}>
                                                {
                                                    rowData.map((cellData, cellIndex) => {
                                                        if(cellIndex < 4)
                                                            return <></>;
                                                        return render(rowIndex, cellIndex, cellData, rowData[2], rowData[10]);
                                                    })
                                                }
                                            </View>
                                        )
                                    })
                                }
                            </View>
                        </ScrollView>
                    </View>
                    <View style={[styles.odd, css.s, styles.cell, {textAlign: 'center', display: data.length==1?'flex':'none'}]}>
                        <MyText style={{textAlign: 'center'}}>
                            {initialLoadDone?'No requests found':'Loading...'}
                        </MyText>
                    </View>
                    {
                        selected?.length > 0 ?
                        <View style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                            <TouchableOpacity onPress={() => BulkApprove()}>
                                <MyText style={[styles.statusAction, {backgroundColor: '#23a43f', height: 24, lineHeight: Platform.OS==='android'?20:26}]}>
                                    ✓
                                </MyText>
                            </TouchableOpacity>
                            <TouchableOpacity onPress={() => BulkReject()}>
                                <MyText style={[styles.statusAction, {backgroundColor: '#e32112', height: 24, lineHeight: Platform.OS==='android'?20:24}]}>
                                    x
                                </MyText>
                            </TouchableOpacity>
                        </View>
                        :null
                    }
                    {
                        data?.length-1 > 0 ?
                        <View style={{display: 'flex', flexDirection: 'row', marginRight: 20, marginTop: 10, marginBottom: 10, justifyContent: 'space-between'}}>
                            <MyText>
                                Showing <MyText style={[css.bold, {color: '#461b6f'}]}>{(page-1)*pageLength+1}</MyText> to <MyText style={[css.bold, {color: '#461b6f'}]}>{Math.min((parseInt(page)-1)*parseInt(pageLength) + parseInt(pageLength), data.length-1)}</MyText> of <MyText style={[css.bold, {color: '#461b6f'}]}>{data?.length-1}</MyText> requests
                            </MyText>
                            <View style={{flexDirection: 'row', alignItems: 'center'}}>
                                <TouchableOpacity onPress={() => page-1>=1?setPage((parseInt(page)-1).toString()):false}>
                                    <AntDesign name="left" size={18} color="#461b6f" />
                                </TouchableOpacity>
                                <View style={[{width: 50, marginVertical: 0, marginHorizontal: 10}]}>
                                    <MyPicker
                                        style={css.formRowInput}
                                        items={[...Array(Math.ceil(data?.length/pageLength))].map((_, i) => (i+1).toString())}
                                        onValueChange={(item, itemIndex) => {
                                                setPage(item.value);
                                            }
                                        }
                                        value={page}
                                        placeholder={'Page'}
                                        fancyPlaceholder={true}
                                    />
                                </View>
                                <TouchableOpacity onPress={() => parseInt(page)+1<=Math.ceil(data?.length/parseInt(pageLength))?setPage((parseInt(page)+1).toString()):false}>
                                    <AntDesign name="right" size={18} color="#461b6f" />
                                </TouchableOpacity>
                            </View>
                        </View>
                        :null
                    }
                </View>
            </View>
        </KeyboardAwareScrollView>
        <MyReasonModal enabled={modalOpen} body='Please provide a reason for the Request Rejection' close={() => setModalOpen(false)} callback={modalCallback}/>
    </>
  );
}

const styles = StyleSheet.create({
    text: {
        padding: 10,
    },
    row: {
        flexDirection: 'row',
    },
    header: {
        backgroundColor: '#461b6f',
    },
    headerText: {
        color: 'white'
    },
    cell: {
        paddingVertical: 5,
        paddingHorizontal: 10,
        height: Platform.OS==='web'?55:45,
        borderWidth: 1,
        borderColor: 'white',
        display: 'flex',
        justifyContent: 'center'
    },
    even: {
        backgroundColor: '#F6F4F9'
    },
    odd: {
        backgroundColor: '#ececec'
    },
    statusAction: {
        width: 30,
        textAlign: 'center',
        paddingTop: 0,
        paddingBottom: 4,
        borderRadius: 5,
        color: 'white',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: 5,
        overflow: 'hidden',
    }
});

export default ManageRequests