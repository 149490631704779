import React from 'react';
import {
    StyleSheet,
    View,
    TouchableOpacity,
    ScrollView,
    TouchableWithoutFeedback,
    ActivityIndicator
  } from 'react-native'
import stylesheet from './Style'
import MyText from './MyText';
import MyDatePicker from './MyDatePicker';
import MyTextInput from './MyTextInput';
import MyPicker from './MyPicker';
import { MaterialIcons, Entypo, Feather } from '@expo/vector-icons';
import * as SecureStore from 'expo-secure-store';
import axios from 'axios';

import * as db from './database';
import AsyncStorage from '@react-native-async-storage/async-storage';

export default function RegisterAgent(props) {
    const css = stylesheet();
    
    const [countries, setCountries] = React.useState(null);
    const [agents, setAgents] = React.useState(null);

    const [countryFlagCords, setCountryFlagCords] = React.useState(0);

   const [agentEditable, setAgentEditable] = React.useState(true);

    const [countryFlag, setCountryFlag] = React.useState({
        value: '',
        hasError: false
    });
    const [companyRegNum, setCompanyRegNum] = React.useState({
        value: '',
        hasError: false
    });
    const [companyName, setCompanyName] = React.useState({
        value: '',
        hasError: false
    });
    const [address, setAddress] = React.useState({
        value: '',
        hasError: false
    });
    const [area, setArea] = React.useState({
        value: '',
        hasError: false
    });
    const [postalCode, setPostalCode] = React.useState({
        value: '',
        hasError: false
    });
    const [vatNumber, setVatNumber] = React.useState({
        value: '',
        hasError: false
    });
    const [vatType, setVatType] = React.useState({
        value: '',
        hasError: false
    });
    const [taxOffice, setTaxOffice] = React.useState({
        value: '',
        hasError: false
    });
    const [vatExceptionReason, setVatExceptionReason] = React.useState({
        value: '',
        hasError: false
    });
    const [email, setEmail] = React.useState({
        value: '',
        hasError: false
    });
    const [phone, setPhone] = React.useState({
        value: '',
        hasError: false
    });
    const [website, setWebsite] = React.useState({
        value: '',
        hasError: false
    });

    //Person in charge
    const [contactPersonFName, setContactPersonFName] = React.useState({
        value: '',
        hasError: false
    });
    const [contactPersonLName, setContactPersonLName] = React.useState({
        value: '',
        hasError: false
    });
    const [contactPersonProfession, setContactPersonProfession] = React.useState({
        value: '',
        hasError: false
    });
    const [contactPersonEmail, setContactPersonEmail] = React.useState({
        value: '',
        hasError: false,
        valid: false
    });
    const [contactPersonPhone, setContactPersonPhone] = React.useState({
        value: '',
        hasError: false,
        valid: false
    });

    const GetAgentByVat = async(parameter) => {
        setAgentEditable(true);
        try{
            agents.forEach(agent => {
                if(agent.vatNumber == (parameter?parameter:vatNumber.value)){
                    SetAgent(agent);
                }
            })
        }catch(e){}
    }

    const GetAgentByName = async(parameter) => {
        setAgentEditable(true);
        try{
            agents.forEach(agent => {
                if(agent.companyName.toLowerCase() == (parameter?parameter.toLowerCase():companyName.value.toLowerCase())){
                    SetAgent(agent);
                }
            })
        }catch(e){}
    }

    const SetAgent = (data) => {
        console.log(data);
        setAgentEditable(false);
        setCountryFlag({
            hasError: false,
            value: (data.country??"").toString()
         })
         setCompanyName({
             hasError: false,
             value: (data.companyName??"").toString()
         })
         setAddress({
             hasError: false,
             value: (data.address??"").toString()
         })
         setArea({
             hasError: false,
             value: (data.area??"").toString()
         })
         setPostalCode({
             hasError: false,
             value: (data.postalCode??"").toString()
         })
         setVatNumber({
             hasError: false,
             value: (data.vatNumber??"").toString()
         })
        setEmail({
             hasError: false,
             value: (data.agentEmail??"").toString()
         })
         setPhone({
              hasError: false,
              value: (data.agentPhone??"").toString()
          })
        setContactPersonFName({
            hasError: false,
            value: (data.contactPersonFName??"").toString()
        })
        setContactPersonLName({
            hasError: false,
            value: (data.contactPersonLName??"").toString()
        })
        setContactPersonProfession({
            hasError: false,
            value: (data.contactPersonProfession??"").toString()
        })
        setContactPersonEmail({
            hasError: false,
            value: (data.contactPersonEmail??"").toString()
        })
        setContactPersonPhone({
            hasError: false,
            value: (data.contactPersonPhone??"").toString()
        })
      }

    React.useEffect(() => {
        // calculate hasError and send to parent
        setCountryFlag(val => ({...val, hasError: !val.value}));
        setCompanyName(val => ({...val, hasError: !val.value}));
        setAddress(val => ({...val, hasError: !val.value}));
        setArea(val => ({...val, hasError: !val.value}));
        setPostalCode(val => ({...val, hasError: !val.value}));
        setVatNumber(val => ({...val, hasError: !val.value}));
        setVatType(val => ({...val, hasError: !val.value}));
        setEmail(val => ({...val, hasError: (!val.value || !val.valid)}));
        setPhone(val => ({...val, hasError: (!val.value || !val.valid)}));
        setContactPersonFName(val => ({...val, hasError: !val.value}));
        setContactPersonLName(val => ({...val, hasError: !val.value}));
        setContactPersonEmail(val => ({...val, hasError: (!val.value || !val.valid)}));
        setContactPersonPhone(val => ({...val, hasError: (!val.value || !val.valid)}));
        setWebsite(val => ({...val, hasError: false}))
    },[companyName.value, address.value, area.value, postalCode.value, countryFlag.value, vatNumber.value, vatType.value, email.value, phone.value,
          contactPersonFName.value, contactPersonLName.value, contactPersonEmail.value, contactPersonPhone.value,
            email.valid, phone.valid, contactPersonEmail.valid, contactPersonPhone.valid])

    React.useEffect(() => {
        const data = {
            companyRegNum,
            companyName,
            address,
            area,
            postalCode,
            countryFlag,
            vatNumber,
            vatType,
            taxOffice,
            vatExceptionReason,
            email,
            phone,
            website,
            contactPersonFName,
            contactPersonLName,
            contactPersonProfession,
            contactPersonEmail,
            contactPersonPhone
        }
        props.setRegisterAgent(data);
    },[companyName.hasError, address.hasError, area.hasError, postalCode.hasError, countryFlag.hasError, vatNumber.hasError, email.hasError, phone.hasError,
        contactPersonFName.hasError, contactPersonLName.hasError, contactPersonEmail.hasError, contactPersonPhone.hasError,
        companyName.value, address.value, area.value, postalCode.value, countryFlag.value, vatNumber.value, email.value, phone.value,
        contactPersonFName.value, contactPersonLName.value, contactPersonEmail.value, contactPersonPhone.value])

    React.useEffect(() => {
        const filters = async () => {
            try{
                setCountries(await db.getAll('countries'));
                setAgents(await db.getAll('agents', 'value'));
            }
            catch(e){}
        }
        filters().catch(console.error);
    },[]);

    React.useEffect(() => {
        if(props.initialAgent){
            const data = props.initialAgent;
            setCompanyRegNum(prev => ({...prev, value: data.companyRegNumber.toString()}));
            setCompanyName(prev => ({...prev, value: data.companyName}));
            setAddress(prev => ({...prev, value: data.address}));
            setArea(prev => ({...prev, value: data.area}));
            setPostalCode(prev => ({...prev, value: data.postalCode.toString()}));
            setCountryFlag(prev => ({...prev, value: data.country}));
            setVatNumber(prev => ({...prev, value: data.vat}));
            setVatType(prev => ({...prev, value: data.vatType}));
            setTaxOffice(prev => ({...prev, value: data.taxOffice}));
            setVatExceptionReason(prev => ({...prev, value: data.vatExceptionReason}));
            setEmail(prev => ({...prev, value: data.email}));
            setPhone(prev => ({...prev, value: data.phone}));
            setWebsite(prev => ({...prev, value: data.website}));
            setContactPersonFName(prev => ({...prev, value: data.contactFirstName}));
            setContactPersonLName(prev => ({...prev, value: data.contactLastName}));
            setContactPersonProfession(prev => ({...prev, value: data.contactPosition}));
            setContactPersonEmail(prev => ({...prev, value: data.contactEmail}));
            setContactPersonPhone(prev => ({...prev, value: data.contactPhone}));
        }
    },[props.initialAgent])

    return (
        <>
        <View style={[{zIndex: 10}, css.form]} >
            <MyText style={[css.formTitle, css.bold]}>
                {props.title}
            </MyText>
            <View
            style={{
                borderBottomColor: '#f3f1f8',
                borderBottomWidth: 2,
            }}
            />
            <View style={css.formContainer}>
            <View style={css.formRow}>
                <MyText style={css.formRowLabel}>
                Company Reg. Number (VAT)*
                </MyText>
                <MyTextInput
                    style={css.formRowInput}
                    onChangeText={(e) => {
                            setVatNumber(prev => ({
                                ...prev,
                                value: e
                            }))
                            GetAgentByVat(e);
                        }
                    }
                    value={vatNumber.value}
                    hasError={vatNumber.hasError && props.submit}
                    validation='vat'
                    onChangeValidation={(e) => {
                        setVatNumber(prev => ({
                            ...prev,
                            valid: e
                        }))
                    }}
                    isInfo={props.isInfo}
                />
            </View>
            <View style={css.formRow}>
                <MyText style={css.formRowLabel}>
                    Company Name*
                </MyText>
                <MyTextInput
                    style={css.formRowInput}
                    onChangeText={(e) => {
                            setCompanyName(prev => ({
                                ...prev,
                                value: e
                            }))
                            GetAgentByName(e);
                        }
                    }
                    value={companyName.value}
                    hasError={companyName.hasError && props.submit}
                    isInfo={props.isInfo}
                />
            </View>
            <View style={css.formRow}>
                <MyText style={css.formRowLabel}>
                    Address*
                </MyText>
                <MyTextInput
                    style={css.formRowInput}
                    onChangeText={(e) => {
                            setAddress(prev => ({
                                ...prev,
                                value: e
                            }))
                        }
                    }
                    value={address.value}
                    hasError={address.hasError && props.submit}
                    editable={agentEditable}
                    isInfo={props.isInfo}
                />
            </View>
            <View style={css.formRow}>
                <MyText style={css.formRowLabel}>
                    Area*
                </MyText>
                <MyTextInput
                    style={css.formRowInput}
                    onChangeText={(e) => {
                            setArea(prev => ({
                                ...prev,
                                value: e
                            }))
                        }
                    }
                    value={area.value}
                    hasError={area.hasError && props.submit}
                    editable={agentEditable}
                    isInfo={props.isInfo}
                />
            </View>
            <View style={css.formRow}>
                <MyText style={css.formRowLabel}>
                Postal Code*
                </MyText>
                <MyTextInput
                    inputMode='decimal'
                    keyboardType='phone-pad'
                    style={css.formRowInput}
                    onChangeText={(e) => {
                            setPostalCode(prev => ({
                                ...prev,
                                value: e
                            }))
                        }
                    }
                    value={postalCode.value}
                    hasError={postalCode.hasError && props.submit}
                    editable={agentEditable}
                    isInfo={props.isInfo}
                />
            </View>
            <View style={[{zIndex: 6}, css.formRow]} onLayout={(event) => {setCountryFlagCords(event.nativeEvent.layout.y + 450)}}>
                <MyText style={css.formRowLabel}>
                Country*
                </MyText>
                <MyPicker
                    style={css.formRowInput}
                    items={countries}
                    onValueChange={(item, itemIndex) => {
                        setCountryFlag(prev => ({
                            ...prev,
                            value: item.value
                        }))
                        if(item.value != 'Greece') {
                            setTaxOffice(prev => ({
                                ...prev,
                                value: ''
                            }))
                        }
                    }}
                    showSearch={true}
                    value={countryFlag.value}
                    hasError={countryFlag.hasError && props.submit}
                    editable={agentEditable}
                    isInfo={props.isInfo}
                >
                </MyPicker>
            </View>
            { countryFlag?.value == 'Greece' ?
            <View style={css.formRow}>
                <MyText style={css.formRowLabel}>
                Tax Office (ΔΟΥ)
                </MyText>
                <MyTextInput
                style={css.formRowInput}
                onChangeText={(e) => setTaxOffice(prev => ({
                    ...prev,
                    value: e
                }))}
                value={taxOffice.value}
                editable={agentEditable}
                //hasError={taxOffice.hasError && props.submit}
                isInfo={props.isInfo}
                />
            </View>
            :null}
            <View style={css.formRow}>
                <MyText style={css.formRowLabel}>
                VAT Exemption*
                </MyText>
                <MyPicker
                    style={css.formRowInput}
                    items={['Yes', 'No']}
                    onValueChange={(item, itemIndex) => setVatType(prev => ({
                        ...prev,
                        value: item.value
                    }))}
                    value={vatType.value}
                    editable={agentEditable}
                    hasError={vatType.hasError && props.submit}
                    isInfo={props.isInfo}
                >
                </MyPicker>
            </View>
            {
                vatType.value === 'Yes'?
                <View style={css.formRow}>
                    <MyText style={css.formRowLabel}>
                    VAT Reason for Exemption
                    </MyText>
                    <MyTextInput
                    style={css.formRowInput}
                    onChangeText={(e) => setVatExceptionReason(prev => ({
                        ...prev,
                        value: e
                    }))}
                    value={vatExceptionReason.value}
                    editable={agentEditable}
                    //hasError={vatExceptionReason.hasError && props.submit}
                    isInfo={props.isInfo}
                    />
                </View>
                :null
            }
            <View style={css.formRow}>
                <MyText style={css.formRowLabel}>
                    Email Address*
                </MyText>
                <MyTextInput
                    style={css.formRowInput}
                    onChangeText={(e) => setEmail(prev => ({
                        ...prev,
                        value: e
                    }))}
                    value={email.value}
                    hasError={email.hasError && props.submit}
                    inputMode='email'
                    keyboardType='email-address'
                    validation='email'
                    onChangeValidation={(e) => {
                            setEmail(prev => ({
                                ...prev,
                                valid: e
                            }))
                        }
                    }
                    autoCapitalize='none'
                    autoCorrect={false}
                    editable={agentEditable}
                    isInfo={props.isInfo}
                />
            </View>
            <View style={css.formRow}>
                <MyText style={css.formRowLabel}>
                    Phone Number*
                </MyText>
                <MyTextInput
                    inputMode='decimal'
                    keyboardType='phone-pad'
                    style={css.formRowInput}
                    onChangeText={(e) => setPhone(prev => ({
                        ...prev,
                        value: e
                    }))}
                    value={phone.value}
                    hasError={phone.hasError && props.submit}
                    validation='phone'
                    onChangeValidation={(e) => {
                            setPhone(prev => ({
                                ...prev,
                                valid: e
                            }))
                        }
                    }
                    editable={agentEditable}
                    isInfo={props.isInfo}
                />
            </View>
            <View style={css.formRow}>
                <MyText style={css.formRowLabel}>
                Website
                </MyText>
                <MyTextInput
                style={css.formRowInput}
                onChangeText={(e) => setWebsite(prev => ({
                    ...prev,
                    value: e
                }))}
                value={website.value}
                //hasError={website.hasError && props.submit}
                validation='website'
                editable={agentEditable}
                onChangeValidation={(e) => {
                        setWebsite(prev => ({
                            ...prev,
                            valid: e
                        }))
                    }
                }
                isInfo={props.isInfo}
                />
            </View>
            <View style={[css.formRow, {flexBasis: '100%', marginTop: 20, marginBottom: 15}]}>
                <MyText style={[css.m, styles.contactPersonTitle]}>
                    Person in charge
                </MyText>
            </View>
            <View style={[{zIndex: 10}, css.formRow]}>
                <MyText style={css.formRowLabel}>
                    First Name*
                </MyText>
                <MyTextInput
                    style={css.formRowInput}
                    onChangeText={(e) => setContactPersonFName(prev => ({
                        ...prev,
                        value: e
                    }))}
                    value={contactPersonFName.value}
                    hasError={contactPersonFName.hasError && props.submit}
                    isInfo={props.isInfo}
                />
            </View>
            <View style={[{zIndex: 9}, css.formRow]}>
                <MyText style={css.formRowLabel}>
                    Last Name*
                </MyText>
                <MyTextInput
                    style={css.formRowInput}
                    onChangeText={(e) => setContactPersonLName(prev => ({
                        ...prev,
                        value: e
                    }))}
                    value={contactPersonLName.value}
                    hasError={contactPersonLName.hasError && props.submit}
                    isInfo={props.isInfo}
                />
            </View>
            <View style={[{zIndex: 9}, css.formRow]}>
                <MyText style={css.formRowLabel}>
                    Position
                </MyText>
                <MyTextInput
                    style={css.formRowInput}
                    onChangeText={(e) => setContactPersonProfession(prev => ({
                        ...prev,
                        value: e
                    }))}
                    value={contactPersonProfession.value}
                    hasError={contactPersonProfession.hasError && props.submit}
                    isInfo={props.isInfo}
                />
            </View>
            <View style={[{zIndex: 9}, css.formRow]}>
                <MyText style={css.formRowLabel}>
                    Email*
                </MyText>
                <MyTextInput
                    style={css.formRowInput}
                    onChangeText={(e) => setContactPersonEmail(prev => ({
                        ...prev,
                        value: e
                    }))}
                    value={contactPersonEmail.value}
                    hasError={contactPersonEmail.hasError && props.submit}
                    inputMode='email'
                    keyboardType='email-address'
                    validation='email'
                    onChangeValidation={(e) => {
                            setContactPersonEmail(prev => ({
                                ...prev,
                                valid: e
                            }))
                        }
                    }
                    isInfo={props.isInfo}
                    autoCapitalize='none'
                    autoCorrect={false}
                />
            </View>
            <View style={[{zIndex: 9}, css.formRow]}>
                <MyText style={css.formRowLabel}>
                    Phone*
                </MyText>
                <MyTextInput
                    inputMode='decimal'
                    keyboardType='phone-pad'
                    style={css.formRowInput}
                    onChangeText={(e) => setContactPersonPhone(prev => ({
                        ...prev,
                        value: e
                    }))}
                    value={contactPersonPhone.value}
                    hasError={contactPersonPhone.hasError && props.submit}
                    validation='phone'
                    onChangeValidation={(e) => {
                            setContactPersonPhone(prev => ({
                                ...prev,
                                valid: e
                            }))
                        }
                    }
                    isInfo={props.isInfo}
                />
            </View>
        </View>
    </View>
    </>
);
}

const styles = StyleSheet.create({
    contactPersonTitle: {
        color: '#461b6f',
        // paddingHorizontal: 15,
    },
});
