import OneSignal from "./helpers/onesignal";

import * as Linking from 'expo-linking';
import 'react-native-gesture-handler';
import React, { useCallback, useMemo, useRef } from 'react';
import { StatusBar } from 'expo-status-bar';
import { StyleSheet, Text, View, TextInput, TouchableWithoutFeedback, TouchableOpacity as RNTouchableOpacity, Button, Platform, useWindowDimensions, ActivityIndicator } from 'react-native';
if (Platform.OS === 'web') {
  global._frameTimestamp = null
}
import { Picker } from '@react-native-picker/picker';
import { NavigationContainer, StackActions, useLinkTo } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { createDrawerNavigator, DrawerToggleButton } from '@react-navigation/drawer';
import {
  SafeAreaView,
  SafeAreaProvider,
  SafeAreaInsetsContext,
  useSafeAreaInsets,
  initialWindowMetrics,
} from 'react-native-safe-area-context';
import { useFonts } from 'expo-font';
import stylesheet from './Style'
import MyText from './MyText';
import MyTextInput from './MyTextInput';
import MyPicker from './MyPicker';
import Header from './Header';
import Footer from './Footer';
import Collapsible from 'react-native-collapsible';
import NewRequest from './NewRequest/NewRequest';
import Home from './Home';
import Dashboard from './Dashboard';
import Documents from './Documents';
import MyModal from './MyModal';
import MyRequests from './MyRequests';
import SyncRequests from './SyncRequests';

// import * as SplashScreen from 'expo-splash-screen';
// SplashScreen.preventAutoHideAsync();

import AsyncStorage from '@react-native-async-storage/async-storage';
import * as SecureStore from 'expo-secure-store';
import 'react-native-get-random-values';
import { v4 as uuidv4 } from 'uuid';

import {
  BottomSheetModal,
  BottomSheetModalProvider,
  BottomSheetBackdrop
} from '@gorhom/bottom-sheet';

import axios from 'axios';
import { GestureHandlerRootView, TouchableOpacity } from 'react-native-gesture-handler';
import MyNotifications from './MyNotifications';
import CustomSidebarMenu from './CustomSidebarMenu';
import Login from './Login';
import Register from './Register';
import BottomSheetModalBackdrop from './BottomSheetModalBackdrop';
import CustomBottomSheetContent from './CustomBottomSheetContent';
import LandingPage from './LandingPage';
import ManageRequests from './VGroup/ManageRequests';
import ManageUsers from './VGroup/ManageUsers';
import Splash from './Splash';
import MyProfile from './MyProfile';
import SideMenu from './SideMenu';
import WebFooter from './WebFooter';

import NetInfo from "@react-native-community/netinfo";
import * as FileSystem from 'expo-file-system';
import * as db from './database';
import CustomBottomSheetCookieContent from "./CustomBottomSheetCookieContent";
import AboutUs from "./AboutUs";
import About from "./About";
import Career from "./Career";
import Press from "./Press";
import CustomerSupport from "./CustomerSupport";
import Terms from "./Terms";
import Privacy from "./Privacy";
import MyDocumentModal from "./MyDocumentModal";
import { useModal } from "./hooks/modal";
import Constants from 'expo-constants';
import CustomBottomSheetDisclaimerContent from "./CustomBottomSheetDisclainerContent";
import CustomBottomSheetNewRequest from "./CustomBottomSheetNewRequest";

// import BackgroundFetch from "react-native-background-fetch";

const Stack = createStackNavigator();
const Drawer = createDrawerNavigator();
const navigationRef = React.createRef();

if(OneSignal){
  OneSignal.addSubscriptionObserver(event => {
    // console.log("OneSignal: subscription changed event:", event);
    // console.log("OneSignal: subscription changed from userId:", event.from.userId);
    // console.log("OneSignal: subscription changed to userId:", event.to.userId);
    // console.log("OneSignal: subscription changed from pushToken:", event.from.pushToken);
    // console.log("OneSignal: subscription changed to pushToken:", event.to.pushToken);
    // console.log("OneSignal: subscription changed from isPushDisabled:", event.from.isPushDisabled);
    // console.log("OneSignal: subscription changed to isPushDisabled:", event.to.isPushDisabled);
    // console.log("OneSignal: subscription changed from isSubscribed:", event.from.isSubscribed);
    // console.log("OneSignal: subscription changed to isSubscribed:", event.to.isSubscribed);

    if(event.to.userId){
      const saveDevice = async () => {
        let uuid = uuidv4();
        let fetchUUID = await AsyncStorage.getItem('uuid')
        if (fetchUUID) {
          uuid = fetchUUID
        }
        await AsyncStorage.setItem('uuid', uuid);

        let data = {
          uuid,
          onesignal_token: event.to.userId
        };

        axios.post('/api/saveDevice.php', data)
            .then((res) => {})
      }
      saveDevice().catch(console.error);
    }
  });
}

RNTouchableOpacity.defaultProps = {
  ...TouchableOpacity.defaultProps,
  activeOpacity: 0.5,
  style: Platform.OS==='web'?{
    outline: 0
  }:{}
};
TouchableOpacity.defaultProps.activeOpacity = 0.5;
TouchableOpacity.defaultProps.style = Platform.OS==='web'?{
  outline: 0
}:{}

Text.defaultProps = {};
TextInput.defaultProps = {};
Text.defaultProps.allowFontScaling = false;
TextInput.defaultProps.allowFontScaling = false;

axios.defaults.baseURL = Constants.expoConfig.extra.baseURL;

// if(Platform.OS !== 'web'){
//   axios.defaults.baseURL = 'https://footprinterp.mdataws.com';
// }
// else{
//   if(__DEV__){
//     axios.defaults.baseURL = 'https://footprinterp.mdataws.com';
//   }
//   else{
//     axios.defaults.baseURL = '/';
//   }
// }
axios.defaults.withCredentials = true;

let deeplinkPrevPath = '';

const App = (props) => {
  const css = stylesheet();
  const modal = useModal()

  const [user, setUser] = React.useState(null);
  const [initial, setInitial] = React.useState('');
  const [splashFinished, setSplashFinished] = React.useState(false);
  const [firstRedirect, setFirstRedirect] = React.useState(false);

  const bottomSheetModalRef = useRef(null);
  const bottomSheetConsentModalRef = useRef(null);
  const bottomSheetDisclaimerModalRef = useRef(null);
  const bottomSheetNewRequestModalRef = useRef(null);

  const handleSheetChanges = useCallback((index) => {
    // console.log('handleSheetChanges', index);
  }, []);

  const [notificationsKey, setNotificationsKey] = React.useState(0);

  const [addedAxiosInterceptors, setAddedAxiosInterceptors] = React.useState(false);
  const [autoLogin, setAutoLogin] = React.useState(false);
  const [filters, setFilters] = React.useState(false);
  const [loadingPercentage, setLoadingPercentage] = React.useState(0);

  let [fontsLoaded] = useFonts({
      'Comfortaa-Light': require('./assets/fonts/Comfortaa-Light.ttf'),
      'Comfortaa-Regular': require('./assets/fonts/Comfortaa-Regular.ttf'),
      'Comfortaa-Medium': require('./assets/fonts/Comfortaa-Medium.ttf'),
      'Comfortaa-SemiBold': require('./assets/fonts/Comfortaa-SemiBold.ttf'),
      'Comfortaa-Bold': require('./assets/fonts/Comfortaa-Bold.ttf'),
      'futur': require('./assets/fonts/futur.otf'),
      'Futura Bold': require('./assets/fonts/futura-bold.otf'),
      'Futura Book': require('./assets/fonts/futura-book.otf'),
      'Futura Heavy': require('./assets/fonts/futura-heavy.otf'),
      'Futura Medium': require('./assets/fonts/futura-medium.otf'),
    });

  const linking = {
    prefixes: ['http://localhost:19006', 'footprint://', 'https://footprint.mdataws.com', 'https://footprinterp.mdataws.com'],
    config: {
      initialRouteName: 'Landing Page',
      screens: {
        'Landing Page': '',
        'Landing Page': 'Home/:action?/:token?',
        'Register': 'Register',
        'My Requests': 'Requests/My',
        'New Request': 'Request/New',
        'Edit Request': 'Request/Edit/:id',
        'Request Info': 'Request/Info/:id',
        'Dashboard': 'Dashboard',
        'Documents': 'Documents',
        'My Notifications': 'Notifications',
        'Manage Requests': 'Requests/Manage',
        'Manage Users': 'Users/Manage',
        'User Info': 'User/:id',
        'My Profile': 'Profile',
        'About Us': 'AboutUs',
        'About': 'About',
        'Career': 'Career',
        'Press': 'Press',
        'Customer Support': 'CustomerSupport',
        'Terms': 'Terms',
        'Privacy': 'Privacy',
        // 'Search': '?:id/Search',
        // 'Landing Page': '*',
      }
    },
  };

  if(!addedAxiosInterceptors){
    axios.interceptors.response.use(
      (response) => {
        return response
      },
      async (error) => {
        console.log("Error: " + error);
        const originalRequest = error.config

        if (
          error.response?.status === 401 &&
          (originalRequest.url === '/api/user/loginToken.php')
        ) {
          setInitial('Landing Page');
          db.logout();
          if (navigationRef.current?.isReady()) {
            navigationRef.current.navigate('Landing Page');
          }
          return Promise.reject(error)
        }
        if(error.response?.status === 401 && originalRequest.url === '/api/user/login.php'){
          return Promise.reject(error)
        }
        if (error.response?.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;
          return axios
            .post('/api/user/loginToken.php', {
              token: await AsyncStorage.getItem('token'),
              uuid: await AsyncStorage.getItem('uuid')
            })
            .then(res => {
              if (res.status === 200) {
                return axios(originalRequest)
              }
            }).catch(e => {
              return Promise.reject(e);
            })
        }
        return Promise.reject(error)
      }
    )
    setAddedAxiosInterceptors(true);
  }

  React.useEffect(() => {
    // to prompt in-app
    const prompt = async () => {
      if(OneSignal){
        const deviceState = await OneSignal.getDeviceState();
        if(deviceState && !deviceState.hasNotificationPermission){
          OneSignal.addTrigger('showPrompt', 'true');
          setTimeout(() => {
            OneSignal.removeTriggerForKey('showPrompt');
          },100);
        }
      }
    }
    // declare the data fetching function
    const login = async () => {
      const token = await AsyncStorage.getItem('token')??'';
      const uuid = await AsyncStorage.getItem('uuid')??'';
      const user = await AsyncStorage.getItem('user')??'';
      if(token && uuid && user){
        NetInfo.fetch().then(state => {
          if(!state.isConnected){
            let tempUser = JSON.parse(user);
            setUser(tempUser);
            return;
          }
          axios.post('/api/user/loginToken.php', {
            token,
            uuid
          }).then(async(res) => {
            setUser(res.data);
            await AsyncStorage.setItem('token', res.data.token);
            await AsyncStorage.setItem('user', JSON.stringify(res.data));
          }).catch((e) =>{
            setAutoLogin(true);
          }).finally(() => {

          })
        });
      }
      else{
        setAutoLogin(true);
      }
    }
    const saveDevice = async () => {
      let uuid = uuidv4();
      let fetchUUID = await AsyncStorage.getItem('uuid')??'';
      if (fetchUUID) {
        uuid = fetchUUID
      }
      await AsyncStorage.setItem('uuid', uuid);

      let data = {
        uuid,
        onesignal_token: ''
      };

      axios.post('/api/saveDevice.php', data)
          .then((res) => {})
    }
    const updatePercentage = (perc) => {
      setLoadingPercentage(parseInt(perc*100));
    }
    const getFilters = async () => {
      if(!filters){
        await db.initDatabase();
        await db.syncFilters(updatePercentage);
        setFilters(true);
      }
    }
    // const configureBackgroundFetch = async () => {
    //   // BackgroundFetch event handler.
    //   const onEvent = async (taskId) => {
    //     console.log('[BackgroundFetch] task: ', taskId);
    //     // Do your background work...
        
    //     axios.get('/api/test/test.php').then(async(res) => {});

    //     // IMPORTANT:  You must signal to the OS that your task is complete.
    //     BackgroundFetch.finish(taskId);
    //   }

    //   // Timeout callback is executed when your Task has exceeded its allowed running-time.
    //   // You must stop what you're doing immediately BackgroundFetch.finish(taskId)
    //   const onTimeout = async (taskId) => {
    //     console.warn('[BackgroundFetch] TIMEOUT task: ', taskId);
    //     BackgroundFetch.finish(taskId);
    //   }

    //   // Initialize BackgroundFetch only once when component mounts.
    //   let status = await BackgroundFetch.configure({
    //     minimumFetchInterval: 15, // Fetch interval in minutes
    //     stopOnTerminate: false, // Continue background fetch after app termination
    //     startOnBoot: true, // Start background fetch on device boot
    //     enableHeadless: true, // Enable headless mode
    //     requiredNetworkType: BackgroundFetch.NETWORK_TYPE_ANY, // Network requirement
    //     requiresCharging: false, // Charging requirement
    //     requiresDeviceIdle: false, // Idle requirement
    //     requiresBatteryNotLow: false, // Battery not low requirement
    //   }, onEvent, onTimeout);

    //   console.log('[BackgroundFetch] configure status: ', status);

    //   // Optional: Start the background fetch manually
    //   // BackgroundFetch.start();
    // };
    setTimeout(() => {
      prompt().catch(console.error);
    }, 5000)
    saveDevice().catch(console.error);
    login().catch(console.error);
    getFilters().catch(console.error);

    // To open native app if it exists. Otherwise this does nothing
    // TODO fix navigation from deeplink
    const getMobileOperatingSystem = () => {
      var userAgent = navigator.userAgent || navigator.vendor || window.opera;
      if (/windows phone/i.test(userAgent)) {return "Windows Phone";}
      if (/android/i.test(userAgent)) {return "Android";}
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {return "iOS";}
      return "unknown";
    }
    if(Platform.OS==='web'){
      const os = getMobileOperatingSystem();
      if(os === 'Android' || os === 'iOS'){
        let openInApp = window.location.pathname;
        modal.confirm.show('Info', 'Do you want to open this page in the Footprint App?', () => {
          let found = false;
          window.location = Constants.expoConfig.extra.packageName+"://"+openInApp;
          setTimeout(() => {
            if(!found){
              if(os === 'Android'){
                window.location.href = ('https://play.google.com/store/apps/details?id='+Constants.expoConfig.extra.packageName);
              }
            }
            if(os === 'iOS'){ // "found" doesn't work on iOS due to the secondary prompt, in safari at least
              modal.confirm.show('Info', 'If you don\'t have the $'+os.toLocaleLowerCase()+' app, please download it.');
            }
          }, 1000);
          window.onblur = () => {
            found = true;
          }
          window.onfocus = () => {
            found = false;
          }
        })
      }
    }
    // configureBackgroundFetch().catch(console.error);

    return () => {
      // Clean up and stop background fetch when component unmounts
      // BackgroundFetch.stop();
    };

  }, [])

  React.useEffect(() => {
    if(user){
      // Sync Incoming/Outgoing data
      const Sync = async() => {

        const Synced = () => {
          setTimeout(() => {
            setAutoLogin(true);
          },1000);
        }

        const SyncNew = async() => {
          const pendingRequests = await db.getAll('pending_requests');
          if(pendingRequests.length > 0){
            let counter = 0;
            Promise.allSettled(pendingRequests.map((request) => axios.post('/api/newRequest.php', request.data)))
            .then((results) => {
              results.forEach(async(result, index) => {
                if (result.status === 'fulfilled') {
                  counter++;
                  const request = pendingRequests[index];
                  await db.remove('pending_requests', request.id);
                }
              })
              let message = 'Your requests have been synced.';
              if(counter == 1){
                message = 'Your request has been synced.';
              }
              if(counter > 0){
                modal.confirm.show('Update', message);
              }
              SyncEdit();
            })
          }
          else{
            SyncEdit();
          }
        }

        const SyncEdit = async() => {
          let pendingRequests = JSON.parse(await AsyncStorage.getItem('PendingEditRequests')??'[]');
          if(pendingRequests.length > 0){
            let counter = 0;
            Promise.allSettled(pendingRequests.map(async(requestId) => {
              let pendingRequest = await db.get('requests', requestId);
              pendingRequest.data.id = requestId;
              return axios.post('/api/editRequest.php', pendingRequest.data);
            }))
            .then((results) => {
              results.forEach(async(result, index) => {
                if (result.status === 'fulfilled') {
                  counter++;
                  pendingRequests = pendingRequests.splice(index, 1);
                }
              })
              AsyncStorage.setItem('PendingEditRequests', JSON.stringify(pendingRequests));
              let message = 'Your edited requests have been synced.';
              if(counter == 1){
                message = 'Your edited request has been synced.';
              }
              if(counter > 0){
                modal.confirm.show('Update', message);
              }
              Synced();
            })
          }
          else{
            Synced();
          }
        }

        NetInfo.fetch().then(async(state) => {
          if(state.isConnected && Platform.OS !== 'web'){
            SyncNew();
          }
          else{
            Synced();
          }
        })
      }
      Sync().catch(console.error);
    }
  }, [user])

  const renderBackdrop = useCallback(
    (props) => <BottomSheetBackdrop {...props} pressBehavior='close' disappearsOnIndex={-1} />,
    []
  );
  
  const {height, width} = useWindowDimensions();

  const Content = (nav, child) => {
    return (
      <>
        <View style={{flexDirection: 'row', flex: 1, flexWrap: 'wrap'}}>
          {
            width >= 992?
            <View style={{flexGrow: 0, flexShrink: 0, flexBasis: 230}}>
              <SideMenu user={user} setUser={setUser} navigation={nav.navigation} route={nav.route} />
            </View>
            :<View style={{width: 1, backgroundColor: '#f6f4f9'}}></View>
          }
          <View style={{flex: 1, minHeight: height - 107 - 201 - 1}}>
            {child}
          </View>
          <View style={{width: '100%'}}>
            {
              Platform.OS==='web'?
              <WebFooter navigation={navigationRef} />
              :null
            }
          </View>
        </View>
        {
          Platform.OS!=='web'?
          <Footer user={user} navigation={nav.navigation} style={{flexGrow: 0, flexShrink: 0, flexBasis: 61}}/>
          :null
        }
      </>
    )
  }

  const url = Linking.useURL();
  if(autoLogin && fontsLoaded && splashFinished && filters){
    // setTimeout(() => {
    //   if (navigationRef.current?.isReady()) {
    //     navigationRef.current.dispatch(StackActions.push('Landing Page'));
    //   }
    // },100);
  }


  const DeeplinkNavigation = () => {
    const linkTo = useLinkTo();
    let hasPath = false;

    if (url) {
      const { hostname, path, queryParams } = Linking.parse(url);

      console.log(
        `Linked to app with hostname: ${hostname}, path: ${path} and data: ${JSON.stringify(
          queryParams
        )}`
      );
      
      if(path){
        hasPath = true;
        setTimeout(() => {
          console.log(deeplinkPrevPath, path);
          if(deeplinkPrevPath != path){
            linkTo('/'+path);
            deeplinkPrevPath = path;
          }
        },100);
      }
      else{
        setTimeout(() => {
          if(user){
            let path = '';
            if(!user.isVGroup){
              path = 'Requests/My';
            }
            else{
              path = 'Requests/Manage';
            }
            if(deeplinkPrevPath != path){
              linkTo('/'+path);
              deeplinkPrevPath = path;
            }
          }
        },100);
      }
    }
    if(!hasPath && !firstRedirect){
      setFirstRedirect(true);
      setTimeout(() => {
        if(user){
          let path = '';
          if(!user.isVGroup){
            path = 'Requests/My';
          }
          else{
            path = 'Requests/Manage';
          }
          if(deeplinkPrevPath != path){
            linkTo('/'+path);
            deeplinkPrevPath = path;
          }
        }
      },100);
    }

    if(OneSignal){
      OneSignal.setNotificationWillShowInForegroundHandler(receivedEvent => {
        setNotificationsKey(prev => prev+1);
      });
    
      OneSignal.setInAppMessageClickHandler(handler => {
        //clicked on inapp
      })
    
      OneSignal.setInAppMessageLifecycleHandler(event => {
        //received inapp, for some reason this is not called
        // OneSignal.removeTriggerForKey('showPrompt');
      })
    
      OneSignal.setNotificationOpenedHandler(openedEvent => {
        const { action, notification } = openedEvent;
        if(notification.additionalData?.id){
          setTimeout(async() => {
            if(notification.additionalData?.dbId){
              let notifications = JSON.parse(await AsyncStorage.getItem('notifications')??'{}');
              notifications[notification.additionalData.dbId] = true;
              await AsyncStorage.setItem('notifications', JSON.stringify(notifications)); 
            }
            setNotificationsKey(prev => prev+1);
            if (navigationRef.current?.isReady()) {
              navigationRef.current.dispatch(StackActions.push('Request Info', { id: notification.additionalData.id }));
            }
          },100);
        }
      });
    }
  }
  // console.log('auto login: ' + autoLogin);
  // console.log('fonts loaded: ' + fontsLoaded);
  // console.log('filters: ' + filters);

  let loadingText = 'Downloading data. Please wait...';
  if (!filters && loadingPercentage != 0)
    loadingText = 'Loading data might take a few minutes. Please wait...';
  
  return (
    <>
      {
        (!autoLogin || !fontsLoaded || !filters /*|| !splashFinished*/)?
        <View style={{flex: 1, paddingHorizontal: 20, justifyContent: 'center', alignItems: 'center', backgroundColor: '#f6f4f9'}}>
          <ActivityIndicator size="large" color="#46r1b6f" />
          <MyText style={[{marginTop: 20, color: '#461b6f', textAlign: 'center'}, css.l]}>{ loadingText }</MyText>
          {
            loadingPercentage != 0?
            <MyText style={[{marginTop: 20, color: '#461b6f'}, css.l]}>{ loadingPercentage }%</MyText>
            :
            null
          }
        </View>
        :
        <BottomSheetModalProvider>
          <NavigationContainer ref={navigationRef} linking={linking} fallback={<Text>Loading...</Text>}>
            <DeeplinkNavigation/>
            <SyncRequests/>
            <StatusBar style='light' />
            <BottomSheetModal
              ref={bottomSheetModalRef}
              index={1}
              snapPoints={[250, user?.isVGroup?420:380]}
              onChange={handleSheetChanges}
              detached={false}
              backdropComponent={renderBackdrop}
            >
              <CustomBottomSheetContent user={user} setUser={setUser} navigation={navigationRef} bottomSheet={bottomSheetModalRef} />
            </BottomSheetModal>
            <BottomSheetModal
              ref={bottomSheetConsentModalRef}
              index={0}
              snapPoints={[120]}
              onChange={handleSheetChanges}
              detached={false}
              backdropComponent={renderBackdrop}
              onDismiss={() => AsyncStorage.setItem('cookie-consent', 'true')}
              enableContentPanningGesture={false}
              enableHandlePanningGesture={false}
              enablePanDownToClose={false}
              handleIndicatorStyle={{display: 'none'}}
            >
              <CustomBottomSheetCookieContent user={user} setUser={setUser} navigation={navigationRef} bottomSheet={bottomSheetConsentModalRef} />
            </BottomSheetModal>
            <BottomSheetModal
              ref={bottomSheetDisclaimerModalRef}
              index={0}
              snapPoints={[120]}
              onChange={handleSheetChanges}
              detached={false}
              backdropComponent={renderBackdrop}
              onDismiss={() => AsyncStorage.setItem('disclaimer-consent', 'true')}
              enableContentPanningGesture={false}
              enableHandlePanningGesture={false}
              enablePanDownToClose={false}
              handleIndicatorStyle={{display: 'none'}}
            >
              <CustomBottomSheetDisclaimerContent navigation={navigationRef} bottomSheet={bottomSheetDisclaimerModalRef} />
            </BottomSheetModal>
            <BottomSheetModal
              ref={bottomSheetNewRequestModalRef}
              index={0}
              snapPoints={[120]}
              onChange={handleSheetChanges}
              detached={false}
              backdropComponent={renderBackdrop}
              onDismiss={() => AsyncStorage.setItem('new-request-disclaimer-consent', 'true')}
              enableContentPanningGesture={false}
              enableHandlePanningGesture={false}
              enablePanDownToClose={false}
              handleIndicatorStyle={{display: 'none'}}
            >
              <CustomBottomSheetNewRequest navigation={navigationRef} bottomSheet={bottomSheetNewRequestModalRef} />
            </BottomSheetModal>
            <View style={Platform.OS==='web'?{flex: 0, height: '100vh', minHeight: '100vh'}:{flex: 1}}>
              <Stack.Navigator
                  // ref={navigationRef}
                  // initialRouteName={initial}
                  screenOptions={{
                    // drawerPosition: 'left',
                    header: (props) => <Header {...props} user={user} bottomSheet={bottomSheetModalRef} bottomConsentSheet={bottomSheetConsentModalRef} style={{position: 'absolute', top: 0, right: 0, left: 0, flexGrow: 0, flexShrink: 0, flexBasis: 74}} key={notificationsKey}/>,
                    headerShown: true,
                    animationEnabled: Platform.OS==='ios',
                    gestureEnabled: Platform.OS==='ios'
                    // cardStyle: { flex: 1 }
                    // swipeEnabled: false,
                    // drawerActiveTintColor: '#461b6f',
                    // drawerLabelStyle: {fontFamily: 'Comfortaa-Regular', fontSize: 12},
                  }}
                  // drawerContent={(props) => <CustomSidebarMenu {...props} user={user} setUser={setUser} navigation={props.navigation} />}
                >
                  {
                    !user?
                    <>
                      <Stack.Screen name="Landing Page" options={{ drawerItemStyle: { display: 'none' } }}>
                        {props => (
                          <>
                            <LandingPage navigation={props.navigation} autoLogin={autoLogin} setUser={setUser} isInfo={false} action={props?.route?.params?.action} token={props?.route?.params?.token} />
                          </>
                        )}
                      </Stack.Screen>
                      <Stack.Screen name="Register">
                        {props => (
                          <>
                            <Register navigation={props.navigation} bottomDisclaimer={bottomSheetDisclaimerModalRef} isInfo={false} />
                          </>
                        )}
                      </Stack.Screen>
                      <Stack.Screen name="About Us">
                        {props => (<AboutUs navigation={props.navigation} />)}
                      </Stack.Screen>
                      <Stack.Screen name="About">
                        {props => (<About navigation={props.navigation} />)}
                      </Stack.Screen>
                      <Stack.Screen name="Career">
                        {props => (<Career navigation={props.navigation} />)}
                      </Stack.Screen>
                      <Stack.Screen name="Press">
                        {props => (<Press navigation={props.navigation} />)}
                      </Stack.Screen>
                      <Stack.Screen name="Customer Support">
                        {props => (<CustomerSupport navigation={props.navigation} />)}
                      </Stack.Screen>
                      <Stack.Screen name="Terms">
                        {props => (<Terms navigation={props.navigation} />)}
                      </Stack.Screen>
                      <Stack.Screen name="Privacy">
                        {props => (<Privacy navigation={props.navigation} />)}
                      </Stack.Screen>
                    </>
                    :
                    <>
                      <Stack.Screen name="Landing Page" options={{ drawerItemStyle: { display: 'none' } }}>
                        {props => (
                          <>
                            <LandingPage navigation={props.navigation} autoLogin={autoLogin} setUser={setUser} isInfo={false} action={props?.route?.params?.action} token={props?.route?.params?.token} />
                          </>
                        )}
                      </Stack.Screen>
                      <Stack.Screen name="My Requests">
                        {props => Content(props, <MyRequests user={user} navigation={props.navigation} />)}
                      </Stack.Screen>
                      <Stack.Screen name="New Request">
                        {props => Content(props, <NewRequest user={user} navigation={props.navigation} isInfo={false} bottomDisclaimer={bottomSheetNewRequestModalRef}  />)}
                      </Stack.Screen>
                      <Stack.Screen name="Edit Request" options={{ drawerItemStyle: { display: 'none' } }}>
                        {props => Content(props, <NewRequest user={user} navigation={props.navigation} id={props.route.params.id} isInfo={false} />)}
                      </Stack.Screen>
                      <Stack.Screen name="Request Info" options={{ drawerItemStyle: { display: 'none' } }}>
                        {props => Content(props, <NewRequest user={user} navigation={props.navigation} id={props.route.params.id} isInfo={true} />)}
                      </Stack.Screen>
                      <Stack.Screen name="Dashboard">
                        {props => Content(props, <Dashboard user={user} navigation={props.navigation}/>)}
                      </Stack.Screen>
                      <Stack.Screen name="Documents">
                        {props => Content(props, <Documents user={user}/>)}
                      </Stack.Screen>
                      {
                        Platform.OS !== 'web'?
                        <Stack.Screen name="My Notifications">
                          {props => Content(props, <MyNotifications user={user} navigation={props.navigation} opened={() => setNotificationsKey(prev => prev+1)} />)}
                        </Stack.Screen>
                        :null
                      }
                      <Stack.Screen name="Manage Requests">
                        {props => Content(props, <ManageRequests user={user} navigation={props.navigation} />)}
                      </Stack.Screen>
                      <Stack.Screen name="Manage Users">
                        {props => Content(props, <ManageUsers user={user} navigation={props.navigation} />)}
                      </Stack.Screen>
                      <Stack.Screen name="User Info" options={{ drawerItemStyle: { display: 'none' } }}>
                        {props => Content(props, <Register user={user} navigation={props.navigation} id={props.route.params.id} isInfo={true} />)}
                      </Stack.Screen>
                      <Stack.Screen name="My Profile" options={{ drawerItemStyle: { display: 'none' } }}>
                        {props => Content(props, <MyProfile user={user} setUser={setUser} navigation={props.navigation} />)}
                      </Stack.Screen>
                      <Stack.Screen name="About Us" options={{ drawerItemStyle: { display: 'none' } }}>
                        {props => Content(props, <AboutUs user={user} navigation={props.navigation} />)}
                      </Stack.Screen>
                      <Stack.Screen name="About" options={{ drawerItemStyle: { display: 'none' } }}>
                        {props => Content(props, <About user={user} navigation={props.navigation} />)}
                      </Stack.Screen>
                      <Stack.Screen name="Career" options={{ drawerItemStyle: { display: 'none' } }}>
                        {props => Content(props, <Career user={user} navigation={props.navigation} />)}
                      </Stack.Screen>
                      <Stack.Screen name="Press" options={{ drawerItemStyle: { display: 'none' } }}>
                        {props => Content(props, <Press user={user} navigation={props.navigation} />)}
                      </Stack.Screen>
                      <Stack.Screen name="Customer Support" options={{ drawerItemStyle: { display: 'none' } }}>
                        {props => Content(props, <CustomerSupport user={user} navigation={props.navigation} />)}
                      </Stack.Screen>
                      <Stack.Screen name="Terms" options={{ drawerItemStyle: { display: 'none' } }}>
                        {props => Content(props, <Terms user={user} navigation={props.navigation} />)}
                      </Stack.Screen>
                      <Stack.Screen name="Privacy" options={{ drawerItemStyle: { display: 'none' } }}>
                        {props => Content(props, <Privacy user={user} navigation={props.navigation} />)}
                      </Stack.Screen>
                    </>
                  }
              </Stack.Navigator>
            </View>
          </NavigationContainer>
        </BottomSheetModalProvider>
        }
        <Splash done={(autoLogin && fontsLoaded /*&& filters*/ && splashFinished)} finished={() => setSplashFinished(true)}/>
        <MyDocumentModal />
        <MyModal />
      </>
    );
}

export default App

const styles = StyleSheet.create({
  background: {
    flex: 1,
    backgroundColor: '#F6F4F9',
    padding: 20,
    overflow: 'scroll'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  },
  bottomContainer: {
    flex: 1,
    padding: 24,
    justifyContent: 'center',
    backgroundColor: 'grey',
  },
  contentContainer: {
    flex: 1,
    // alignItems: 'center',
    paddingHorizontal: 30,
    paddingVertical: 10
  },
});
